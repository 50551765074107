import { errorCallback, successCallback } from '@/composables/validate';

export async function marketingCategories() {
  return this.handleApiRequest({ 
    method: 'common/GetDocumentCategories',
    errorCallback
  })
}

export async function marketingItems(options) {
  const fileResponse = await  this.handleApiRequest({ 
    method: 'common/ListDocuments',
    payload: {
      CategoryId: Number(options.categoryId),
      Type: window.enum.documentType.RESOURCE_FILE,
    },
    errorCallback
  })

  const videoResponse = await  this.handleApiRequest({ 
    method: 'common/ListDocuments',
    payload: {
      CategoryId: Number(options.categoryId),
      Type: window.enum.documentType.RESOURCE_VIDEO,
    },
    errorCallback
  })

  return [...fileResponse.documents, ...videoResponse.documents];
}

export async function documentDetails(id) {
  return this.handleApiRequest({ 
    method: 'common/DocumentDetails',
    payload: {
      Id: Number(id)
    },
    errorCallback
  })
}

export async function uploadDocumentForm(newDocument) {
  return this.handleApiRequest({ 
    method: 'customer/UploadDocumentForm',
    payload: newDocument,
    errorCallback,
    successCallback: (r)  => successCallback(r.data.id, 'Save')
  })
}

export async function addDocumentCategory(categoryName) {
  await this.handleApiRequest({ 
    method: 'staff/AddDocumentCategory',
    payload: {
      Name: categoryName,
    },
    errorCallback,
    successCallback: (r)  => successCallback(r.data, 'Save')
  })
}

export async function updateDocumentCategory(category) {
  await this.handleApiRequest({ 
    method: 'staff/UpdateDocumentCategory',
    payload: {
      Id: Number(category.id),
      Name: category.name,
    },
    errorCallback,
    successCallback: (r)  => successCallback(r.data, 'Save')
  })
}

export async function deleteDocumentCategories(categoryIds) {
  return this.handleApiRequest({ 
    method: 'staff/DeleteDocumentCategories',
    payload: {
      Ids: categoryIds,
    },
    errorCallback,
    successCallback: (r)  => successCallback(r.data, 'Delete')
  })
}

export async function deleteMarketingDocuments(documentIds) {
  return this.handleApiRequest({ 
    method: 'staff/DeleteDocuments',
    payload: {
      Ids: documentIds,
    },
    errorCallback,
    successCallback: (r)  => successCallback(r.data, 'Delete')
  })
}

export async function generateLinkWeb2Print({ jwt_token, prefix }) {
  return this.handleApiRequest({ 
    method: { endpoint: import.meta.env.VITE_APP_STORE_PREFIX, method: 'api/vpress-register' },
    payload: {
      jwt_token, prefix
    },
    errorCallback
  })
}