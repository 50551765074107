<template>
  <div>
    <table class="w-full table-lined 3xs:table-unstacked">
      <thead>
        <tr>
          <th>Heading</th>
          <th>Main Default</th>
          <th>Customer Default</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="group in groupedProductDefaults" :key="group[0].id">
          <tr>
            <th colspan="4">{{ group[0].headingGroupDescription || "No Group Description" }}</th>
          </tr>
          <tr v-for="item in group" :key="`${group[0].id}:${item.id}`">
            <td>{{ item.headingDescription }}</td>
            <td>{{ item.itemDescription }}</td>
            <td>
              <span
                :class="{
                  'opacity-25':
                    item.customerOverrideItemId === '00000000-0000-0000-0000-000000000000',
                }"
              >{{ item.customerOverrideDescription || item.itemDescription }}</span>
            </td>
            <td class="text-right w-48 p-1">
              <div v-if="canHaveDefault(item)" class="-my-3">
                <select
                  v-model="item.customerOverrideItemId"
                  class="form-control h-8 p-1"
                  @change="updateProductDefault($event, item)"
                >
                  <option
                    v-for="(itemOption, id) in getProductDefaultSelections(item)"
                    :key="id"
                    :value="id"
                  >
                    {{ itemOption }}
                  </option>
                </select>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useTouchStore } from '@/pinia/touch';
import _ from 'lodash';

export default {
  props: ['product', 'datasetId', 'customerId'],
  data() {
    return {
      productDefaults: [],
    };
  },
  computed: {
    ...mapStores(useTouchStore),
    groupedProductDefaults() {
      return _.groupBy(this.productDefaults, (row) => row.headingGroupId);
    },
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    updateDefaultVariable({ id, optionItemId }) {
      const index = this.productDefaults.findIndex((row) => row.id === id);
      this.productDefaults[index] = {
        ...this.productDefaults[index],
        customerOverrideItemId: optionItemId,
        customerOverrideDescription:
          optionItemId === '00000000-0000-0000-0000-000000000000'
            ? null
            : this.productDefaults[index].appliedOptions.find(
              (option) => option.id === optionItemId,
            ).description,
      }
    },
    canHaveDefault(item) {
      return item.appliedOptions !== null && item.appliedOptions.length > 0;
    },
    getProductDefaultSelections(item) {
      const inputOptions = {};

      inputOptions['00000000-0000-0000-0000-000000000000'] = 'Reset To Main Default';

      item.appliedOptions.forEach((option) => {
        inputOptions[option.id] = option.description;
      });
      return inputOptions;
    },
    async updateProductDefault(event, item) {
      const newDefault = event.target.value;

      if (newDefault) {
        try {
          this.touchStore.customerUpdateProductDefault({
            id: item.id,
            productId: this.product.default.engineId,
            optionItemId: newDefault,
            datasetId: this.datasetId,
            customerId: this.customerId,
          })
            .then(async () => {
              this.updateDefaultVariable({
                id: item.id,
                optionItemId: newDefault,
              });
            });
        } catch (error) {
          if (error.status === 400) {
            this.alertBox().fire(error.popUp);
          } else {
            throw error
          }
        }
      }
    },
    async loadData() {
      this.productDefaults =
        await this.touchStore.customerGetProductDefaultsWithDetails({
          productId: this.product.default.engineId,
          datasetId: this.datasetId,
          customerId: this.customerId,
        })
    },
  },
};
</script>

<style scoped></style>
