import { errorCallback, successCallback } from '@/composables/validate';

export async function processingBasketSummary(CustomerId, ContractId, JobKey, ProcessingLevel) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/BasketSummary'),
    payload: {
      CustomerId,
      ContractId,
      JobKey,
      ProcessingLevel,
    },
    errorCallback
  })
}

export async function processingAddStockItem(ContractId, JobKey, ItemId, Quantity, LinkToItemKey) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/AddStockItem'),
    payload: {
      ContractId,
      JobKey,
      ItemId,
      LinkToItemKey,
      Quantity,
    },
    errorCallback
  })
}

export async function processingContract(contractId) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/Contract'),
    payload: {
      ContractId: contractId,
    },
    errorCallback
  })
}

export async function processingSetVisualisations(contractId, jobKey, visualisations) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/SetVisualisations'),
    payload: {
      ContractId: contractId,
      JobKey: jobKey,
      Visualisations: visualisations,
    },
    errorCallback
  })
}

export async function processingGetAvailableProductsToSelect(typeId, groupId, customerId, Sandbox) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/GetAvailableProductsToSelect'),
    payload: {
      TypeId: typeId,
      GroupId: groupId,
      CustomerId: customerId,
      Sandbox
    },
    errorCallback
  })
}

export async function processingGetAvailableProductSelectionGroups(customerId) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/GetAvailableProductSelectionGroups'),
    payload: {
      CustomerId: customerId,
    },
    errorCallback
  })
}

export async function processingGetAvailableProductSelectionTypes(customerId, groupId) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/GetAvailableProductSelectionTypes'),
    payload: {
      GroupId: groupId,
      CustomerId: customerId,
    },
    errorCallback
  })
}

export async function getProducts() {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/Products'),
    errorCallback
  })
}

export async function processingGetCustomerPriceBreakdown(contractId, jobId, itemId) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/GetCustomerPriceBreakdown'),
    payload: {
      ContractId: contractId,
      JobKey: jobId,
      ItemKey: itemId,
    },
    errorCallback
  })
}

export async function productStoredDesigns(
  customerId, processingLevel, productId, width = undefined, height = undefined, bayBowOnly = false,
  limit = 1000000, offset = 0, squaresQty = undefined, coupledOnly = false,
) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/ProductStoredDesigns'),
    payload: {
      ProductId: productId,
      OnlyShowBayBowCompatible: bayBowOnly,
      OnlyShowCoupledCompatible: coupledOnly,
      TargetWidth: width ? Number(width) : undefined,
      TargetHeight: height ? Number(height) : undefined,
      ProcessingLevel: processingLevel,
      Limit: limit,
      Offset: offset,
      QtySquares: squaresQty,
      CustomerId: customerId,
    },
    errorCallback
  })
}

export async function processingGetProcessingLevel() {
  const { processingLevelId } = await this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/GetProcessingLevel'),
    errorCallback
  })
  return processingLevelId;
}

export async function createJobLineItem(customerId, contractId, jobKey, designId, productId, processingLevel) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/CreateJobLineItem'),
    payload: {
      ContractId: contractId,
      CustomerId: customerId,
      JobKey: jobKey,
      ProductStoredDesignId: designId,
      ProductId: productId,
      ProcessingLevel: processingLevel,
    },
    errorCallback
  })
}

export async function updateJobLineItemDimension(
  customerId, contractId, jobKey, itemKey, processingLevel, dimensionId, value,
) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/UpdateJobLineItemDimension'),
    payload: {
      CustomerId: customerId,
      ContractId: contractId,
      JobKey: jobKey,
      ItemKey: parseInt(itemKey, 10),
      ProcessingLevel: processingLevel,
      DimensionId: dimensionId,
      Value: parseInt(value, 10),
    },
    errorCallback
  })
}

export async function updateJobLineItemQuantity(
  customerId, contractId, jobKey, itemKey, processingLevel, qty,
) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/UpdateJobLineItemQuantity'),
    payload: {
      CustomerId: customerId,
      ContractId: contractId,
      JobKey: jobKey,
      ItemKey: parseInt(itemKey, 10),
      ProcessingLevel: processingLevel,
      NewQuantity: parseInt(qty, 10),
    },
    errorCallback
  })
}

export async function UpdateJobLineStockItemQuantity(
  contractId, jobKey, itemKey, processingLevel, qty,
) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/UpdateJobLineStockItemQuantity'),
    payload: {
      ContractId: contractId,
      JobKey: jobKey,
      ItemKey: parseInt(itemKey, 10),
      ProcessingLevel: processingLevel,
      NewQuantity: parseInt(qty, 10),
    },
    errorCallback
  })
}

export async function UpdateJobLineItemOption(
  customerId, contractId, jobKey, itemKey, processingLevel, heading, value,
  components, members, parameters, text, rule,
) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/UpdateJobLineItemOption'),
    payload: {
      CustomerId: customerId,
      ContractId: contractId,
      JobKey: jobKey,
      ItemKey: parseInt(itemKey, 10),
      ProcessingLevel: processingLevel,
      OptionHeadingId: heading,
      NewOptionId: value,
      OnlyTheseComponents: components,
      OnlyTheseMembers: members,
      Text: text,
      Parameters: parameters,
      Rule: parseInt(rule, 10),
    },
    errorCallback
  })
}

export async function createTradeQuote(customerId) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/createTradeQuote'),
    payload: {
      CustomerId: customerId,
    },
    errorCallback
  })
}

export async function processingGetEarliestDeliveryDate(contractId, jobKey) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/GetEarliestDeliveryDate'),
    payload: {
      ContractId: contractId,
      JobKey: jobKey,
    },
    errorCallback
  })
}

export async function processingForceDefaults(contractId, jobKey, itemKey, processingLevel) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/ForceDefaults'),
    payload: {
      ContractId: contractId,
      JobKey: jobKey,
      ItemKey: itemKey,
      ProcessingLevel: processingLevel,
    },
    errorCallback
  })
}

export async function createTradeOrder(customerId) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/createTradeOrder'),
    payload: {
      CustomerId: customerId
    },
    errorCallback
  })
}

export async function jobLineItem(
  customerId, contractId, jobKey, itemKey, processingLevel,
  recalculate = false, visualiserImages = false, exchangeRate = 1,
) {

  const item = await this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/JobLineItem'),
    payload: {
      CustomerId: customerId,
      ContractId: parseInt(contractId, 10),
      JobKey: jobKey,
      ItemKey: parseInt(itemKey, 10),
      ProcessingLevel: processingLevel,
      Recalculate: recalculate,
      FormatImagesForVisualiser: visualiserImages,
    },
    errorCallback
  })

  if (item) {
    item.price *= exchangeRate;
    item.tax *= exchangeRate;
  
    if (item.priceBreakdown) {
      item.priceBreakdown = item.priceBreakdown.map((breakdownRow) => ({
        ...breakdownRow,
        discount: breakdownRow.discount * exchangeRate,
        gross: breakdownRow.gross * exchangeRate,
        nett: breakdownRow.nett * exchangeRate,
        rate: breakdownRow.rate * exchangeRate,
        value: breakdownRow.value * exchangeRate,
      }));
    }
    return item;
  }
}

export async function processingJob(contractId, jobKey, recalculate = false) {
  const rawJob = await this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/Job'),
    payload: {
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobKey, 10),
      Recalculate: recalculate,
    },
    errorCallback
  })
  

  if (!rawJob?.exchangeRate) {
    rawJob.exchangeRate = 1;
  }

  return rawJob;
}

export async function processingUpdateJobLineItemNestedDesign(
  customerId, contractId, jobKey, itemKey, processsingLevel,
  productId, designId, containerId,
) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/UpdateJobLineItemNestedDesign'),
    payload: {
      CustomerId: customerId,
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobKey, 10),
      ItemKey: parseInt(itemKey, 10),
      ProcessingLevel: processsingLevel,
      ProductId: parseInt(productId, 10),
      StoredDesignId: designId,
      ContainerId: containerId,
    },
    errorCallback
  })
}

export async function processingGetChildProducts(contractId, jobKey, itemKey, width, height, customerId) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/GetChildProducts'),
    payload: {
      CustomerId: customerId,
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobKey, 10),
      ItemKey: parseInt(itemKey, 10),
      TargetWidth: parseInt(width, 10),
      TargetHeight: parseInt(height, 10),
    },
    errorCallback
  })
}

export async function processingSaveContract(
  customerId, contractId, skipAcceptance = false, placeTradeOrder = false, useLazySaving = true
) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/SaveContract'),
    payload: {
      CustomerId: customerId,
      ContractId: parseInt(contractId, 10),
      useLazySaving,
      PlaceTradeOrder: placeTradeOrder,
      SkipAcceptance: skipAcceptance,
    },
    errorCallback,
    successCallback: () => ({ status: true }),
  })
}

export async function processingRemoveContract(contractId) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/RemoveContract'),
    payload: {
      ContractId: parseInt(contractId, 10),
    },
    errorCallback
  })
}

export async function processingGet3DModel({contractId, itemKey, jobKey, customerId}) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/Get3dModel'),
    payload: {
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobKey, 10),
      ItemKey: parseInt(itemKey, 10),
      CustomerId: parseInt(customerId, 10),
    },
    errorCallback
  })
}

export async function processingUpdateContract(contractId, payload) {
  await this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/UpdateContract'),
    payload: {
      ContractId: parseInt(contractId, 10),
      ...payload,
    },
    errorCallback
  })
}

export async function processingUpdateNote(params) {
  await this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/UpdateNote'),
    payload: params,
    errorCallback
  })
}

export async function processingUpdateFittingType(contractId, jobId, isFit) {
  await this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/UpdateFittingType'),
    payload:  {
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobId, 10),
      FittingType: isFit
        ? window.enum.fittingType.SUPPLY_AND_FIT
        : window.enum.fittingType.SUPPLY_ONLY,
    },
    errorCallback
  })
}

export async function processingUpdateJob(contractId, jobId, payload) {
  await this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/UpdateJob'),
    payload:  {
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobId, 10),
      ...payload,
    },
    errorCallback
  })
}

export async function processingUpdateJobDelivery(customerId, contractId, jobId, payload) {
  const params = {
    ContractId: parseInt(contractId, 10),
    JobKey: parseInt(jobId, 10),
    ...payload,
  };

  if (window.VITE_APP_INSTALLATION_TYPE === 'business') {
    params.CustomerId = parseInt(customerId, 10);
  }

  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/UpdateJobDelivery'),
    payload:  params,
    errorCallback,
    successCallback: () => ({ status: true }),
  })
}

export async function processingUpdateCustomerPONumber(contractId, poNumber) {
  const params = {
    ContractId: parseInt(contractId, 10),
    CustomerPONumber: poNumber,
  };

  await this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/UpdateCustomerPONumber'),
    payload:  params,
    errorCallback
  })
}

export async function processingUpdateExistingExtraItem(contractId, jobId, itemId, params) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/UpdateExistingExtraItem'),
    payload:  {
      ...params,
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobId, 10),
      ItemKey: itemId,
    },
    errorCallback
  })
}

export async function processingUpdateNewExtraItem(contractId, jobId, itemId, params) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/UpdateNewExtraItem'),
    payload:  {
      ...params,
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobId, 10),
      ItemKey: itemId,
    },
    errorCallback
  })
}

export async function processingAddExistingExtraItem(
  contractId, jobId, extraItemId, itemId = undefined, params = {},
) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/AddExistingExtraItem'),
    payload:  {
      ...params,
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobId, 10),
      ItemId: extraItemId,
      LinkToItemKey: itemId,
    },
    errorCallback
  })
}

export async function processingJobDelivery(customerId, contractId, jobId, monthsAhead = 3,) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/JobDelivery'),
    payload:  {
      CustomerId: customerId,
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobId, 10),
      MonthsAhead: parseInt(monthsAhead, 10),
    },
    errorCallback
  })
}

export async function processingUpdateJobLineItemRequote(contractId, jobId, processingLevel, items) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/UpdateJobLineItemRequote'),
    payload:  {
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobId, 10),
      ProcessingLevel: processingLevel,
      Items: items.map((item) => ({
        Override: true,
        ItemKey: parseInt(item.itemKey, 10),
        Value: parseFloat(item.price),
        TaxExempt: false,
      })),
    },
    errorCallback
  })
}

export async function processingReloadContract(customerId, contractId) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/ReloadContract'),
    payload:  {
      CustomerId: customerId,
      ContractId: parseInt(contractId, 10),
    },
    errorCallback,
    successCallback: (r) => r 
  })
}

export async function processingDeleteJob(contractId, jobId) {
  await this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/DeleteJob'),
    payload:  {
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobId, 10),
    },
    errorCallback
  })
}

export async function processingDeleteLineItem(contractId, jobId, lineItemId) {
  await this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/DeleteJobLineItem'),
    payload:  {
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobId, 10),
      ItemKey: parseInt(lineItemId, 10),
    },
    errorCallback
  })
}

export async function processingDuplicateItem(customerId, contractId, jobId, lineItemId) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/DuplicateItem'),
    payload:  {
      CustomerId: customerId,
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobId, 10),
      ItemKey: parseInt(lineItemId, 10),
    },
    errorCallback
  })
}

export async function processingCopyJob(contractId, jobId) {
  const { jobKey } = await this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/CopyJob'),
    payload:  {
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobId, 10),
    },
    errorCallback
  })
  return jobKey
}

export async function getEnquiryStatuses() {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/GetEnquiryStatuses'),
    errorCallback
  })
}

export async function updateQuoteStatus(contractId, status) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/UpdateQuoteStatus'),
    payload:  {
      ContractId: Number(contractId),
      Status: Number(status),
    },
    errorCallback
  })
}

export async function updateEnquiryStatus(contractId, status) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/UpdateEnquiryStatus'),
    payload:  {
      ContractId: Number(contractId),
      Status: Number(status),
    },
    errorCallback
  })
}

export async function updateDealerEnquiryStatus(dealerNetworkAssignmentId, status, refusalReason = '') {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/UpdateDealerEnquiryStatus'),
    payload:  {
      dealerNetworkAssignmentId: Number(dealerNetworkAssignmentId),
      DealerStatus: Number(status),
      RefusalReason: refusalReason,
    },
    errorCallback
  })
}

export async function getDocsToAccept() {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/GetDocsToAccept'),
    errorCallback
  })
}

export async function processingProductSelectionStage(selectedStages) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/ProductSelectionStage'),
    payload: { SelectedStages: selectedStages },
    errorCallback
  })
}

export async function processingConvertDealerEnquiry(contractId, jobIds, processingLevel,) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/ConvertDealerEnquiry'),
    payload: { 
      ContractId: Number(contractId),
      JobKeys: jobIds.map((jobId) => Number(jobId)),
      ProcessingLevelId: processingLevel,
    },
    errorCallback
  })
}

export async function processingImage(contractId, jobKey, itemKey, imageType) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/Image'),
    payload: { 
      contractId,
      jobKey,
      itemKey,
      imageType,
    },
    errorCallback
  })
}

export async function processingAddStaffToContract(ContractId, JobKey, Role, StaffId) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/AddStaffToContract'),
    payload: { 
      ContractId,
      JobKey,
      Role,
      StaffId,
    },
    errorCallback
  })
}

export async function processingGetItemPriceBreakdown(CustomerId, ContractId, JobKey, ItemKey, ProcessingLevel) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/GetItemPriceBreakdown'),
    payload: { 
      CustomerId,
      ContractId,
      JobKey,
      ItemKey,
      ProcessingLevel,
    },
    errorCallback
  })
}

export async function UpdateFabricatorJobPriceOverride(
  CustomerId, ContractId, JobKey, OverrideCalculatedPrice, OverridePriceExTax
) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/UpdateFabricatorJobPriceOverride'),
    payload: { 
      CustomerId, 
      ContractId, 
      JobKey, 
      OverrideCalculatedPrice, 
      OverridePriceExTax
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  })
}

export async function UpdateFabricatorItemPriceOverride(
  CustomerId, ContractId, JobKey, ItemKey, OverrideItemCalculatedPrice, OverrideItemPriceExTax
) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/UpdateFabricatorItemPriceOverride'),
    payload: { 
      CustomerId, 
      ContractId, 
      JobKey, 
      ItemKey, 
      OverrideItemCalculatedPrice, 
      OverrideItemPriceExTax
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  })
}

export async function ApplyDiscountVoucher(discount) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/ApplyDiscountVoucher'),
    payload: discount,
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  })
}

export async function AvailableBranchesForContract(query) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/AvailableBranchesForContract'),
    payload: query,
    errorCallback: e => e
  })
}

export async function UpdateBranch(query) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/UpdateBranch'),
    payload: query,
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  })
}

export async function UpdateIssuePresentConfirmed({ ContractId, IssuePresentAccepted }) {
  return this.handleApiRequest({ 
    method: await this.sandboxStore.processingUrl('processing/UpdateIssuePresentConfirmed'),
    payload: { ContractId, IssuePresentAccepted },
    errorCallback
  })
}