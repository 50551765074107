import { useAuthStore } from '@/pinia/auth';
import { useCustomerStore } from '@/pinia/customer';

export default async (to, from, next) => {
  if (to.path === '/') {
    next({ path: '/business' });
  } else if (to.matched.some((record) => record.meta.businessRoute === true || record.meta.shared)) {
    const authStore = useAuthStore()
    const customerStore = useCustomerStore()
    const isLoginValid = await authStore.validateLogin('staff')
    if (isLoginValid) {
      customerStore.freshdeskWidgetEnabled = true;
      next();
    } else {
      customerStore.freshdeskWidgetEnabled = false;
      next({
        path: authStore.login_url,
        query: to.fullPath ? { redirect: to.fullPath } : undefined
     });
    }
  } else {
    next();
  }
};
