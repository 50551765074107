import { errorCallback } from '@/composables/validate';

export async function commonGetCurrencies() {
  return this.handleApiRequest({ 
    method: 'common/GetCurrencies', 
    errorCallback 
  })
}

export async function commonGetDatasets() {
  return this.handleApiRequest({ 
    method: 'setup/GetDatasets',
    errorCallback
  })
}

export async function commonOrganisationDetails() {
  return this.handleApiRequest({ 
    method: 'common/OrganisationDetails',
    errorCallback
  })
}

export async function getTermsAndConditionsDocument() {
  const response = await this.handleApiRequest({ 
    method: 'common/ListDocuments', 
    payload: {
      Type: Number(1),
      MyDocuments: true,
    },
    errorCallback
  })
  return response.documents[0];
}

export async function getPrivacyPolicyDocument() {
  const response = await this.handleApiRequest({ 
    method: 'common/ListDocuments', 
    payload: {
      Type: Number(2),
      MyDocuments: true,
    },
    errorCallback
  })
  return response.documents[0];
}

export async function commonGetProductSelectionGroups() {
  return this.handleApiRequest({ 
    method: 'common/GetProductSelectionGroups',
    errorCallback
  })
}

export async function commonGetProductSelectionTypes() {
  return this.handleApiRequest({ 
    method: 'common/GetProductSelectionTypes',
    errorCallback
  })
}

export async function commonGetCustomerStatuses() {
  return this.handleApiRequest({ 
    method: 'common/GetCustomerStatuses',
    errorCallback
  })
}
