import { defineStore } from 'pinia';
import { useAuthStore } from '@/pinia/auth';
import { useBasketStore } from '@/pinia/basket';

export const useVisualiserStore = defineStore('visualiser', {
  persist: true,
  state: () => ({
    reference: '',
    selectedImageId: null,
    internalView: false,
    images: [],
    visualisations: {},
    productSourceImages: [],
  }),
  actions: {
    setProductSourceImages(images) {
      this.productSourceImages = images;
    },
    updatePosition({ id, payload, imageWidth, imageHeight }) {
      const imageId = this.images.filter((image) => image.id === this.selectedImageId)[0].id;

      this.visualisations[imageId].items[id] =  {
        position: {
          ...this.visualisations[imageId].items[id].position,
          ...payload,
        },
        product: this.visualisations[imageId].items[id].product,
      };

      this.visualisations[imageId].width = imageWidth;
      this.visualisations[imageId].height = imageHeight;
    },
    deactivateAllImages() {
      Object.values(this.visualisations).forEach((visualisation) => {
        visualisation.items.forEach((item) => {
          item.position.active = false
        });
      });
    },
    addProduct({ product, image, imageWidth, imageHeight }) {
      if (this.visualisations[image.id] === undefined) {
        this.visualisations[image.id] = {
          id: image.id,
          width: imageWidth,
          height: imageHeight,
          reference: 'reference',
          insideView: false,
          items: [],
        };
      }

      this.visualisations[image.id].width = imageWidth;
      this.visualisations[image.id].height = imageHeight;

      this.visualisations[image.id].items.push({
        position: {
          x: 100,
          y: 100,
          width: null,
          height: null,
          angle: 0,
          active: true,
          randomKey: Math.random().toString(36).substr(2, 9),
        },
        product: {
          id: product.itemKey,
          image: this.productSourceImages
            .filter((sourceProduct) => sourceProduct.itemKey === product.itemKey)[0]
            .images.filter((productImage) => productImage.type === (this.internalView ? 2 : 1))[0]
            .image,
        },
      });
    },
    selectImage(id) {
      this.selectedImageId = id;
    },
    setReference(ref) {
      this.reference = ref;
    },
    setView(view) {
      const match = this.images.filter((image) => image.id === this.selectedImageId);

      if (match.length !== 1 || this.visualisations[match[0].id] === undefined) {
        return;
      }

      this.visualisations[match[0].id].insideView = view
    },
    setImages(images) {
      this.images = images;
    },
    syncVisualisations() {
      this.images.forEach((image) => {
        if (this.visualisations[image.id] === undefined) {
          this.visualisations[image.id] =  {
            id: image.id,
            width: 0,
            height: 0,
            reference: 'reference',
            insideView: false,
            items: [],
          };
        }
      });
    },
    setVisualisations(visualisations) {
      this.visualisations = visualisations;
    },
    clear() {
      this.setReference('')
      this.setImages([])
      this.selectImage(null)
      this.setVisualisations({})
    },
    activateItem(key) {
      this.deactivateAllImages()
      this.placedImages[key].position.active = true
    },
    async saveToServer() {
      const visualisations = Object.values(this.visualisations).map((visualisation) => ({
        Reference: this.reference ? this.reference : 'Your Home',
        VirtualHomeImageId: visualisation.id,
        Width: visualisation.width,
        Height: visualisation.height,
        Items: visualisation.items.map((item) => ({
          ItemKey: item.product.id,
          X: item.position.x,
          Y: item.position.y,
          X1: item.position.x1,
          Y1: item.position.y1,
          X2: item.position.x2,
          Y2: item.position.y2,
          X3: item.position.x3,
          Y3: item.position.y3,
          X4: item.position.x4,
          Y4: item.position.y4,
          Transform: item.position.transform,
          TransformOrigin: item.position['transform-origin'],
        })),
      }));

      // noinspection JSVoidFunctionReturnValueUsed
      await Promise.all(
        Object.values(this.visualisations).map(async (visualisation) => {
          if (visualisation.insideView) {
            return window.touch.visualisationUpdateImage(
              visualisation.id,
              undefined,
              visualisation.insideView,
            );
          }

          return Promise.resolve();
        }),
      );
      const basketStore = useBasketStore();
      await window.touch.processingSetVisualisations(
        basketStore.contractIdentifier.contractId,
        basketStore.contractIdentifier.jobKey,
        visualisations.filter((visualisation) => visualisation.Width > 0),
      );
    },
    async deleteProduct({ id }) {
      let deleted;

      do {
        deleted = false;
        Object.values(this.visualisations)
           
          .forEach((visualisation) => {
            visualisation.items.forEach((item, index) => {
              if (item.product.id === id) {
                delete visualisation.items[index]
                deleted = true;
              }
            });
          });
      } while (deleted);
      this.setVisualisations(this.visualisations)
    },
    async deleteActiveProduct() {
      const match = this.images.filter((image) => image.id === this.selectedImageId);

      const visualisation = this.visualisations[match[0].id]
      if (match.length !== 1 || visualisation === undefined) {
        return;
      }

      visualisation.items = visualisation.items.filter(x => !x.position.active)

      this.setVisualisations(this.visualisations)
    },
    async loadSourceImages(force = false) {
      const authStore = useAuthStore();
      const basketStore = useBasketStore();
      if (this.productSourceImages.length === 0 || force) {
        await this.setProductSourceImages(
          await Promise.all(
            basketStore.basket.map(async (item) => {
              const fullItem = await window.touch.jobLineItem(
                basketStore.customerId,
                basketStore.contractIdentifier.contractId,
                basketStore.contractIdentifier.jobKey,
                item.key,
                authStore.processingLevel,
                false,
                true,
              );

              return {
                ...item,
                ...fullItem,
              };
            }),
          ),  
        )
      }
    },
    async loadFromStorage() {
      const id = this.selectedImageId;

      if (id) {
        this.selectImage(id)
      }

      if (this.visualisations) {
        this.setVisualisations(this.visualisations)
      }

      if (this.reference) {
        this.setReference(this.reference)
      }
    },
    async updateReference({ id, reference, view }) {
      return window.touch.visualisationUpdateImage(id, reference, view);
    },
    async deleteImage({ id }) {
      this.selectedImageId = null
      return window.touch.visualisationDeleteImage(id);
    },
    async loadImages() {
      const response = await window.touch.visualisationListImages();
      this.setImages(response)
      this.syncVisualisations()
    },
    async refreshProductImages(reloadProducts) {
      this.loadSourceImages(reloadProducts)

      const internalView = this.selectedVisualisation
        ? this.selectedVisualisation.insideView
        : false;

      Object.values(this.visualisations).forEach((visualisation) => {
        visualisation.items.forEach((item) => {
          item.product.image = this.productSourceImages
            .filter((sourceProduct) => sourceProduct.itemKey === item.product.id)[0]
            .images.filter((productImage) => productImage.type === (internalView ? 2 : 1))[0]
            .image
        });
      });
    },
  },
  getters: {
    productIsSelected(state) {
      const match = state.images.filter((image) => image.id === state.selectedImageId);

      if (match.length !== 1 || state.visualisations[match[0].id] === undefined) {
        return false;
      }

      return (
        state.visualisations[match[0].id].items.find((element) => element.position.active) !==
        undefined
      );
    },
    placedImages(state) {
      const match = state.images.filter((image) => image.id === state.selectedImageId);

      if (match.length !== 1 || state.visualisations[match[0].id] === undefined) {
        return [];
      }

      return state.visualisations[match[0].id].items;
    },
    selectedVisualisation(state) {
      const match = state.images.filter((image) => image.id === state.selectedImageId);

      if (match.length !== 1 || state.visualisations[match[0].id] === undefined) {
        return null;
      }

      return state.visualisations[match[0].id];
    },
    selectedImage(state) {
      const match = state.images.filter((image) => image.id === state.selectedImageId);

      if (match.length === 1) {
        return match[0];
      }

      return null;
    },
  },
});
