<template>
  <portal to="portal_popup">
    <modal-window :modal_open="true" title="Account Settings" @close="$emit('close')">
      <div class="flex flex-col container gap-3">
        <div v-if="portalOnStop" class="bg-red-500 rounded px-5 py-3 text-center text-white text-lg">
          <strong>{{ description }}</strong>
        </div>
        <div class="flex gap-5 pb-10">
          <div class="flex-1">
            <div class="flex items-center font-medium gap-2">
              Disable Portal Processing
            </div>
            <div class="flex items-center cursor-pointer" @click="portalOnStop = !portalOnStop">
              <div class="relative">
                <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner" />
                <div class="switch-toggle" :class="{ '_active': portalOnStop }" />
              </div>
              <div class="ml-2 text-gray-700 font-medium whitespace-no-wrap">
                <span v-text="portalOnStop ? 'On' : 'Off'" />
              </div>
            </div>
          </div>
        </div>
        <div class="text-right mt-auto flex flex-col 2xs:block">
          <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
            Cancel
          </button>
          <button class="btn-action btn-lg" @click.prevent.stop="validate">
            Save Changes
          </button>
        </div>
      </div>
    </modal-window>
  </portal>
</template>

<script>

import { mapStores } from 'pinia'
import { useTouchStore } from '@/pinia/touch';
import { useStyleStore } from '@/pinia/style';

export default {
  data() {
    return {
      portalOnStop: undefined,
      description: 'Enabling this setting will disable processing for all Portal users'
    };
  },
  computed: {
    ...mapStores(useStyleStore, useTouchStore)
  },
  created() {
    this.portalOnStop = this.styleStore.fabricator.portalOnStop
  },
  methods: {
    async validate() {
      if (this.portalOnStop) {
        this.alertBox()
          .fire({
            title: 'Are you sure you want to enable this setting?',
            text: this.description,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await this.UpdateOrganisation()
            }
          });
      } else {
        await this.UpdateOrganisation()
      }
    },
    async UpdateOrganisation() {
      try {
        await this.touchStore.staffUpdateOrganisationDetails({ PortalOnStop: this.portalOnStop })
        this.$emit('close');
      } catch (error) {
        if (error.status === 400) {
          this.alertBox().fire(error.popUp);
        } else {
          throw error
        }
      }
    }
  },
};
</script>