<template>
  <touch-layout>
    <template v-if="quote && quote.consumer" #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[['Dashboard', '/'], ['Enquiries', '/enquiries'], [quote.customerCounter]]"
      />
    </template>
    <template v-if="quote && quote.consumer" #header_page_title>
      #{{ quote.customerCounter }}
    </template>
    <template v-if="quote && quote.consumer" #header_page_actions>
      <div class="md:px-10 flex items-center">
        <div
          v-if="can_see_accept_or_refuse_buttons"
          class="flex items-center"
          :class="{ 'opacity-50 pointer-events-none': loading }"
        >
          <button class="btn-action mr-1" @click="acceptEnquiry()">Accept</button>
          <button class="btn-action" @click="refuseEnquiry()">Refuse</button>
        </div>
      </div>
    </template>
    <loading v-if="loading || saving" :loading="loading || saving" :label="saving ? 'Saving' : 'Loading'" style="flex: 1 1 0" />
    <div v-else class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch p-3 xs:p-10">
      <template v-if="quote">
        <Teleport v-if="edit_consumer_open" defer to="#portal_popup">
          <consumer-popup
            :contract="quote"
            @close="edit_consumer_open = false"
            @saved="savedConsumer()"
          />
        </Teleport>
        <div v-if="quote.consumer" class="bg-white p-3 border">
          <consumer-details
            :redacted="isRedacted"
            :read-only="quote.fromDealer"
            :consumer="quote.consumer"
            @edit="edit_consumer_open = true"
          />
        </div>
        <div
          v-if="can_see_download_survey_sheet"
          key="action-btns"
          class="flex flex-col xs:flex-row flex-wrap mt-3 gap-3"
        >
          <div class="flex-1">
            <a
              class="btn-action whitespace-nowrap font-medium flex justify-between w-full p-3 px-6 relative"
              @click="showSurveyPdf()"
            >
              Download Survey Sheet
              <i class="fa fa-file-download text-sm" />
              <info-popup
                :id="'enquiry:download_survey_sheet'"
                class="-m-3 absolute right-0 top-0 _pop-out-left"
                :info_message="'If you wish to complete a home survey, download this pdf to take along to the customers home. You can then convert this to a quote and update as necessary.'"
                :next_id="'enquiry:customer_details'"
              />
            </a>
          </div>
          <div v-if="can_see_convert_to_quote && !isLeadGen" class="flex-1">
            <a
              class="btn-action whitespace-nowrap font-medium flex justify-between w-full p-3 px-6 relative"
              @click.prevent="convertToQuote()"
            >
              <span>Convert to Quote</span>
              <i class="fa fa-shopping-basket text-sm" />
              <info-popup
                :id="'job:generate_customer_quote'"
                class="-m-3 absolute right-0 top-0"
                :info_message="'This allows you to add a price uplift to your quote, this can be added to the overall quote or to each product line item. You can also add a fitting cost. You can then download the quote with these prices to send to your customer.'"
                :next_id="'job:download_price_request'"
              />
            </a>
          </div>
        </div>
        <div key="quote-details" data-index="0" class="flex flex-col xs:flex-row gap-3 my-3">
          <div class="flex flex-1 flex-col">
            <div class="bg-white border p-3 h-full">
              <div class="border-b font-bold mb-3 pb-3">Enquiry Details</div>
              <table class="">
                <tbody>
                  <tr>
                    <td class="pb-1 w-32">Enquiry Number:</td>
                    <td class="pb-1">
                      {{ quote.customerCounter }}
                    </td>
                  </tr>
                  <tr>
                    <td class="pb-1 w-32">Created at:</td>
                    <td class="pb-1">
                      {{ formatDate(quote.date) }}
                    </td>
                  </tr>
                  <tr v-if="notes">
                    <td class="pb-1 w-32">Notes:</td>
                    <td class="pb-1">
                      {{ notes }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="showPrice" class="flex flex-1 flex-col">
            <div class="bg-blue-100 border p-3 h-full">
              <div class="border-b font-bold mb-3 pb-3">
                Price
                <info-popup
                  :id="'enquiry:price'"
                  :info_message="'If you have configured pricing, your enquiries will land here with a pre-populated cost to the customer. This will only be seen by the customer if you have pricing switched on, on the website designer.'"
                  :next_id="'enquiry:convert_to_quote'"
                />
              </div>
              <table>
                <tbody>
                  <tr>
                    <td class="pb-1 w-32">Subtotal:</td>
                    <td
                      class="pb-1 text-brand-primary"
                      v-html="formatCurrency(job.customerCurrency, job.priceBeforeTax)"
                    />
                  </tr>
                  <tr>
                    <td class="pb-1 w-32">VAT:</td>
                    <td
                      class="pb-1 text-brand-primary"
                      v-html="formatCurrency(job.customerCurrency, job.tax)"
                    />
                  </tr>
                  <tr>
                    <td class="w-32">Total:</td>
                    <td
                      class="text-brand-primary"
                      v-html="formatCurrency(job.customerCurrency, job.priceIncludingTax)"
                    />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <hr key="divider" class="-mx-3 md:-mx-10">
        <div class="flex justify-between items-center my-6">
          <div class="text-2xl font-medium">Products</div>
        </div>
        <template v-if="job">
          <animation-staggered-fade>
            <contract-job-line-item
              v-for="(line_item, index) in sortedItems"
              :key="index"
              :job="job"
              :show-decoration-overrides="false"
              :show-sizes="!quote.noSizes"
              :show-price="showPrice"
              :data-index="index"
              :item="line_item"
              :item-number="index + 1"
              :total-items="job.items.length"
              :is-enquiry-line-item="true"
              breakdown="Cost"
            />
          </animation-staggered-fade>
        </template>
      </template>
    </div>
  </touch-layout>
</template>

<script>

import { mapStores } from 'pinia'
import { useStyleStore } from '@/pinia/style';
import { useBasketStore } from '@/pinia/basket';
import { useTouchStore } from '@/pinia/touch';
import { setJob } from '@/composables/job';
import { formatCurrency } from '@/composables/currency';
import { formatDate } from '@/composables/date';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import LineItem from '@/components/shared/job/LineItem.vue';
import ConsumerDetails from '@/components/shared/job/ConsumerDetails.vue';
import ConsumerPopup from '@/components/shared/ConsumerPopup.vue';

export default {
  components: {
    Breadcrumbs,
    'contract-job-line-item': LineItem,
    'consumer-details': ConsumerDetails,
    'consumer-popup': ConsumerPopup,
  },
  setup() {
    return { formatCurrency, formatDate }
  },
  data() {
    return {
      jobId: parseInt(this.$route.params.jobId || 1, 10),
      quote: null,
      notes: '',
      loading: true,
      saving: false,
      edit_consumer_open: false,
      other_actions_dropdown_open: false,
    };
  },
  computed: {
    ...mapStores(useStyleStore, useBasketStore, useTouchStore),
    isRedacted() {
      return (
        this.quote.fromDealer &&
        this.quote.dealerStatus !== this.enums.DealerStatus.ACCEPTED &&
        this.quote.dealerStatus !== this.enums.DealerStatus.QUOTED
      );
    },
    isLeadGen() {
      return (
        this.styleStore.fabricator?.accountProductStatus ===
        this.enums.ProductStatus.LEAD_GEN_ONLY
      );
    },
    sortedItems() {
      return this.job.items.slice().sort((a, b) => a.key - b.key);
    },
    showPrice() {
      return false;
    },
    job() {
      return this.quote.jobs ? setJob(this.quote.jobs[0]) : undefined
    },
    quote_name() {
      if (!this.quote.consumer) {
        return '';
      }

      return [this.quote.consumer.FirstName, this.quote.consumer.LastName]
        .filter(Boolean)
        .join(' ');
    },
    can_see_download_survey_sheet() {
      return (
        !this.quote.fromDealer ||
        (this.quote.fromDealer &&
          (this.quote.dealerStatus === this.enums.DealerStatus.ACCEPTED ||
            this.quote.dealerStatus === this.enums.DealerStatus.QUOTED))
      );
    },
    can_see_convert_to_quote() {
      return (
        !this.quote.fromDealer ||
        (this.quote.fromDealer && this.quote.dealerStatus === this.enums.DealerStatus.ACCEPTED)
      );
    },
    can_see_accept_or_refuse_buttons() {
      return (
        this.quote.fromDealer && this.quote.dealerStatus === this.enums.DealerStatus.AWAITINGACTION
      );
    },
  },
  async mounted() {
    await this.loadQuote(this.$route.params.id);
    this.loading = false;
  },
  methods: {
    async acceptEnquiry() {
      this.loading = true;
      try {
        await this.touchStore.updateDealerEnquiryStatus({
          dealerNetworkAssignmentId: this.quote.dealerNetworkAssignmentId,
          status: this.enums.DealerStatus.ACCEPTED,
        });
      } catch (error) {
        if (error.status === 400) {
          this.alertBox().fire(error.popUp);
        } else {
          throw error
        }
      }
      await this.loadQuote(this.$route.params.id);
      this.loading = false;
    },
    async refuseEnquiry() {
      this.loading = true;
      const { value: refusalReason } = await window.alertBox.fire({
        title: 'Refuse Enquiry',
        text: 'Why are you refusing this enquiry?',
        icon: 'warning',
        input: 'text',
        inputPlaceholder: 'Enter refusal reason...',
        showCancelButton: true,
        confirmButtonText: 'Refuse',
        inputValidator: (value) => !value && 'Please enter a refusal reason',
      });

      if (refusalReason) {
        try {
          await this.touchStore.updateDealerEnquiryStatus({
            dealerNetworkAssignmentId: this.quote.dealerNetworkAssignmentId,
            status: this.enums.DealerStatus.REFUSED,
            refusalReason,
          });
          await this.loadQuote(this.$route.params.id);
        } catch (error) {
          if (error.status === 400) {
            this.alertBox().fire(error.popUp);
          } else {
            throw error
          }
        }
        this.loading = false;
      }
    },
    async savedConsumer() {
      this.loading = true;
      this.edit_consumer_open = false;
      await this.loadQuote(this.$route.params.id);
      this.loading = false;
    },
    async showPdf() {
      this.loading = true;
      await window.touch.downloadQuoteReport(this.$route.params.id, this.jobId);
      this.loading = false;
    },
    async showSurveyPdf() {
      if (this.demo_mode) {
        window.touch.sampleSurveyReport();
        return;
      }

      this.loading = true;
      await window.touch.downloadManualSurveyReport(this.$route.params.id, this.jobId);
      this.loading = false;
    },
    async showRequotedPdf() {
      this.loading = true;
      await window.touch.downloadRequoteReport(this.$route.params.id, this.jobId);
      this.loading = false;
    },
    async loadQuote(id) {
      return Promise.all([
        this.touchStore.loadQuote({
          quoteId: id,
          jobId: this.jobId,
        }),
        this.touchStore.loadEnquiryNotes({
          contractId: id,
          jobId: this.jobId,
        }),
      ]).then(([quote, notes]) => {
        this.quote = quote;

        if (notes.length > 0) {
          this.notes = notes[0].text;
        }
      });
    },
    async deleteJob() {
      if (this.quote.jobs.length === 1) {
        this.alertBox().fire({
          title: 'Unable to delete',
          icon: 'error',
          html: 'Cannot delete last job in contract',
        });

        return;
      }
      const quoteId = this.$route.params.id;

      await this.touchStore.deleteJob({
        contractId: quoteId,
        jobId: this.jobId,
      });

      this.routerPush(`/quote/${quoteId}`);
    },
    async duplicateJob() {
      const quoteId = this.$route.params.id;

      const { value: newReference } = await this.alertBox().fire({
        title: 'Enter reference for new Job',
        input: 'text',
        inputPlaceholder: 'Enter Reference',
      });

      if (!newReference) {
        this.alertBox().fire('Cancelled');
        return;
      }

      await this.touchStore.copyJob({
        contractId: quoteId,
        jobId: this.jobId,
        reference: newReference,
      });

      this.routerPush(`/quote/${quoteId}`);
    },
    async convertToQuote() {
      await this.styleStore.loadFabricatorStyle()
      if (this.styleStore.fabricator.portalOnStop) {
        window.alertBox.fire({
          title: 'System under temporary maintenance',
          text: 'Please check back soon'
        });
        return;
      }
      this.loading = true;
      await this.basketStore.fromEnquiry({
        contractId: this.$route.params.id,
        jobKey: this.jobId || 1,
        fromDealer: this.quote.fromDealer,
      })
        .then(() => {
          this.basketStore.setButtonName('Quote');
          this.routerPush('/basket');
        });
    },
    async editJob() {
      this.basketStore.fromQuote({
        contractId: this.$route.params.id,
        jobKey: this.jobId,
      })
        .then(() => {
          this.basketStore.setReference(this.quote.reference);
          this.routerPush('/basket');
        })
        .catch((error) => {
          this.alertBox().fire({
            title: 'Error',
            text: error,
          });
        });
    },
    async reload() {
      this.loading = true;
      await this.loadQuote(this.$route.params.id);

      await window.touch.downloadRequoteReport(this.$route.params.id, this.jobId);
      this.saving = false;
      this.loading = false;
    },
  },
};
</script>
