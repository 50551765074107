import { errorCallback, successCallback } from '@/composables/validate';

export async function setupUpdateProducts(products) {
  return this.handleApiRequest({ 
    method: 'setup/UpdateProducts',
    payload: { 
      Products: products
     },
    errorCallback
  })
}

export async function setupOrderBusinessProducts(products) {
  return this.handleApiRequest({ 
    method: 'setup/OrderBusinessProducts',
    payload: { 
      Products: products
     },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save'),
  })
}
