<template>
  <div>
    <div v-if="override && touch_business_user" class="flex">
      <strong class="text-red-500">Manually Updated</strong>
    </div>
    <div
      v-if="((override || basketMainItems.some(x => x.override === true)) && !quantity)"
      class="line-through opacity-50"
      v-html="formatCurrency(basketCurrency, basketStore.job.calculatedPriceTaxable + deliveryCharge +
        basketStore.job.surveyPrice + basketStore.job.fittingPrice)"
    />
    <div
      :class="[is_admin_user && touch_business_user ? 'flex cursor-pointer' : 'flex pointer-events-none']"
      @click="override
        ? deleteOverride()
        : isPriceOverride = true, priceOverride = overridePrice"
    >
      <div
        class="flex items-center gap-2"
        :class="{ 'ml-auto': !quantity, 'line-through': itemKey && basketStore?.job?.overrideCalculatedPrice }"
        :title="(override ? 'Remove ' : 'Edit ') + title"
      >
        <div v-html="formatCurrency(basketCurrency, price)" />
        <span v-if="quantity > 1"> (<span class="font-medium" v-html="formatCurrency(basketCurrency, price / quantity)" /> each) </span>
        <div v-if="is_admin_user && touch_business_user">
          <i v-if="override" class="fal fa-times" /> 
          <i v-else class="fal fa-pencil" /> 
        </div>
      </div>
    </div>
    <Teleport v-if="isPriceOverride" defer to="#portal_popup">
      <modal-window :modal_open="isPriceOverride" :title="'Override ' + title" @close="isPriceOverride = false">
        <div class="flex flex-col">
          <div v-if="quantity" class="mb-2">Changes made will be reflected in the job level price.</div>
          <div v-else class="mb-2">Overriding the price will ignore individual item prices.</div>
          <div class="flex gap-2 items-center">
            <div class="flex border rounded border-black h-10 overflow-hidden max-w-sm">
              <div class="border-r border-black px-3 flex flex-col justify-center">
                {{ currencySymbol(basketCurrency) }} 
              </div>
              <input v-model.number="priceOverride" type="number" class="px-3 flex-grow" min="0">
            </div>
            <span v-if="quantity > 1 && priceOverride > 0">
              (<span class="font-medium" v-html="formatCurrency(basketCurrency, priceOverride / quantity)" /> each)
            </span>
          </div>
          <div v-if="quantity">
            <div class="my-4">Please note: Value entered will be divided by the item quantity.</div>
          </div>
          <div v-else-if="[enums.salesSectorType.RETAIL, enums.salesSectorType.TRADE].includes(salesSectorType)">
            <div class="my-4">Delivery charges will be calculated at checkout.</div>
          </div>
          <div class="mt-auto flex">
            <div class="ml-auto flex gap-2">
              <button class="btn btn-lg" @click.prevent="isPriceOverride = false">
                Cancel
              </button>
              <button class="btn-action btn-lg" @click.prevent.stop="addOverride">
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </modal-window>
    </Teleport>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';
import { useBasketStore } from '@/pinia/basket';
import { formatCurrency, currencySymbol } from '@/composables/currency';
import validate from 'validate.js';

export default {
  props: {
    override: Boolean,
    title: String,
    overridePrice: Number,
    basketMainItems: Array,
    itemKey: Number,
    basketCurrency: String,
    price: Number,
    quantity: Number,
    salesSectorType: Number,
    delivery: Object,
  },
  setup() {
    return { formatCurrency, currencySymbol }
  },
  data() {
    return {
      isPriceOverride: false,
      priceOverride: undefined,
      minimumOrderValue: undefined,
    };
  },
  computed: {
    ...mapStores(useCustomerStore, useBasketStore),
    deliveryCharge() {
      if (this.priceOverride < this.minimumOrderValue) {
        return this.delivery.useCustomerCharge ? this.delivery.deliveryCharge : this.customerStore.customer.delivery.deliveryCharge
      } else {
        return 0
      }
    }
  },
  mounted() {
    this.priceOverride = this.basketStore?.job?.overridePriceTaxable
    this.minimumOrderValue = this.delivery.useCustomerCharge ? this.delivery.minimumOrderValue : this.customerStore.customer.delivery.minimumOrderValue
  },
  methods: {
    async addOverride() {
      const validationErrors = validate(
        {
          priceOverride: this.priceOverride
        },
        {
          priceOverride: {
            presence: { allowEmpty: false },
          }
        },
      );

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }

      if (this.itemKey) {
        this.alertBox()
          .fire({
            title: 'Overriding Item Price will Reset Job Price',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              try {
                await window.touch.UpdateFabricatorItemPriceOverride(
                  this.basketStore.customerId,
                  this.basketStore.contractIdentifier.contractId,
                  this.basketStore.contractIdentifier.jobKey,
                  this.itemKey,
                  true,
                  this.priceOverride
                )
                await this.basketStore.refresh(true);
                this.isPriceOverride = false
                this.priceOverride = this.basketStore?.job?.overridePriceTaxable
              } catch (error) {
                if (error.status === 400) {
                  this.alertBox().fire(error.popUp);
                } else {
                  throw error
                }
              }
            }
          }
          )
      } else {
        this.alertBox()
          .fire({
            title: 'Overriding Job Price will Reset All Item Prices',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              try {
                await window.touch.UpdateFabricatorJobPriceOverride(
                  this.basketStore.customerId,
                  this.basketStore.contractIdentifier.contractId,
                  this.basketStore.contractIdentifier.jobKey,
                  true,
                  this.priceOverride
                )
              } catch (error) {
                if (error.status === 400) {
                  this.alertBox().fire(error.popUp);
                } else {
                  throw error
                }
              }
              await this.basketStore.refresh(true);
              this.isPriceOverride = false
              this.priceOverride = this.basketStore?.job?.overridePriceTaxable
            }
          }
          )
      }
    },
    async deleteOverride() {
      this.alertBox()
        .fire({
          title: 'Are you sure you want to delete this price override?',
          text: 'This action cannot be undone.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              if (this.itemKey) {
                await window.touch.UpdateFabricatorItemPriceOverride(
                  this.basketStore.customerId,
                  this.basketStore.contractIdentifier.contractId,
                  this.basketStore.contractIdentifier.jobKey,
                  this.itemKey,
                  false,
                  this.priceOverride
                )
              } else {
                await window.touch.UpdateFabricatorJobPriceOverride(
                  this.basketStore.customerId,
                  this.basketStore.contractIdentifier.contractId,
                  this.basketStore.contractIdentifier.jobKey,
                  false
                )
              }
            } catch (error) {
              if (error.status === 400) {
                this.alertBox().fire(error.popUp);
              } else {
                throw error
              }
            }
            await this.basketStore.refresh(true);
            this.isPriceOverride = false
            this.priceOverride = this.basketStore?.job?.overridePriceTaxable
          }
        });
    }
  }
};
</script>
