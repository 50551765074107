import { errorCallback, successCallback } from '@/composables/validate';

export async function listCarriers() {
  return this.handleApiRequest({ 
    method: 'carrier/List',
    errorCallback,
  })
}

export async function createCarrier(name, web, email, phone) {
  await this.handleApiRequest({ 
    method: 'carrier/Create',
    payload: {
      Name: name,
      Web: web,
      Email: email,
      Phone: phone,
    },
    errorCallback,
    successCallback: (r) => successCallback(r, 'Save')
  })
}

export async function updateCarrier(id, name, web, email, phone) {
  await this.handleApiRequest({ 
    method: 'carrier/Update',
    payload: {
      Id: id,
      Name: name,
      Web: web,
      Email: email,
      Phone: phone,
    },
    errorCallback,
    successCallback: (r) => successCallback(r, 'Save')
  })
}
