import { errorCallback } from '@/composables/validate';

export async function getAllVehicles() {
  return this.handleApiRequest({ 
    method: 'vehicle/List',
    errorCallback
  })
}

export async function createVehicle(description, registration, maxWeight) {
  return this.handleApiRequest({ 
    method: 'vehicle/Create',
    payload: {
      Description: description,
      Registration: registration,
      MaxWeight: maxWeight,
    },
    errorCallback
  })
}

export async function updateVehicle(id, description, registration, maxWeight) {
  return this.handleApiRequest({ 
    method: 'vehicle/Update',
    payload: {
      Id: id,
      Registration: registration,
      Description: description,
      MaxWeight: maxWeight,
    },
    errorCallback
  })
}
