<template>
  <div class="flex flex-col bg-gray-200 w-full h-full overflow-y-auto scrolling-touch">
    <div class="p-5 xs:p-10 flex-grow">
      <div class="mx-auto" style="max-width: 1550px">
        <div v-if="!loading">
          <div
            v-if="privacyPolicyDocument !== undefined && termsAndConditionsDocument !== undefined"
            class="bg-white p-4 xs:p-10 border"
          >
            <div class="mb-3">
              <strong class="text-base">
                Deploy {{ setup }}
                <info-popup
                  :id="'vendor_settings_website_details:deploy'"
                  class=""
                  :info_message="'You can personalise your website address to match your company name. Once you are happy, make sure you click to Save Changes and you can then pass this URL to your website developer who can then add links to your current website to link to your new designer.'"
                  :next_id="'vendor_settings_website_details:pricing_display'"
                />
              </strong>
            </div>
            <div class="flex flex-col justify-around mb-10">
              Follow the instructions below to deploy {{ setup }}.
            </div>
            <div class="flex flex-col mb-10">
              <strong class="text-base mb-3">
                {{ setup }} Address
              </strong>
              <div class="flex flex-col justify-around mb-3">
                Personalise the URL/website address for your {{ setup }}. This is usually your company
                name.
              </div>
              <div class="">
                <input
                  v-model="alias"
                  :disabled="!is_admin_user || demo_mode"
                  type="text"
                  class="form-control"
                >
              </div>
            </div>
            <div class="mb-3">
              <strong class="text-base"> How to deploy </strong>
            </div>
            <div class="mb-3 flex gap-3 items-center">
              <strong>Option 1 - deploy in new window</strong>
              <div class="px-2 py-1 text-white rounded bg-green-500 text-xs">RECOMMENDED</div>
            </div>
            <div class="mb-3">
              1) Click the 'Copy URL' button below.
            </div>
            <div class="mb-3">
              2) Add this link onto your website. We recommend linking this up to a button or banner. You
              can also add a link to the navigation of your website.
            </div>
            <div class="mb-3">
              3) Once the links have been added, your {{ setup }} will now be live on your website and any
              updates you make to your settings will be automatically applied to your live {{ setup }}.
            </div>
            <div class="mb-3 bg-gray-200 flex flex-col sm:flex-row items-center justify-between">
              <div class="p-5 overflow-y-auto text-blue-600">
                <span>
                  {{ launch_url }}
                  <input
                    id="designer_url"
                    type="text"
                    readonly
                    :value="launch_url"
                    style="position: absolute; top: -2000px; left: -2000px"
                  >
                </span>
              </div>
              <div class="px-5 pb-5 sm:p-5">
                <button
                  class="w-full sm:w-auto"
                  :class="{
                    'btn-action': copy_url_button_clicked,
                    'btn-primary': !copy_url_button_clicked,
                  }"
                  @click.prevent="copy_url_button_clicked = true, copy_iframe_button_clicked = false, copy('designer_url')"
                >
                  Copy URL
                  <transition name="fade-in" mode="out-in">
                    <i v-if="!copy_url_button_clicked" class="fal fa-copy ml-3" />
                    <i v-else class="fal fa-check ml-3" />
                  </transition>
                </button>
              </div>
            </div>
            <div class="mb-10">
              Tip: This link can also be posted directly to social media to generate further enquiries.
            </div>
            <div class="mb-3">
              <strong>Option 2 - deploy in an iFrame</strong>
            </div>
            <div class="mb-3">
              1) Click the 'Copy iFrame' button below.
            </div>
            <div class="mb-3">
              2) Place the code snippet within a container where you want it to appear on your website.
            </div>
            <div class="bg-gray-200 flex flex-col sm:flex-row items-center justify-between">
              <div class="p-5 overflow-y-auto max-w-4xl text-center sm:text-left text-blue-600">
                <span>
                  {{ iFrame }}
                  <input
                    id="designer_iframe"
                    type="text"
                    readonly
                    :value="iFrame"
                    style="position: absolute; top: -2000px; left: -2000px"
                  >
                </span>
              </div>
              <div class="px-5 pb-5 sm:p-5 flex-shrink-0">
                <button
                  class="w-full sm:w-auto"
                  :class="{
                    'btn-action': copy_iframe_button_clicked,
                    'btn-primary': !copy_iframe_button_clicked,
                  }"
                  @click.prevent="copy_iframe_button_clicked = true, copy_url_button_clicked = false, copy('designer_iframe')"
                >
                  <span class="normal-case">Copy iFrame</span>
                  <transition name="fade-in" mode="out-in">
                    <i v-if="!copy_iframe_button_clicked" class="fal fa-copy ml-3" />
                    <i v-else class="fal fa-check ml-3" />
                  </transition>
                </button>
              </div>
            </div>
          </div>
          <div v-else class="bg-white p-4 xs:p-10 rounded-lg text-red-600">
            Please upload your GDPR documents, you will then be able to deploy your designer.
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!loading && is_admin_user"
      class="sticky bottom-0 bg-white p-3 xs:p-4 text-right border-gray-300 border-t"
    >
      <div class="flex flex-col xs:flex-row justify-between">
        <div v-if="!demo_mode" class="mt-2 xs:mt-0 ml-auto">
          <button class="btn-action btn-lg w-full xs:w-auto" @click.prevent.stop="saveSettings()">
            Save all Changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import validate from 'validate.js';
import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';

export default {
  props: ['customerId', 'setup'],
  data() {
    return {
      loading: false,
      copy_url_button_clicked: false,
      copy_iframe_button_clicked: false,
      alias: '',
      priceDisplayMode: 0,
      privacyPolicyDocument: false,
      termsAndConditionsDocument: false,
      timeout: undefined
    };
  },
  computed: {
    ...mapStores(useCustomerStore),
    launch_url() {
      return `${window.location.origin.replace('.business.', '.portal.').replace('.admin.', '.portal.')}/launch/${this.customerStore.customer.eCommerceIntegration.alias}`;
    },
    iFrame() {
      const url = `${window.location.origin.replace('.business.', '.portal.').replace('.admin.', '.portal.')}/launch/${this.customerStore.customer.eCommerceIntegration.alias}`;
      const iframe = '<iframe style="border:1px #d1d5db solid" src="' + url + '" width="100%" height="600px" scrolling="yes" frameborder="yes" allow="fullscreen"></iframe>'
      return iframe
    }
  },
  async mounted() {
    await Promise.all([this.customerStore.getPrivacyPolicyDocument(), this.customerStore.getTermsAndConditionsDocument()]).then(
      ([privacyPolicyDocument, termsAndConditionsDocument]) => {
        this.privacyPolicyDocument = privacyPolicyDocument;
        this.termsAndConditionsDocument = termsAndConditionsDocument;
        this.priceDisplayMode = this.customerStore.customer.eCommerceIntegration.priceDisplayMode;
        this.alias = this.customerStore.customer.eCommerceIntegration.alias;
        this.loading = false;
      },
    );
  },
  methods: {
    async saveSettings() {
      this.loading = true;
      const options = {
        alias: this.alias,
        priceDisplayMode: this.priceDisplayMode,
      };

      const validationErrors = validate(
        {
          'Personalised Web Address': this.alias,
        },
        {
          'Personalised Web Address': {
            presence: { allowEmpty: false },
          },
        },
      );

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        this.loading = false;
        return;
      }

      try {
        await this.customerStore.customerUpdateCommerceIntegrationSettings({
          eCommerceIntegrationSettings: options,
          customerId: this.customerId,
        });
        const customer = await this.customerStore.customerGet(this.customerId)
        this.customerStore.setCustomer(customer)
      } catch (error) {
        if (error.status === 400) {
          this.alertBox().fire(error.popUp);
        } else {
          throw error
        }
      }
      this.loading = false;
    },
    copy(id) {
      clearTimeout(this.timeout)
      const input = document.getElementById(id);

      if (this.is_iOS) {
        const range = document.createRange();
        range.selectNodeContents(input);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        input.setSelectionRange(0, 999999);
      } else {
        input.select();
      }

      document.execCommand('copy');
      this.timeout = setTimeout(() => {
        this.copy_url_button_clicked = false;
        this.copy_iframe_button_clicked = false;
      }, 2000);
    },
  },
};
</script>
