<template>
  <router-view v-if="customer" :customer="customer" @update-customer="updateCustomer" />
</template>

<script>

import { mapStores } from 'pinia'
import { useBasketStore } from '@/pinia/basket';

export default {
  async beforeRouteEnter(to, from, next) {
    const customer = await window.touch.customerGet(to.params.customerId || to.params.id, true)
    next((vm) => {
      vm.customer = customer;
      vm.basketStore.setSalesSectorType(customer.customer.salesSectorType);
    });
  },
  data() {
    return {
      customer: undefined
    }
  },
  computed: {
    ...mapStores(useBasketStore),
  },
  methods: {
    async updateCustomer() {
      this.customer = await window.touch.customerGet(this.$route.params.customerId || this.$route.params.id, true)
    }
  }
};
</script>
