<template>
  <div>
    <div class="flex flex-col xs:flex-row flex-wrap">
      <div class="w-full">
        <div v-show="addresses && addresses.length > 0" class="">
          <div class="mb-2">
            <strong> Select address </strong>
          </div>
          <select
            :disabled="!is_admin_user"
            type="text"
            :value="selectedAddress"
            class="form-control"
            @change="selectAddress"
          >
            <option :value="0">Select address ...</option>
            <option v-for="address in addresses" :key="address.id" :value="address.id">
              {{ formattedAddress(address) }}
            </option>
          </select>
          <hr class="mt-6 mb-6">
          <div class="mb-2">
            <strong> ... or create a new one </strong>
          </div>
        </div>
        <AddressForm @address-updated="addressUpdated" />
      </div>
    </div>
    <div class="text-right">
      <button class="btn mr-2" @click.prevent="close">Cancel</button>
      <button class="btn-action" @click.prevent="saveAddress">Save new address</button>
    </div>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';
import validate from 'validate.js';
import AddressForm from '@/components/shared/address/AddressForm.vue';

export default {
  components: {
    AddressForm,
  },
  props: ['addresses', 'customerId', 'selectedAddress'],
  data() {
    return {
      newAddress: {
        line1: '',
        line2: '',
        line3: '',
        town: '',
        county: '',
        code: '',
        isSelectable: true,
        isPrimary: false,
        iso: 'GB',
        latitude: 0,
        longitude: 0,
      },
    };
  },
  computed: {
    ...mapStores(useCustomerStore),
  },
  methods: {
    formattedAddress(address) {
      return [
        address.line1,
        address.line2,
        address.line3,
        address.town,
        address.county,
        address.code,
      ]
        .filter((val) => val)
        .join(', ');
    },
    close() {
      this.$emit('close');
    },
    selectAddress(event) {
      this.$emit('select-address', parseInt(event.target.value, 10));
      this.$emit('close');
    },
    async saveAddress() {
      const validationErrors = validate(
        {
          line1: this.newAddress.line1,
          town: this.newAddress.town,
          country: this.newAddress.iso,
          postcode: this.newAddress.code,
        },
        {
          line1: {
            presence: { allowEmpty: false },
            length: {
              maximum: 64,
            },
          },
          town: {
            presence: { allowEmpty: false },
            length: {
              maximum: 64,
            },
          },
          country: {
            presence: { allowEmpty: false },
            length: {
              maximum: 3,
            },
          },
          postcode: {
            presence: { allowEmpty: false },
            length: {
              maximum: 16,
            },
          },
        },
      );

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }

      const newAddressList = [...this.addresses, this.newAddress];

      const customer = {
        addresses: newAddressList,
      };
      if (this.touch_business_installation) {
        customer.customerId = this.customerId;
      }

      try {
        const savedNewAddresses = await this.customerStore.customerUpdateVendorAddress(customer);

        this.$emit('new-address', {
          addresses: savedNewAddresses,
          addressId: savedNewAddresses[0].id,
        });

        this.$emit('close');
      } catch (error) {
        if (error.status === 400) {
          this.alertBox().fire(error.popUp);
        } else {
          throw error
        }
      }
    },
    addressUpdated(newAddress) {
      this.newAddress.line1 = newAddress.addressLine1;
      this.newAddress.line2 = newAddress.addressLine2;
      this.newAddress.line3 = newAddress.addressLine3;
      this.newAddress.town = newAddress.addressTown;
      this.newAddress.county = newAddress.addressCounty;
      this.newAddress.code = newAddress.addressCode;
      this.newAddress.iso = newAddress.addressIso;
      this.newAddress.isPrimary = newAddress.isPrimary;
    },
  },
};
</script>

<style scoped></style>
