import * as Sentry from '@sentry/browser';
import { resetPinia } from '@/composables/resetPinia';

export function errorCallback(error, authStore, brandedStore, router, url) {
  if (error.response) {
    const eventId = Sentry.captureEvent({
      message: `Status ${error.response.status} ${error.response.statusText} : ${url}`,
      extra: {
        ajax_input: error.response.config.data,
        response: error.response.data,
        headers: error.response.headers,
        status: error.response.status,
      },
    });

    if (error.response.status === 400) {
      handle400(error.response, url, eventId)
    } else if (error.response.status === 401) {
      handle401(authStore, brandedStore, router)
    } else if (error.response.status === 403) {
      handle403(error.response, url, eventId)
    } else {
      handle500(error.response, url, eventId)
    } 
  } else {
    Sentry.captureEvent({ message: 'Status 0 no response', extra: { error, eventId } })
  }
};

function handle400(error, url, eventId) {
  let message = '';
  if (typeof error.data === 'string') {
    message += `${error.data}<br>`;
  }
  if (error.data.title) {
    message += `${error.data.title}<br>`;
  }
  if (error.data.message) {
    message += `${error.data.message}<br>`;
  }
  if (error.data.issues) {
    message += error.data.issues.join('<br>');
  }
  if (error.data.errors) {
    message += Object.values(error.data.errors).join('<br>');
  }

  const newError = new Error(`Status ${error.status} ${error.statusText} : ${url}`)
  newError.status = error.status;
  newError.eventId = eventId

  if (message) {
    const title = message.split('<br>')[0];
    const html = message.split('<br>').slice(1).join('<br>');
    newError.popUp = { title, html };
  } else {
    newError.popUp = { title: 'One or more validation errors has occured' };
  }
  throw newError
}

function handle401(authStore, brandedStore, router) {
  window.logoutPush = true
  if (authStore.installation === 'public') {
    authStore.setBrandedMode(false)
    const launchURL = brandedStore.launchURL
    resetPinia(['basket', 'branded.slug'])
    router.push(launchURL).catch(() => {});
  } else if (router.currentRoute.path) {
    resetPinia(['basket', 'touch.isDealerNetworkPartnerOnly'])
    router.push(`${ authStore.login_url}?redirect=${router.currentRoute.path}`).catch(() => {});
  } else {
    resetPinia(['basket', 'touch.isDealerNetworkPartnerOnly'])
    router.push(`${ authStore.login_url}`).catch(() => {});
  }
  triggerPopUp({
    title: 'Unauthorised Access',
    text: 'Redirected user.'
  })
}

function handle403(error, url, eventId) {
  triggerPopUp({
    title: 'Forbidden Access',
    text: 'You don\'t have permission to access this resource.',
  })

  const newError = new Error(` Status ${error.status} ${error.statusText} : ${url}`)
  newError.status = error.status;
  newError.eventId = eventId
  throw newError
}

function handle500(error, url, eventId) {
  const newError = new Error(`Status  ${error.status} ${error.statusText} : ${url}`)
  newError.status = error.status;
  newError.eventId = eventId
  throw newError
}

function triggerPopUp(alertBoxObject) {
  if (alertBoxObject) {
    setTimeout(() => {
      if (window.alertBox.isVisible()) {
        window.alertBox.update(alertBoxObject)  
      } else {
        window.alertBox.fire(alertBoxObject)  
      } 
    }, 300) // How long sweetalert animations are
  }
}

export function successCallback (response, operation) {
  setTimeout(() => {
    if (!window.alertBox.isVisible()) {
      window.alertBox.fire({
        title: `${operation} Successful`,
        icon: 'success',
      });
    }
  }, 300) // How long sweetalert animations are
  return response
};
