<template>
  <Teleport defer to="#portal_popup"> 
    <modal-window :modal_open="true" title="Add Category" :loading="saving" @close="$emit('close')">
      <div class="font-medium">Category Name</div>
      <div>
        <input v-model="new_category.name" class="form-control">
      </div>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button class="btn-action btn-lg" @click.prevent.stop="save()">Save Changes</button>
      </div>
    </modal-window>
  </Teleport>
</template>

<script>

export default {
  data() {
    return {
      new_category: { name: '' },
      saving: false,
    };
  },
  methods: {
    async save() {
      if (!this.saving) {
        this.saving = true;

        if (this.new_category.name === '') {
          this.saving = false;
          this.alertBox().fire({
            title: 'Category Name Missing',
            text: 'Please enter a category name.',
            icon: 'error',
          });
        }

        try {
          await window.touch.addNewsCategory(this.new_category.name);
        } catch (error) {
          if (error.status === 400) {
            this.alertBox().fire(error.popUp);
          } else {
            throw error
          }
        }

        this.new_category = { name: '' };

        this.saving = false;
        this.$emit('category-created');
        this.$emit('close');
      }
    },
  },
};
</script>
