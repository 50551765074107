<template>
  <div class="h-full">
    <create-address
      v-if="add_address_popup_open"
      :customer-id="customer.customerId"
      @created="loadCustomer"
      @close="add_address_popup_open = false"
    />
    <loading
      id="customer_index_scrolling_outer_wrapper"
      :loading="loading"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch min-h-full"
    >
      <div class="bg-white border m-3 md:m-10 p-3">
        <table
          v-if="addresses.length > 0"
          class="table-lined xs:table-unstacked _with-hover w-full"
        >
          <thead>
            <tr>
              <th>#</th>
              <th>Line 1</th>
              <th>Line 2</th>
              <th>Line 3</th>
              <th>Town</th>
              <th>County</th>
              <th>Country</th>
              <th>Postcode</th>
              <th>Primary</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(address, index) in addresses"
              v-show="address.isSelectable"
              :key="index"
              :class="{ _hovered: address_hovered === index }"
              @click="editing_address = address.id"
              @mouseover="address_hovered = index"
              @mouseleave="address_hovered = null"
            >
              <td>{{ index + 1 }}</td>
              <td>
                <div class="td-title w-24">Line 1:</div>
                <div class="td-content">{{ address.line1 }}</div>
              </td>
              <td>
                <div class="td-title w-24">Line 2:</div>
                <div class="td-content">{{ address.line2 }}</div>
              </td>
              <td>
                <div class="td-title w-24">Line 3:</div>
                <div class="td-content">{{ address.line3 }}</div>
              </td>
              <td>
                <div class="td-title w-24">Town:</div>
                <div class="td-content">{{ address.town }}</div>
              </td>
              <td>
                <div class="td-title w-24">County:</div>
                <div class="td-content">{{ address.county }}</div>
              </td>
              <td>
                <div class="td-title w-24">Country:</div>
                <div class="td-content">{{ address.iso }}</div>
              </td>
              <td>
                <div class="td-title w-24">Postcode:</div>
                <div class="td-content">{{ address.code }}</div>
              </td>
              <td>
                <div class="td-title w-24">Primary Address:</div>
                <div class="td-content">
                  <template v-if="address.isPrimary"> Yes </template>
                  <template v-else> No </template>
                </div>
              </td>
              <td class="td-action-buttons">
                <div class="table-edit-btn">
                  <i class="fa fa-pencil" />
                </div>
                <edit-address
                  v-if="editing_address === address.id"
                  :address-id="address.id"
                  :customer-id="customer.customerId"
                  :logged-in-user="loggedInUser"
                  @updated="loadCustomer"
                  @close="(editing_address = null), loadCustomer()"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else>No Addresses to Show</div>
      </div>
      <div class="bg-white border m-3 md:m-10 p-3">
        <table class="table-lined xs:table-unstacked _with-hover w-full">
          <thead>
            <tr>
              <th>Mon</th>
              <th>Tues</th>
              <th>Wed</th>
              <th>Thurs</th>
              <th>Fri</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr
              :class="{ _hovered: delivery_day_hovered && touch_business_user }"
              @click.stop.prevent="openEditDeliveryDaysPopup()"
              @mouseover="delivery_day_hovered = true"
              @mouseleave="delivery_day_hovered = false"
            >
              <td>
                <div class="td-title w-24">Mon:</div>
                <div class="td-content" v-text="customer.delivery.onMonday ? 'Yes' : 'No'" />
              </td>
              <td>
                <div class="td-title w-24">Tues:</div>
                <div class="td-content" v-text="customer.delivery.onTuesday ? 'Yes' : 'No'" />
              </td>
              <td>
                <div class="td-title w-24">Wed:</div>
                <div class="td-content" v-text="customer.delivery.onWednesday ? 'Yes' : 'No'" />
              </td>
              <td>
                <div class="td-title w-24">Thurs:</div>
                <div class="td-content" v-text="customer.delivery.onThursday ? 'Yes' : 'No'" />
              </td>
              <td>
                <div class="td-title w-24">Fri:</div>
                <div class="td-content" v-text="customer.delivery.onFriday ? 'Yes' : 'No'" />
              </td>
              <td class="td-action-buttons">
                <div v-if="touch_business_user" class="table-edit-btn">
                  <i class="fa fa-pencil" />
                </div>
                <edit-delivery-days
                  v-if="edit_delivery_days_popup_open"
                  :prop_customer="customer"
                  :logged-in-user="loggedInUser"
                  @close="(edit_delivery_days_popup_open = false), loadCustomer()"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </loading>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { useCustomerStore } from '@/pinia/customer';
import EditDeliveryDays from '@/components/shared/EditDeliveryDays.vue';
import CreateAddress from '@/views/Business/Customer/AddressesCreate.vue';
import EditAddress from '@/views/Business/Customer/AddressesEdit.vue';

export default {
  components: {
    EditAddress,
    CreateAddress,
    EditDeliveryDays,
  },
  data() {
    return {
      loggedInUser: {},
      customer: {
        delivery: {},
        addresses: [],
      },
      loading: true,
      address_hovered: null,
      delivery_day_hovered: false,
      editing_address: null,
      add_address_popup_open: false,
      edit_delivery_days_popup_open: false,
    };
  },
  computed: {
    ...mapStores(useAuthStore, useCustomerStore),
    addresses() {
      return this.customer.addresses;
    },
  },
  async mounted() {
    await this.loadCustomer();
  },
  methods: {
    openEditDeliveryDaysPopup() {
      if (this.touch_business_user) {
        this.edit_delivery_days_popup_open = true;
      }
    },
    async loadCustomer() {
      this.loading = true;
      this.customer = await this.customerStore.customerGet(this.$route.params.id);
      this.$emit('setCustomer', this.customer);
      if (this.authStore.loggedIn) {
        this.loggedInUser.id = this.authStore.id;
        this.loggedInUser.isAdmin = this.authStore.admin;
      }
      this.loading = false;
    },
    deleteAddress(id) {
      this.alertBox()
        .fire({
          title: 'Are you sure?',
          text: 'Are you sure you want to delete this address?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then((result) => {
          if (result.value) {
            const foundIndex = this.addresses.findIndex((address) => address.id == id);
            this.addresses[foundIndex].isSelectable = false;
            this.customer.addresses = this.addresses;
            try {
              this.customerStore.customerUpdateAddresses(this.customer).then(() => {
                this.alertBox().fire({
                  title: 'Address Deleted',
                  icon: 'success',
                });
              });
            } catch (error) {
              if (error.status === 400) {
                this.alertBox().fire(error.popUp);
              } else {
                throw error
              }
            }
          }
        });
    },
    async saveSettings() {
      this.loading = true;
      try {
        await this.customerStore.customerUpdateDeliveryDays(this.customer);
      } catch (error) {
        if (error.status === 400) {
          this.alertBox().fire(error.popUp);
        } else {
          throw error
        }
      }
      this.loading = false;
    },
  },
};
</script>
