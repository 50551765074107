import { errorCallback } from '@/composables/validate';

export async function reportGenerate(contractId, jobId, reportType) {
  return this.download(`report/Generate/${contractId}/${jobId}/${reportType}`);
}

export function downloadRequoteReport(contractId, jobId) {
  return this.reportGenerate(contractId, jobId, 3);
}

export function downloadOrderReport(contractId, jobId) {
  return this.reportGenerate(contractId, jobId, 2);
}

export function downloadQuotePDF(contractId, jobId) {
  return this.reportGenerate(contractId, jobId, 10);
}

export function downloadQuoteReport(contractId, jobId) {
  return this.reportGenerate(contractId, jobId, 1);
}

export function downloadQuoteRequestReport(contractId, jobId) {
  return this.reportGenerate(contractId, jobId, 8);
}

export function downloadManualSurveyReport(contractId, jobId) {
  return this.reportGenerate(contractId, jobId, 6);
}

export function downloadPriceBreakdown(contractId, jobId) {
  return this.reportGenerate(contractId, jobId, window.enum.ReportType['Financial Breakdown']);
}

export function downloadGlassOrderReport(contractId, jobId) {
  return this.reportGenerate(contractId, jobId, 11);
}

export async function downloadJobXML(contractId, jobId) {
  const response = await this.handleApiRequest({ 
    method: 'export/GrabJobSuperUser',
    payload: { 
      ContractId: contractId,
      JobKey: jobId,
      },
    errorCallback,
    successCallback: r => r
  })

  if (response.status === 200) {
    const xml = response.data;
    const xmlDL = new Blob([xml], {
      type: 'text/xml',
    });
    const url = window.URL.createObjectURL(xmlDL);
    const link = document.createElement('a');
    link.href = url;
    const filename = `job-${contractId}.xml`;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  }

  return response;
}

export function downloadSampleReport(url) {
  const link = document.createElement('a');
  link.setAttribute('download', url);
  link.href = `https://store.bm-touch.co.uk/images/demo/${url}`;
  document.body.appendChild(link);
  link.click();
}

export function samplePriceRequestReport() {
  return this.downloadSampleReport('pricerequestpdf.pdf');
}

export function sampleSurveyReport() {
  return this.downloadSampleReport('surveysheetpdf.pdf');
}

export function sampleRequoteReport() {
  return this.downloadSampleReport('customerrequotepdf.pdf');
}
