<template>
  <div v-if="stockPartsStore.cartItems.length > 0">
    <div class="text-lg m-2 md:m-0 md:mt-6">Stock Parts</div>
    <div key="basket_stock_parts" class="bg-white shadow-lg rounded-lg m-2 md:m-0 md:mt-6 py-3">
      <div v-for="part in stockPartsStore.cartItems" :key="part.id" class="flex flex-col md:flex-row flex-grow">
        <div class="flex flex-col justify-around mx-6">
          <div class="pr-2">Part No. {{ part.code }}: {{ part.description }}</div>
        </div>
        <div class="flex items-center ml-auto">
          <div class="mx-6 flex items-center mr-0">
            <div class="mr-3 hidden md:block">Qty</div>
            <div class="flex" style="height: 37px">
              <input
                :value="part.quantity"
                class="w-8 border rounded text-center"
                @change="updateQtyForItem($event, part)"
              >
            </div>
          </div>
          <div class="flex items-center" style="width: 182px">
            <div class="border-l border-gray-300 mx-6" style="height: 37px" />
            <div class="flex flex-col justify-around w-full mr-3">
              <div
                class="cursor-pointer border border-gray-300 rounded p-2 ml-0 md:ml-1 m-1 flex hover:bg-gray-200 transition duration-500 ease-in-out"
                @click="stockPartsStore.deleteItem(part.itemKey)"
              >
                <i class="fal fa-trash-alt xs:mr-2" />
                <span class="text-xs hidden xs:flex flex-col justify-around">
                  <span>Delete <span class="hidden md:inline">Item</span></span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useStockPartsStore } from '@/pinia/stock-parts';

export default {
  computed: {
    ...mapStores(useStockPartsStore),
  },
  methods: {
    async updateQtyForItem($event, part) {
      try {
        await this.stockPartsStore.updateQtyForItem({
          itemId: part.stockItemId,
          quantity: Number.isNaN($event.target.value) ? 0 : $event.target.value,
        })
      } catch (error) {
        if (error.status === 400) {
          this.alertBox().fire(error.popUp);
        } else {
          throw error
        }
      }
    }
  }
};
</script>
