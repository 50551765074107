<template>
  <div
    id="customer_index_scrolling_outer_wrapper"
    class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch"
  >
    <CreateCarrier
      v-if="carrierModalOpen"
      :create-carrier-modal-model="createCarrierModalModel"
      @close="carrierModalOpen = false"
      @save="createRack"
      @update="updateRack"
    />
    <div class="m-3 xs:m-10 mb-0 flex flex-row items-center justify-between">
      <div class="text-2xl font-medium">Manage Carriers</div>
      <button class="btn-action" @click="openModal">Add Carrier</button>
    </div>
    <div class="bg-white border m-3 xs:m-10 p-3">
      <table v-if="carriers.length" class="table-lined xs:table-unstacked _with-hover w-full">
        <thead>
          <tr>
            <th>#</th>
            <th>Carrier Name</th>
            <th>Web</th>
            <th>Email</th>
            <th>Phone</th>
          </tr>
        </thead>
        <tr v-for="carrier in carriers" :key="carrier.id">
          <td>
            <div class="td-title w-24"> #: </div>
            <div class="td-content">{{ carrier.id }}</div>
          </td>
          <td>
            <div class="td-title w-24">Name: </div>
            <div class="td-content">{{ carrier.name }}</div>
          </td>
          <td>
            <div class="td-title w-24"> Web: </div>
            <div class="td-content">{{ carrier.web }}</div>
          </td>
          <td>
            <div class="td-title w-24"> Email: </div>
            <div class="td-content">{{ carrier.email }}</div>
          </td>
          <td>
            <div class="td-title w-24"> Phone: </div>
            <div class="td-content">{{ carrier.phone }}</div>
          </td>
          <td class="flex xs:td-action-buttons border-b xs:border-b-0">
            <div class="ml-auto flex pb-3 xs:pb-0">
              <div class="ml-auto table-edit-btn" @click="openModal(carrier.id)">
                <i class="fa fa-pencil" />
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import CreateCarrier from '@/components/business/rackManager/CreateCarrier.vue';

export default {
  components: {
    CreateCarrier,
  },
  data() {
    return {
      carriers: [],
      carrierModalOpen: false,
      createCarrierModalModel: undefined,
    };
  },
  async created() {
    this.loadCarriers();
  },
  methods: {
    async loadCarriers() {
      this.carriers = await window.touch.listCarriers();
    },
    async createRack(carrier) {
      this.carrierModalOpen = false;
      await window.touch.createCarrier(carrier.name, carrier.web, carrier.email, carrier.phone);
      await this.loadCarriers();
    },
    async updateRack(carrier) {
      this.carrierModalOpen = false;
      try {
        await window.touch.updateCarrier(
          carrier.id,
          carrier.name,
          carrier.web,
          carrier.email,
          carrier.phone,
        );
      } catch (error) {
        if (error.status === 400) {
          this.alertBox().fire(error.popUp);
        } else {
          throw error
        }
      }
      await this.loadCarriers();
    },
    openModal(id) {
      this.createCarrierModalModel = this.carriers.find((x) => x.id === id) ?? {};
      this.carrierModalOpen = true;
    },
  },
};
</script>
