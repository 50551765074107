import { errorCallback } from '@/composables/validate';

export async function loginstaff(username, password) {
  const response = await this.handleApiRequest({ 
    method: 'auth/loginstaff',
    payload: {
      Email: username,
      Password: password,   
    },
    errorCallback
  })

  if (response?.loginStatus === 0) {
    return {
      id: response.contactId,
      companyName: response.companyName,
      firstName: response.firstName,
      lastName: response.lastName,
      image: response.image,
      username,
      email: username,
      staffLogin: true,
      admin: response.admin,
      token: response.token,
      tokenExpiry: response.expiresAt,
    };
  }

  return false;
}

export async function logincustomer(username, password) {
  const response = await this.handleApiRequest({ 
    method: 'auth/logincustomer',
    payload: {
      Username: username,
      Password: password,
    },
    errorCallback
  })

  if (response?.loginStatus === 0) {
    return {
      companyName: response.companyName,
      firstName: response.firstName,
      lastName: response.lastName,
      image: response.image,
      username,
      email: username,
      admin: response.admin,
      id: response.contactId,
      isDemo: response.isDemo,
      token: response.token,
      tokenExpiry: response.expiresAt,
    };
  }

  return false;
}

export async function authVendorGuest(alias, returnUrl) {
  return this.handleApiRequest({ 
    method: 'auth/VendorGuest',
    payload: {
      Alias: alias,
      ReturnUrl: returnUrl,
    },
    errorCallback,
  })
}

export function authGetJWT() {
  return this.handleApiRequest({ 
    method: 'auth/GetJWT',
    errorCallback,
  })
}

export function setLogin(authToken, authTokenExpiry, installation) {
  this.installation = installation;
  this.token = authToken;
  this.tokenExpiry = authTokenExpiry;
}

export async function authGetMyStoreLinkToken() {
  return this.handleApiRequest({ 
    method: 'auth/GetMyStoreLinkToken',
    errorCallback,
  })
}

export async function requestPasswordReset(username) {
  return this.handleApiRequest({ 
    method: 'auth/RequestPasswordReset',
    payload: {
      Email: username,
    },
    errorCallback,
  })
}

export async function authGetCookie() {
  return this.handleApiRequest({ 
    method: { endpoint: '/api/', method: 'auth/GetCookie' },
    errorCallback,
  })
}

export async function resetPassword(token, password) {
  return this.handleApiRequest({ 
    method: 'auth/ResetPassword',
    payload: {
      Token: token,
      Password: password,
    },
    errorCallback,
  })
}

export async function checkPasswordResetToken(token) {
  return this.handleApiRequest({ 
    method: 'auth/CheckPasswordResetToken',
    payload: {
      Token: token,
    },
    errorCallback,
  })
}

export async function isDesignerAvailable(ecommerceAccountAlias) {
  return this.handleApiRequest({ 
    method: 'auth/isDesignerAvailable',
    payload: {
      ParentAccountAlias: import.meta.env.VITE_APP_FABRICATOR_ALIAS,
      EcommerceAccountAlias: ecommerceAccountAlias,
      Product: Number(window.enum.productType.BRANDED),
    },
    errorCallback,
    successCallback: (response) => response.data.issues,
  })
}

export async function authUpdateCompanyWebSettings(key, value ) {
  return this.handleApiRequest({ 
    method: 'auth/UpdateCompanyWebSettings',
    payload: {
      key,
      value,
    },
    errorCallback
  })
}

export async function authGetCompanyWebSettings() {
  return this.handleApiRequest({ 
    method: 'auth/GetCompanyWebSettings',
    errorCallback
  })
}

export async function authGetOrganisationWebSetting(searchKey) {
  return this.handleApiRequest({ 
    method: 'auth/GetOrganisationWebSetting',
    payload: {
      SearchKey: searchKey,
    },
    errorCallback
  })
}

export async function authLoginSuperUser(username, password, email) {
  const response = await this.handleApiRequest({ 
    method: 'auth/LoginSuperUser',
    payload: {
      Username: username,
      Password: password,
      ContactEmail: email,
    },
    errorCallback
  })

  if (response?.loginStatus === 0) {
    return  {
      companyName: response.companyName,
      firstName: response.firstName,
      lastName: response.lastName,
      image: response.image,
      username,
      email: username,
      admin: response.admin,
      id: response.contactId,
      superuser: true,
      token: response.token,
      tokenExpiry: response.expiresAt,
    };
  }

  return false;
}

export async function isProductAvailable(product, companyId) {
  return this.handleApiRequest({ 
    method: 'auth/IsProductAvailable',
    payload: {
      Product: product,
      CompanyId: companyId,
    },
    errorCallback,
    successCallback: (r) => (r)
  })
}

export async function CreateTradeAccountApplication(register) {
  return this.handleApiRequest({ 
    method: 'auth/IsProductAvailable',
    payload: register,
    errorCallback
  })
}

export async function VerifyEmail(VerificationCode) {
  return this.handleApiRequest({ 
    method: 'customer/VerifyEmail',
    payload: { VerificationCode },
    errorCallback,
    successCallback: (r) => (r)
  })
}