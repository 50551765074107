<template>
  <canvas id="Canvas3D" class="canvas3D w-full h-full bg-gray-200" @wheel.prevent />
</template>

<script>

import { mapStores } from 'pinia';
import { useBasketStore } from '@/pinia/basket';

export default {
  name: 'Show3d',
  props: {
    contractId: Number,
    jobKey: Number,
    itemKey: Number,
    trigger: Number
  },
  data() {
    return {
      model: null,
    };
  },
  computed: {
    ...mapStores(useBasketStore)
  },
  watch: {
    trigger() {
      this.loadModel();
    },
    'basketStore.imageType': {
      async handler(to) {
        window.rotateScene(window.babylonScene, window.babylonCamera);
      }
    },
    model(is) {
      if (window.touchFenestrationThreeD_BabylonEngine != null) {
        window.touchFenestrationThreeD_BabylonEngine.dispose();
      }
      const data1 = this.basketStore.imageType === this.enums.imageType.INTERNAL ? 'inside' : 'outside';
      const touchFenestrationThreeDBabylonEngine = window.touchFenestrationThreeD_RenderScene('Canvas3D', is, data1);
      window.touchFenestrationThreeD_BabylonEngine = touchFenestrationThreeDBabylonEngine.babylonEngine;
      window.babylonScene = touchFenestrationThreeDBabylonEngine.babylonScene;
      window.babylonCamera = touchFenestrationThreeDBabylonEngine.babylonCamera;
    },
  },
  mounted() {
    this.loadModel();
  },
  unmounted() {
    window.touchFenestrationThreeD_cleanUp();
  },
  methods: {
    async loadModel() {
      this.model = await window.touch.processingGet3DModel({
        contractId: this.contractId,
        customerId: this.basketStore.customerId,
        jobKey: this.jobKey,
        itemKey: this.itemKey,
      });
    },
  },
};
</script>