<template>
  <div class="relative px-3 border border-gray-500 rounded cursor-pointer">
    <div class="flex items-center gap-2 p-1" @click="dropdown = !dropdown">
      <span>{{ label }}: </span>
      <span class="ml-auto flex gap-2 items-center rounded">
        <i class="fal fa-filter" />
        <b v-if="$route.query[queryLabel] && filters.some(x => x.id == $route.query[queryLabel])">
          {{
            filters.find(x => x.id == $route.query[queryLabel]).name
          }}
        </b>
        <i v-if="dropdown" class="fal fa-chevron-up" />
        <i v-else class="fal fa-chevron-down" />
      </span>
    </div>
    <div v-show="dropdown" class="absolute bg-white z-50 border border-gray-300 text-xs rounded mt-1 left-0 w-full top-full">
      <div
        v-for="filter in filters"
        :key="filter.id"
        class="py-2 px-4 hover:bg-gray-200 flex"
        @click="toggleFilter(filter.id)"
      >
        <span class="flex items-center gap-2">
          <span class="rounded-full w-2 h-2" :class="[filter.dot ? filter.dot : 'bg-gray-500']" />
          <strong>{{ filter.name }}</strong>
        </span>
        <span v-show="$route.query[queryLabel] && $route.query[queryLabel] == filter.id" class="text-green-600 pl-2">
          <i class="fal fa-check" />
        </span>
      </div>
    </div>
    <div v-if="dropdown" class="fixed w-full h-full top-0 left-0 z-40" @click="dropdown = false" />
  </div>
</template>

<script>

export default {
  props: {
    filters: Array,
    label: String,
    queryLabel: String,
    defaultRequired: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dropdown: false,
    };
  },
  methods: {
    toggleFilter(filter) {
      let query = {}
      query[this.queryLabel] = (this.$route.query[this.queryLabel] == filter) && !this.defaultRequired ? undefined : filter
      this.$router.replace({ query: { ...this.$route.query, ...query } }).catch(() => { });
      this.dropdown = false
    },
    setDefaultFilter() {
      this.$router.replace({ query: undefined }).catch(() => { });
      this.dropdown = false
    },
  }
};
</script>