import { errorCallback, successCallback } from '@/composables/validate';

export async function latestNews({limit, offset, search}) {
  const response = await this.handleApiRequest({ 
    method: 'marketing/NewsItems',
    payload: { 
      Limit: limit,
      Offset: offset,
      GetTotal: true,
      Search: search
    },
    errorCallback
  })

  let newsItems = [];
  if (response.total > 0) {
    newsItems = response.newsItems.map((newsItem) => ({
      ...newsItem,
      url: `/news/${newsItem.id}`,
      edit_url: `/manage-news/edit/${newsItem.id}`,
    }));
  }
  return {
    news_items: newsItems,
    total_items: response.total,
  };
}

export async function newsItems({limit, offset, categoryId, search}) {
  const response = await this.handleApiRequest({ 
    method: 'marketing/NewsItems',
    payload: { 
      Limit: parseInt(limit, 10),
      Offset: parseInt(offset, 10),
      categoryId: Number(categoryId),
      search,
      GetTotal: true,
    },
    errorCallback
  })

  let articles = [];
  if (response.total > 0) {
    articles = response.newsItems.map((newsItem) => ({
      ...newsItem,
      url: `/news/${newsItem.id}`,
      edit_url: `/manage-news/edit/${newsItem.id}`,
    }));
  }
  return {
    news_items: articles,
    total_items: response.total,
  };
}

export async function getNewsItem(id) {
  return this.handleApiRequest({ 
    method: 'marketing/GetNewsItem', 
    payload: { Id: Number(id) },
    errorCallback
  })
}

export async function addNewsItem(newsItem) {
  return this.handleApiRequest({ 
    method: 'marketing/AddNewsItem', 
    payload: { 
      NewsCategoryId: newsItem.newsCategoryId,
      Title: newsItem.title,
      Paragraph: newsItem.paragraph,
      Summary: newsItem.summary,
      Image: newsItem.image,
     },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  })
}

export async function updateNewsItem(newsItem) {
  return this.handleApiRequest({ 
    method: 'marketing/UpdateNewsItem', 
    payload: { 
      Id: parseInt(newsItem.id, 10),
      NewsCategoryId: newsItem.newsCategoryId,
      Title: newsItem.title,
      Paragraph: newsItem.paragraph,
      Summary: newsItem.summary,
      Image: newsItem.image,
     },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  })
}

export async function deleteNewsItems(newsPostIds) {
  return this.handleApiRequest({ 
    method: 'marketing/DeleteNewsItems', 
    payload: { 
      Ids: newsPostIds,
     },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Delete')
  })
}

export async function getNewsCategories() {
  return this.handleApiRequest({ 
    method: 'marketing/NewsCategories', 
    errorCallback
  })
}

export async function addNewsCategory(categoryName) {
  return this.handleApiRequest({ 
    method: 'marketing/AddNewsCategory', 
    payload: {
      Name: categoryName, 
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  })
}

export async function updateNewsCategory(category) {
  return this.handleApiRequest({ 
    method: 'marketing/UpdateNewsCategory', 
    payload: {
      Id: Number(category.id),
      Name: category.name,
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  })
}

export async function deleteNewsCategories(categoryIds) {
  return this.handleApiRequest({ 
    method: 'marketing/DeleteNewsCategories', 
    payload: {
      Ids: categoryIds,
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Delete')
  })
}
