import { errorCallback, successCallback } from '@/composables/validate';

export async function getAllCompanyHolidays() {
  return this.handleApiRequest({ 
    method: 'company/GetCompanyHolidays', 
    errorCallback
  })
}

export async function addCompanyHoliday(title, dateStart, dateEnd) {
  await this.handleApiRequest({ 
    method: 'company/AddCompanyHoliday', 
    payload: {
      Title: title,
      DateStart: dateStart,
      DateEnd: dateEnd,   
    },
    errorCallback,
    successCallback: (r) => successCallback(r, 'Save')
  })
}

export async function updateCompanyHoliday(id, title, dateStart, dateEnd ) {
  await this.handleApiRequest({ 
    method: 'company/UpdateCompanyHoliday', 
    payload: {
      Id: id,
      Title: title,
      DateStart: dateStart,
      DateEnd: dateEnd,
    },
    errorCallback,
    successCallback: (r) => successCallback(r, 'Save')
  })
}

export async function deleteCompanyHoliday(id) {
  await this.handleApiRequest({ 
    method: 'company/DeleteCompanyHoliday', 
    payload: { Id: id },
    errorCallback,
    successCallback: (r) => successCallback(r, 'Delete')
  })
}

export async function getUserDetails() {
  return this.handleApiRequest({ 
    method: 'company/GetUserDetails', 
    errorCallback,
  })
}

export async function getListCompanies(CompanyType) {
  return this.handleApiRequest({ 
    method: 'company/ListCompanies',
    payload: { CompanyType },
    errorCallback,
  })
}

export async function GetOrganisation(companyId) {
  return this.handleApiRequest({ 
    method: 'company/GetOrganisation',
    payload: { companyId },
    errorCallback,
  })
}

export async function UpdateOrganisation(organisation) {
  return this.handleApiRequest({ 
    method: 'company/UpdateOrganisation',
    payload: organisation,
    errorCallback,
    successCallback: (r) => successCallback(r, 'Save'),
  })
}
