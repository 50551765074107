<template>
  <div class="bg-gray-200 w-full h-screen overflow-y-auto">
    <loading :loading="loading" class="scrolling-touch p-3 md:p-10 h-full">
      <template v-if="quote && job">
        <div
          v-if="quote.locked"
          key="locked-banner"
          class="flex bg-red-200 py-5 px-10 rounded-lg mb-6 xs:mb-10"
          data-index="1"
        >
          <div class="mr-6 hidden xs:flex flex-col justify-around">
            <i class="fal fa-lock-alt" />
          </div>
          <div
            v-if="lockedByUser"
            class="flex-grow flex flex-col xs:flex-row justify-between text-center xs:text-left"
          >
            <div v-if="lockedByUser.id == authStore.user.id" class="flex flex-col justify-around">
              <slot name="locked_by_user"> This quote is locked </slot>
            </div>
            <div v-else class="flex flex-col justify-around">
              This Quote is locked by {{ lockedByUser.firstName }} {{ lockedByUser.lastName }} -
              {{ lockedByUser.email }}
            </div>
            <div v-if="lockedByUser.id == authStore.user.id || isAdmin">
              <template v-if="quote_unlocking">
                <a class="w-full mt-3 xs:w-auto xs:mt-0 btn-action">
                  <i class="fal fa-unlock mr-2" /> Unlocking Quote
                </a>
              </template>
              <template v-else>
                <a class="w-full mt-3 xs:w-auto xs:mt-0 btn-danger" @click="unlockQuote()">
                  <i class="fal fa-unlock-alt mr-2" /> Unlock Quote
                </a>
              </template>
            </div>
          </div>
        </div>
        <Teleport v-if="edit_consumer_open" defer to="#portal_popup">
          <consumer-popup
            v-show="edit_consumer_open"
            :contract="quote"
            @close="edit_consumer_open = false"
            @saved="savedConsumer()"
          />
        </Teleport>
        <requote-popup
          v-if="requoter_open"
          :job="job"
          :markup="customerStore.requoteMarkup"
          @close="(requoter_open = false)"
          @saved="savedRequote()"
        />
        <slot name="consumer_details" />
        <slot name="portal_ctas" />
        <div class="grid xs:grid-cols-2 md:grid-cols-3 items-center gap-3">
          <slot name="quote-stages" />
          <slot name="surveyor" />
          <slot name="sales-person" />
        </div>
        <send-customer-quote-modal
          :open="send_customer_quote_modal_open"
          :customer="customer"
          :job="job"
          @close="send_customer_quote_modal_open = false"
          @saved="sentCustomerQuoteEmail"
        />
        <div key="quote-details" data-index="0" class="flex flex-col xs:flex-row gap-3 pt-3">
          <div class="flex-1 flex flex-col">
            <div class="bg-white border p-3 h-full">
              <div class="border-b font-bold mb-3 pb-3">Quote Details</div>
              <table class="">
                <tbody>
                  <tr>
                    <td class="pb-1 w-32">Quote Number:</td>
                    <td class="pb-1">
                      {{ quote.customerCounter }}
                    </td>
                  </tr>
                  <tr class="xs:hidden">
                    <td class="pb-1 w-32">Reference:</td>
                    <td class="pb-1">
                      {{ quote.reference }}
                    </td>
                  </tr>
                  <tr>
                    <td class="pb-1 w-32">Created at:</td>
                    <td class="pb-1">
                      {{ formatDate(quote.date) }}
                    </td>
                  </tr>
                  <tr>
                    <td class="pb-1 w-32">Quoted By:</td>
                    <td class="pb-1">
                      {{ quote.createdBy.name }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <calculated-price
            class="flex-1"
            :breakdown="breakdown"
            :is-costs-hidden="isCostsHidden"
            :job="job"
          >
            <template #cost_toggle>
              <slot name="cost_toggle" />
            </template>
            <template #financial_breakdown>
              <slot name="financial_breakdown" />
            </template>
          </calculated-price>
          <slot name="quoted_at_price" />
          <slot name="send-quote-notification" />
        </div>
        <div key="notes" data-index="0" class="flex flex-col xs:grid grid-cols-3 xs:flex-row gap-3 py-3">
          <slot
            name="customer_notes"
            :is-read-only-note="readOnly"
            :contract-id="quote.id"
            :job-id="job.jobKey"
            :note-type="enums.noteType.CUSTOMER"
            :notes="notes.filter((x) => x.noteType === enums.noteType.CUSTOMER)"
          />
          <slot
            name="internal_notes"
            :is-read-only-note="readOnly"
            :contract-id="quote.id"
            :job-id="job.jobKey"
            :note-type="enums.noteType.INTERNAL"
            :notes="notes.filter((x) => x.noteType === enums.noteType.INTERNAL)"
          />
          <div class="flex flex-1 flex-col">
            <div class="bg-white border p-3 h-full">
              <Note
                title="Supplier Notes"
                :read-only="readOnly"
                :contract-id="quote.id"
                :job-id="job.jobKey"
                :note-type="enums.noteType.SUPPLIER"
                :notes="notes.filter((x) => x.noteType === enums.noteType.SUPPLIER)"
                @load-notes="loadNotes"
              >
                Supplier Notes
              </Note>
            </div>
          </div>
        </div>
        <hr key="divider" class="-mx-3 md:-mx-10">
        <div class="2xs:flex justify-between items-center my-6">
          <div class="text-2xl font-medium">Products</div>
          <div class="flex justify-end -mx-1">
            <div v-if="quote.jobs.length > 1 && !readOnly" class="m-1">
              <a class="btn-action" @click="deleteJob()"> Delete </a>
            </div>
            <div v-if="!readOnly" class="m-1">
              <div>
                <a class="btn-action" @click="$emit('isEditJob', () => editJob())"> Edit Products </a>
                <info-popup
                  :id="'job:edit'"
                  class="-mx-3 mr-3"
                  :info_message="'Edit allows you to re-open the quote and make any amendments to the product specification.'"
                  :next_id="'job:download_survey_sheet'"
                />
              </div>
            </div>
            <slot name="quote_pdf" />
            <div v-if="!readOnly" class="m-1">
              <a class="btn-action" @click="$emit('isOrderJob', () => orderJob())"> Convert to Order </a>
            </div>
          </div>
        </div>
        <div v-if="job" class="pb-5">
          <contract-job-line-item
            v-for="(line_item, index) in sortedItems"
            :key="index"
            :job="job"
            :show-sizes="!quote.noSizes"
            :show-price="!isCostsHidden"
            :item="line_item"
            :discount-applied="job.discountApplied"
            :discount-code="job.discountCode"
            :item-number="index + 1"
            :total-items="sortedItems.length"
            :breakdown="breakdown"
          />
        </div>
        <template v-if="job && jobLevelExtras.length > 0">
          <job-level-extras :job="job" :extras="jobLevelExtras" />
        </template>
        <slot name="download_history" />
      </template>
    </loading>
  </div>
</template>

<script>
import JobLevelExtras from '@/components/shared/job/JobLevelExtras.vue';
import CalculatedPrice from '@/components/shared/job/CalculatedPrice.vue';
import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { useCustomerStore } from '@/pinia/customer';
import { useBasketStore } from '@/pinia/basket';
import { useTouchStore } from '@/pinia/touch';
import { setJob } from '@/composables/job';
import { formatDate } from '@/composables/date';
import SendCustomerQuoteModal from '@/components/shared/job/SendCustomerQuoteModal.vue';
import LineItem from '@/components/shared/job/LineItem.vue';
import RequotePopup from '@/components/shared/job/RequotePopup.vue';
import ConsumerPopup from '@/components/shared/ConsumerPopup.vue';
import Note from '@/components/shared/Note.vue';

export default {
  components: {
    'contract-job-line-item': LineItem,
    'requote-popup': RequotePopup,
    'consumer-popup': ConsumerPopup,
    Note,
    'job-level-extras': JobLevelExtras,
    'calculated-price': CalculatedPrice,
    'send-customer-quote-modal': SendCustomerQuoteModal,
  },
  props: {
    customer: Object,
    canSkipSop: Boolean,
    breakdown: String,
    isCostsHidden: Boolean,
  },
  setup() {
    return { formatDate }
  },
  data() {
    return {
      jobId: 1,
      quote: null,
      loading: true,
      requoter_open: false,
      edit_consumer_open: false,
      other_actions_dropdown_open: false,
      lockedByUser: false,
      quote_unlocking: false,
      send_customer_quote_modal_open: false,
      togglingVisibility: false,
      notes: [],
      isAdmin: false
    };
  },
  computed: {
    ...mapStores(useAuthStore, useCustomerStore, useBasketStore, useTouchStore),
    customerName() {
      try {
        return this.customer.company.name;
      } catch (e) {
        return 'Loading';
      }
    },
    customerId() {
      try {
        return this.customer.customerId;
      } catch (e) {
        return 'Loading';
      }
    },
    readOnly() {
      return this.quote.finalised || this.quote.locked || this.customer.customer.generalLicenseStatus === window.enum.generalLicenseStatus.DISABLED;
    },
    extraItems() {
      return this.sortedItems.filter((item) => item.inputType === window.enum.inputType.CUSTOMER);
    },
    jobLevelExtras() {
      return this.extraItems.filter((item) => item.parentItemKey === 0);
    },
    sortedItems() {
      return this.job.items.slice().sort((a, b) => a.key - b.key);
    },
    job() {
      return this.quote.jobs ? setJob(this.quote.jobs[0]) : undefined
    },
    quote_name() {
      if (!this.quote.consumer) {
        return '';
      }

      return [this.quote.consumer.FirstName, this.quote.consumer.LastName]
        .filter(Boolean)
        .join(' ');
    },
    backToQuoteUrl() {
      return `/quote/${this.$route.params.id}`;
    },
  },
  async created() {
    await this.loadQuote(this.$route.params.id);
    await this.loadNotes();
    this.isAdmin = await this.authStore.admin;
    this.loading = false;
  },
  methods: {
    async getQuotePDF() {
      this.loading = true;
      await window.touch.downloadQuotePDF(this.$route.params.id, this.jobId);
      this.loading = false;
    },
    async toggleVisibility() {
      // we don't need to do anything if the contract is being processed.
      if (this.togglingVisibility) {
        return;
      }
      this.$emit('setVisibility');
      // we can now safely remove the contract from the processing list
      this.togglingVisibility = false;
    },
    async unlockQuote() {
      this.quote_unlocking = true;
      await this.touchStore.unlockQuote({
        contractId: this.$route.params.id,
      });
      await this.loadQuote(this.$route.params.id);
      this.quote_unlocking = false;
    },
    async showPdf() {
      this.loading = true;
      await window.touch.downloadQuoteReport(this.$route.params.id, this.jobId);
      this.loading = false;
    },
    async showQuoteRequestPdf() {
      if (this.demo_mode) {
        window.touch.samplePriceRequestReport();
        return;
      }
      this.loading = true;
      await window.touch.downloadQuoteRequestReport(this.$route.params.id, this.jobId);
      this.loading = false;
    },
    async showSurveyPdf() {
      if (this.demo_mode) {
        window.touch.sampleSurveyReport();
        return;
      }

      this.loading = true;
      await window.touch.downloadManualSurveyReport(this.$route.params.id, this.jobId);
      this.loading = false;
    },
    async showRequotedPdf() {
      this.loading = true;
      await window.touch.downloadRequoteReport(this.$route.params.id, this.jobId);
      this.loading = false;
    },
    async loadQuote(id) {
      this.quote = await this.touchStore.loadQuote({
        quoteId: id,
        customerId: this.$route.params.customerId,
        jobId: this.jobId,
      });
      this.$emit('setQuote', this.quote);

      if (this.quote.locked) {
        await this.loadLockedByUser(this.quote.lockedBy);
      }
    },
    async loadLockedByUser(id) {
      const resp = await this.customerStore.customerGet(this.$route.params.customerId)
      const result = resp.users.filter((usr) => usr.id === id)[0];
      const staffResp = await this.customerStore.customerListContacts();
      const staffResult = staffResp.filter((usr) => usr.id === id)[0];
      if (result || staffResult) {
        this.lockedByUser = result ?? staffResult;
      } else {
        await this.unlockQuote();
      }
    },
    async deleteJob() {
      if (this.quote.jobs.length === 1) {
        this.alertBox().fire({
          title: 'Unable to delete',
          icon: 'error',
          html: 'Cannot delete last job in contract',
        });

        return;
      }

      this.alertBox()
        .fire({
          title: 'Are you sure you want to delete this job?',
          text: 'This will not be recoverable.',
          showCancelButton: true,
        })
        .then(async (response) => {
          if (response.isConfirmed) {
            this.loading = true;
            const quoteId = this.$route.params.id;

            await this.touchStore.deleteJob({
              contractId: quoteId,
              jobId: this.jobId,
            });

            this.routerPush(`/quote/${quoteId}`);
          }
        });
    },
    async duplicateJob() {
      const quoteId = this.$route.params.id;

      const { value: newReference } = await this.alertBox().fire({
        title: 'Enter reference for new Job',
        input: 'text',
        inputPlaceholder: 'Enter Reference',
      });

      if (!newReference) {
        this.alertBox().fire('Cancelled');
        return;
      }
      this.loading = true;

      const newJobKey = await this.touchStore.copyJob({
        contractId: quoteId,
        jobId: this.jobId,
        reference: newReference,
      });

      this.routerPush(`/quote/${quoteId}/${newJobKey}`, () => {
        this.jobId = newJobKey;
        this.loadQuote(this.$route.params.id);
        this.loading = false;
      });
    },
    async orderJob() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$emit('setCustomerId', this.$route.params.customerId);
      const orderId = await window.touch.contractConvertToOrder(
        this.$route.params.id,
        [this.jobId],
        this.$route.params.customerId,
      );
      if (orderId) {
        this.basketStore.setContract({
          contractId: orderId,
          jobKey: 1,
          isOrder: true,
          isEnquiry: false,
        });
        window.gtag('event', 'quote-to-order-conversion');
        this.basketStore.setReference(this.quote.reference);
        this.basketStore.setButtonName('Order');
        this.$emit('redirectToCheckout', orderId);
      } else {
        this.loading = false;
      }
    },
    async editJob() {
      this.loading = true;
      this.$emit('setCustomerId', this.$route.params.customerId);
      this.basketStore.fromQuote({
        contractId: this.$route.params.id,
        jobKey: this.jobId,
      })
        .then((response) => {
          if (response) {
            this.basketStore.setReference(this.quote.reference);
            this.$emit('redirectToBasket');
          } else {
            this.loading = false;
          }
        })
    },
    downloadRequoteReport() {
      if (this.demo_mode) {
        window.touch.sampleRequoteReport();
      } else {
        window.touch.downloadRequoteReport(this.$route.params.id, this.jobId);
      }
    },
    async savedRequote() {
      this.loading = true;
      await this.loadQuote(this.$route.params.id);
      this.requoter_open = false;
      this.loading = false;
    },
    async savedConsumer() {
      this.loading = true;
      this.edit_consumer_open = false;
      await this.loadQuote(this.$route.params.id);
      this.loading = false;
    },
    async sentEmail() {
      this.loading = true;
      await this.loadQuote(this.$route.params.id);
      this.loading = false;
    },
    open_swal_warning_for_no_consumer() {
      this.alertBox().fire({
        title: 'Please enter consumer details',
        text: 'You can send an email to the consumer once their details have been entered',
      });
    },
    sentCustomerQuoteEmail() {
      this.alertBox().fire({
        title: 'Email Sent',
      });
    },
    async loadNotes(action = () => { }) {
      this.notes = await this.touchStore.loadNotes({
        contractId: this.quote.id,
        jobId: this.jobId,
      });
      action()
    },
  },
};
</script>
