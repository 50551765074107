import { errorCallback, successCallback } from '@/composables/validate';

export async function customerUpdateCompanySettings(showSizes, showDesignNames) {
  return this.handleApiRequest({ 
    method: 'customer/UpdateCompanySettings',
    payload: {
      ShowconsumerSizes: showSizes,
      ShowDesignNames: showDesignNames
    },
    errorCallback,
    successCallback: (r) => successCallback(r, 'Save'),
  })
}

export async function customerGetCompanySettings() {
  return this.handleApiRequest({ 
    method: 'customer/GetCompanySettings',
    errorCallback,
  })
}
export async function customerGetProcessingLevels() {
  return this.handleApiRequest({ 
    method: 'customer/GetProcessingLevels',
    errorCallback,
  })
}

export async function customerUpdateProductDefault(id, productId, optionItemId, datasetId, customerId) {
  return this.handleApiRequest({ 
    method: 'customer/UpdateProductDefault',
    payload: {
      Id: id,
      ProductId: productId,
      OptionItemId: optionItemId,
      DatasetId: datasetId,
      CustomerId: customerId,
    },
    errorCallback,
  })
}

export async function customerGetProductDefaultsWithDetails(productId, datasetId, customerId) {
  return this.handleApiRequest({ 
    method: 'customer/GetProductDefaultsWithDetails',
    payload: {
      ProductId: productId,
      DatasetId: datasetId,
      CustomerId: customerId,
    },
    errorCallback,
  })
}

export async function customerGetProductsWithDefaults(datasetId) {
  return this.handleApiRequest({ 
    method: 'customer/GetProductsWithDefaults',
    payload: {
      DatasetId: datasetId,
    },
    errorCallback,
  })
}

export async function customerGetExtraItemOptions(itemId) {
  return this.handleApiRequest({ 
    method: 'customer/GetExtraItemOptions',
    payload: { itemId },
    errorCallback,
  })
}

export async function customerUpdateExtraItemOptions( params ) {
  return this.handleApiRequest({ 
    method: 'customer/UpdateExtraItemOptions',
    payload: { Options: params },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save'),
  })
}

export async function customerUpdateExtraItems(params) {
  return this.handleApiRequest({ 
    method: 'customer/UpdateExtraItems',
    payload: { Items: params },
    errorCallback,
    successCallback: (r) => successCallback(r.data, params.length === 1 && params[0].Delete ? 'Delete' : 'Save'),
  })
}

export async function customerUpdateExtraItemGroups(params) {
  return this.handleApiRequest({ 
    method: 'customer/UpdateExtraItemGroups',
    payload: { Groups: params },
    errorCallback,
    successCallback: (r) => successCallback(r.data, params[0].Delete ? 'Delete' : 'Save'),
  })
}

export async function customerGetAllExtraItems() {
  return this.handleApiRequest({ 
    method: 'customer/GetAllExtraItems',
    errorCallback
  })
}

export async function customerGetExtraItemGroups() {
  return this.handleApiRequest({ 
    method: 'customer/GetExtraItemGroups',
    errorCallback
  })
}

export async function customerGetExtraItems(GroupId) {
  return this.handleApiRequest({ 
    method: 'customer/GetExtraItems',
    payload: { GroupId },
    errorCallback
  })
}

export async function customerGet(customerId, staffLogin) {
  let options = {};
  if (staffLogin) {
    options = {
      CustomerId: parseInt(customerId, 10),
    };
  }

  return this.handleApiRequest({ 
    method: 'customer/Get',
    payload: options,
    errorCallback
  })
}

export async function customerGetGuest() {
  return this.handleApiRequest({ 
    method: 'customer/GetForGuest',
    errorCallback
  })
}

export async function customerUpdateDeliveryDays(
  customer,
  staffLogin,
) {
  const Customer = {
    Delivery: customer.delivery,
  };
  if (staffLogin) {
    Customer.CustomerId = customer.customerId;
  }

  return this.handleApiRequest({ 
    method: 'customer/Update',
    payload: Customer,
    errorCallback,
    successCallback: (r) => successCallback(r, 'Save'),
  })
}

export async function customerUpdateUsers(
  customer,
  staffLogin,
  userIdsToUpdate,
) {
  let usersToUpdate = [];

  if (!userIdsToUpdate) usersToUpdate = customer.users.filter((x) => !x.id);
  else usersToUpdate = customer.users.filter((x) => userIdsToUpdate.find((y) => y === x.id));

  const Users = usersToUpdate.map((user) => ({
    ...user,
    licenseStatusType: Number(user.licenseStatusType),
    processingLevelId: Number(user.processingLevelId),
  }));
  const Customer = {
    Users,
  };
  if (staffLogin) {
    Customer.CustomerId = customer.customerId;
  }

  return this.handleApiRequest({ 
    method: 'customer/Update',
    payload: Customer,
    errorCallback,
    successCallback: (r) => successCallback(r, 'Save'),
  })  
}

export async function customerUpdateAddresses(
  customer,
  staffLogin,
) {
  const Customer = {
    Addresses: customer.addresses,
  };
  if (staffLogin) {
    Customer.CustomerId = customer.customerId;
  }

  return this.handleApiRequest({ 
    method: 'customer/Update',
    payload: Customer,
    errorCallback,
    successCallback: (r) => successCallback(r, 'Save'),
  })  
}

export async function customerUpdateVendorAddress(
  customer,
  staffLogin,
) {
  const Customer = {
    Addresses: customer.addresses,
  };
  if (staffLogin) {
    Customer.CustomerId = customer.customerId;
  }

  await this.handleApiRequest({ 
    method: 'customer/Update',
    payload: Customer,
    errorCallback,
    successCallback: (r) => successCallback(r, 'Save'),
  })  

  let params = {};

  if (staffLogin) {
    params = {
      CustomerId: customer.customerId,
    };
  }

  const { addresses } =  await this.handleApiRequest({ 
    method: 'customer/Get',
    payload: params,
    errorCallback,
  })  

  return addresses;
}

export async function customerCreate(customer) {
  const Customer = {
    Name: customer.name,
    SalesSector: customer.salesSector,
    Email: customer.email,
    Phone: customer.phone,
    Web: customer.web,
    DefaultTaxId: customer.defaultTaxId,
    Branches: customer.branches,
  };

  const { id } = await this.handleApiRequest({ 
    method: 'customer/Create',
    payload: Customer,
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save'),
  })  
  return id;
}

export async function customerListContacts() {
  return this.handleApiRequest({ 
    method: 'customer/ListContacts',
    errorCallback,
  })  
}

export async function customerGetFinancialGroups(datasetId) {
  return this.handleApiRequest({ 
    method: 'customer/GetFinancialGroups',
    payload: {
      DatasetId: datasetId,
    },
    errorCallback,
  })  
}

export async function customerGetContact(ContactId) {
  return this.handleApiRequest({ 
    method: 'customer/GetContact',
    payload: {
      ContactId: parseInt(ContactId, 10),
    },
    errorCallback,
  }) 
}

export async function customerEditContact(Contact) {
  const user = Contact;
  user.licenseStatusType = Number(Contact.licenseStatusType);

  return this.handleApiRequest({ 
    method: 'customer/EditContact',
    payload: user,
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  }) 
}

export async function customerCreateContact(Contact) {
  return this.handleApiRequest({ 
    method: 'customer/CreateContact',
    payload: Contact,
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  }) 
}

export async function customerUpdateCommerceIntegrationSettings(
  eCommerceIntegrationSettings,
  customerId,
) {
  const Customer = {
    ECommerceIntegration: {
      Alias: eCommerceIntegrationSettings.alias,
      WebAddress: eCommerceIntegrationSettings.webAddress,
      Email: eCommerceIntegrationSettings.email,
      mobilePhone: eCommerceIntegrationSettings.mobilePhone,
      privacyPolicyDocumentId: eCommerceIntegrationSettings.privacyPolicyDocumentId,
      addressId: eCommerceIntegrationSettings.addressId,
      companyRegNumber: eCommerceIntegrationSettings.companyRegNumber,
      fensaRegistrationNumber: eCommerceIntegrationSettings.fensaRegistrationNumber,
      vatRegistrationNumber: eCommerceIntegrationSettings.vatRegistrationNumber,
      priceDisplayMode: Number(eCommerceIntegrationSettings.priceDisplayMode),
    },
    CustomerId: customerId,
  };
  return this.handleApiRequest({ 
    method: 'customer/Update',
    payload: Customer,
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  }) 
}

export async function customerUpdateFinancialSettings({
  vatEnabled,
  taxValue,
  fittingRate,
}) {
  const Customer = {
    Financial: {
      vatEnabled: Boolean(vatEnabled),
      taxValue: Number(taxValue),
      fittingRate: Number(fittingRate),
    },
  };
  await this.handleApiRequest({ 
    method: 'customer/Update',
    payload: Customer,
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  }) 
}

export async function customerGetProductOverrides() {
  return this.handleApiRequest({ 
    method: 'customer/GetProductOverrides',
    errorCallback,
  }) 
}

export async function customerGetAllProductsWithOverrides(customerId) {
  return this.handleApiRequest({ 
    method: 'customer/GetAllProductsWithOverrides',
    payload: {
      CustomerId: customerId !== undefined ? Number(customerId) : undefined,
    },
    errorCallback,
  }) 
}

export async function customerUpdateProductOverrides(overrides, customerId) {
  return this.handleApiRequest({ 
    method: 'customer/UpdateProductOverrides',
    payload: {
      CustomerId: customerId,
      Overrides: overrides,
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  }) 
}

export async function customerUploadDocumentWithUrl(document) {
  const { id } = await this.handleApiRequest({ 
    method: 'customer/UploadDocumentWithUrl',
    payload: {
      Id: document.id,
      Type: Number(document.type),
      CategoryId: document.categoryId,
      Name: document.name,
      OverwriteCurrentSingleDocument: true,
      Url: document.url,
      VideoType: document.videoType,
      DealerNetworkPartnerOnly: document.DealerNetworkPartnerOnly,
      ImageBlob: document.ImageBlob,
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  }) 
  return id;
}

export async function UpdateCustomerOnlyGlass(CustomerId, UseCustomerOnlyGlass) {
  return this.handleApiRequest({ 
    method: 'customer/UpdateCustomerOnlyGlass',
    payload: {
      CustomerId,
      UseCustomerOnlyGlass,
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  })
}

export async function customerUpdateRaw(customer) {
  return this.handleApiRequest({ 
    method: 'customer/Update',
    payload: customer,
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  })
}

export async function updateCustomerRequoteDetails(customer) {
  return this.handleApiRequest({ 
    method: 'customer/UpdateCustomerRequoteDetails',
    payload: {
      CustomerId: customer.customerId,
      RequoteEmail: customer.requoteEmail,
      RequoteEmailSubject: customer.requoteEmailSubject,
      RequoteEmailBodyText: customer.requoteEmailBodyText,
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  })
}

export async function customerUpdateLogo(logo, customerId) {
  return this.handleApiRequest({ 
    method: 'customer/customer',
    payload: {
      Styling: {
        logo,
      },
      CustomerId: customerId,
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  })
}

export async function listTicketContacts() {
  return this.handleApiRequest({ 
    method: 'customer/ListTicketContacts',
    errorCallback
  })
}

export async function organisationUpdate(organisationId, users, defaultTicketContact, workingDays) {
  const obj = {
    organisationId,
    users,
  };
  if (defaultTicketContact) {
    obj.company = {};
    obj.company.DefaultTicketContact = defaultTicketContact;
  }
  if (workingDays) {
    obj.company = {};
    obj.company.WorkingDays = workingDays;
  }
  return this.handleApiRequest({ 
    method: 'organisation/Update',
    payload: obj,
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  })
}
export async function organisationGet() {
  return this.handleApiRequest({ 
    method: 'organisation/Get',
    errorCallback
  })
}

export async function resendActivationEmail(contactId) {
  return this.handleApiRequest({ 
    method: 'Customer/ResendActivationEmail',
    payload: {
      ContactId: contactId,
    },
    errorCallback
  })
}

export async function customerCreateBranch(branch) {
  const Branch = {
    Name: branch.name,
    Email: branch.email,
    Phone: branch.phone,
    Address: {
      Town: branch.town,
      County: branch.county,
    },
  };
  return this.handleApiRequest({ 
    method: 'Customer/CreateBranch',
    payload: Branch,
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save'),
  })
}

export async function customerListBranches(limit, offset, searchName, contractId) {
  const response = await this.handleApiRequest({ 
    method: 'Customer/ListBranchCompanies',
    payload: {
      limit,
      offset,
      searchName,
      ContractIDForDistance: contractId,
    },
    errorCallback
  })
  const branches = response.availableBranches.map((branch) => ({
    ...branch,
    url: `/branch/${branch.id}/details`,
  }));
  return { branches };
}

export async function customerBranchUpdate(branch) {
  await this.handleApiRequest({ 
    method: 'Customer/UpdateBranch',
    payload: {
      Id: parseInt(branch.id, 10),
      Name: branch.name,
      Email: branch.email,
      Phone: branch.phone,
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save'),
  })
}

export async function customerGetCompany(companyId) {
  return this.handleApiRequest({ 
    method: 'Customer/GetCompany',
    payload: {
      CompanyId: parseInt(companyId, 10)
    },
    errorCallback
  })
}

export async function branchUpdateAddress(address) {
  return this.handleApiRequest({ 
    method: 'Customer/UpdateBranch',
    payload: {
      Id: parseInt(address.branchId, 10),
      Address: {
        Line1: address.line1,
        Line2: address.line2,
        Line3: address.line3,
        Town: address.town,
        County: address.county,
        Code: address.code,
        Iso: address.iso,
        IsPrimary: address.isPrimary,
      },
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save'),
  })
}

export async function GetCustomerOnlyPrices(DatasetId, Limit, Offset) {
  return this.handleApiRequest({ 
    method: 'vendor/GetCustomerOnlyPrices',
    payload: {
      DatasetId, Limit, Offset 
    },
    errorCallback
  })
}

export async function GetCustomQuoteStatuses(CompanyId) {
  const { stages } = await this.handleApiRequest({ 
    method: 'Customer/GetCustomQuoteStages',
    payload: {
      CompanyId
    },
    errorCallback
  })
  return stages;
}

export async function UpdateCustomQuoteStage(stages) {
  return this.handleApiRequest({ 
    method: 'customer/UpdateCustomQuoteStage',
    payload: {
      Stages: stages,
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save'),
  })
}

export async function ListOrganisationContacts(role) {
  return this.handleApiRequest({ 
    method: 'customer/ListOrganisationContacts',
    payload: { role },
    errorCallback
  })
}

export async function GetExtraItemGroups() {
  return this.handleApiRequest({ 
    method: 'customer/GetExtraItemGroups',
    errorCallback
  })
}

export async function getAccountSettings() {
  return this.handleApiRequest({ 
    method: 'customer/GetAccountSettings',
    errorCallback
  })
}

export async function updateBrandedFabricator(CustomerId, Active, SupplyUntil, IndefiniteSupply) {
  return this.handleApiRequest({ 
    method: 'customer/updateBrandedFabricator',
    payload: {
      CustomerId, Active, SupplyUntil, IndefiniteSupply
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save'),
  })
}

export async function ListTradeAccountApplications(query) {
  return this.handleApiRequest({ 
    method: 'customer/ListTradeAccountApplications',
    payload: query,
    errorCallback
  })
}

export async function ProcessTradeAccountApplication(Id, Status) {
  return this.handleApiRequest({ 
    method: 'customer/ProcessTradeAccountApplication',
    payload: {
      Id, Status
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save'),
  })
}

export async function ResendVerificationEmail(ApplicationId) {
  return this.handleApiRequest({ 
    method: 'customer/ResendVerificationEmail',
    payload: { ApplicationId },
    errorCallback
  })
}

export async function UpdateCompanyIssuePresent({ ShowIssuePresentWarning, IssuePresentWarning }) {
  return this.handleApiRequest({ 
    method: 'customer/UpdateCompanyIssuePresent',
    payload: { ShowIssuePresentWarning, IssuePresentWarning },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save'),
  })
}

export async function GetCompanyIssuePresent() {
  return this.handleApiRequest({ 
    method: 'customer/GetCompanyIssuePresent',
    errorCallback
  })
}

export async function ThirdPartyGlassAvailable(datasetId) {
  return this.handleApiRequest({ 
    method: `customer/ThirdPartyGlassAvailable?datasetId=${datasetId}`,
    request: window.enum.request.GET,
    errorCallback
  })
}
