<template>
  <modal-window :modal_open="true" title="Edit Customer" @close="$emit('close')">
    <div class="text-green-600 pl-3 flex flex-col justify-around font-medium text-base">
      * Required Fields
    </div>
    <div class="flex flex-wrap">
      <div class="w-full mt-5 xs:w-1/2 xs:pr-5 xs:mt-8">
        <div class="font-medium">First Name <span class="text-green-600">*</span></div>
        <input v-model="firstName" type="text" class="form-control">
      </div>
      <div class="w-full mt-5 xs:w-1/2 xs:pl-5 xs:mt-8">
        <div class="font-medium">Last Name</div>
        <input v-model="lastName" type="text" class="form-control">
      </div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-full mt-5 xs:w-1/2 xs:pr-5 xs:mt-8">
        <div class="font-medium">Email <span class="text-green-600">*</span></div>
        <input v-model="email" type="text" class="form-control">
      </div>
      <div class="w-full mt-5 xs:w-1/2 xs:pl-5 xs:mt-8">
        <div class="font-medium">Tel</div>
        <input v-model="mobile" type="text" class="form-control">
      </div>
    </div>
    <consumer-address
      :consumer="contract.consumer"
      @address-updated="addressUpdated"
    />
    <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
      <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
        Cancel
      </button>
      <button class="btn-action btn-lg" @click="save()">Save</button>
    </div>
  </modal-window>
</template>

<script>

import { mapStores } from 'pinia'
import { useTouchStore } from '@/pinia/touch';
import validate from 'validate.js';
import ConsumerAddress from '@/components/shared/address/ConsumerAddress.vue';

export default {
  components: {
    'consumer-address': ConsumerAddress,
  },
  props: ['contract'],
  data() {
    if (this.contract.consumer) {
      const {
        addressCode,
        addressIso,
        addressLine1,
        lastName,
        firstName,
        addressLine2,
        addressLine3,
        addressTown,
        addressCounty,
        email,
        mobile,
        addressLatitude,
        addressLongitude,
      } = this.contract.consumer;

      return {
        firstName,
        lastName,
        addressLine1,
        addressLine2,
        addressLine3,
        addressTown,
        addressCode,
        addressCounty,
        addressIso,
        latitude: addressLatitude,
        longitude: addressLongitude,
        email,
        mobile,
        lookupBoxFocused: false,
        manualAddressEntry: true,
        address_picked: false,
      };
    }
    return {
      firstName: '',
      lastName: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      addressTown: '',
      addressCounty: '',
      addressCode: '',
      addressIso: 'GB',
      latitude: '',
      longitude: '',
      email: '',
      mobile: '',
      lookupBoxFocused: false,
      manualAddressEntry: false,
      address_picked: false,
    };
  },
  computed: {
    ...mapStores(useTouchStore),
    formatted_address() {
      return [
        this.addressLine1,
        this.addressLine2,
        this.addressTown,
        this.addressCounty,
        this.addressCode,
        this.addressIso,
      ]
        .filter((val) => val)
        .join(', ');
    },
  },
  methods: {
    addressUpdated(newAddress) {
      this.addressLine1 = newAddress.addressLine1;
      this.addressLine2 = newAddress.addressLine2;
      this.addressLine3 = newAddress.addressLine3;
      this.addressTown = newAddress.addressTown;
      this.addressCounty = newAddress.addressCounty;
      this.addressCode = newAddress.addressCode;
      this.addressIso = newAddress.addressIso;
      this.latitude = newAddress.latitude;
      this.longitude = newAddress.longitude;
    },
    save() {
      const validationErrors = validate(
        {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phone: this.mobile,
          addressLine1: this.addressLine1,
          addressLine2: this.addressLine2,
          addressLine3: this.addressLine3,
          addressTown: this.addressTown,
          postcode: this.addressCode,
          countryCode: this.addressIso,
        },
        {
          firstName: {
            presence: {
              allowEmpty: false,
            },
            length: {
              maximum: 32,
            },
          },
          lastName: {
            presence: {
              allowEmpty: true,
              message: 'and First name are required',
            },
            length: {
              maximum: 32,
            },
          },
          phone: {
            presence: { allowEmpty: true },
            length: {
              maximum: 64,
            },
          },
          email: {
            presence: { allowEmpty: true },
            length: {
              maximum: 255,
            },
            email: !!this.email,
          },
          postcode: {
            presence: { allowEmpty: true },
            length: {
              maximum: 16,
            },
          },
          countryCode: {
            length: {
              maximum: 3,
            },
            presence: { allowEmpty: false },
          },
        },
      );

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }

      this.saveConsumer();
    },
    saveConsumer() {
      this.$emit('saving');

      try {
        this.touchStore.updateConsumer({
          contractId: this.contract.id,
          customerId: this.$route.params.customerId,
          consumer: {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            mobile: this.mobile,
            addressLine1: this.addressLine1,
            addressLine2: this.addressLine2,
            addressLine3: this.addressLine3,
            addressTown: this.addressTown,
            addressCounty: this.addressCounty,
            addressCode: this.addressCode,
            addressIso: this.addressIso,
            addressLatitude: this.latitude || null,
            addressLongitude: this.longitude || null,
          },
        })
          .then(() => {
            this.$emit('saved');
          });
      } catch (error) {
        if (error.status === 400) {
          this.alertBox().fire(error.popUp);
        } else {
          throw error
        }
      }
    },
  },
};
</script>
