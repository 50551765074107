<template>
  <Teleport defer to="#portal_popup"> 
    <modal-window :modal_open="true" title="Add Holiday" @close="$emit('close')">
      <div class="flex flex-col container">
        <div class="flex flex-col flex-wrap">
          <div class="w-full xs:w-1/2 xs:pr-5 mt-4">
            <div class="font-medium">Title</div>
            <input v-model="newHolidayTitle" class="form-control">
          </div>
          <div class="w-full xs:w-1/2 xs:pr-5 mt-4">
            <div class="font-medium">Start Date</div>
            <VueDatePicker
              v-model="newHolidayStart"
              :ui="{ calendar: 'font-thin text-xs', menu: 'font-thin text-xs', calendarCell: 'h-6' }"
              format="dd-MM-yyyy"
              auto-apply
              :enable-time-picker="false"
              :hide-offset-dates="true"
              :year-range="[(new Date()).getFullYear(), (new Date()).getFullYear() + 5]"
            />
          </div>
          <div class="w-full xs:w-1/2 xs:pr-5 mt-4">
            <div class="font-medium">End Date</div>
            <VueDatePicker
              v-model="newHolidayEnd"
              :ui="{ calendar: 'font-thin text-xs', menu: 'font-thin text-xs', calendarCell: 'h-6' }"
              auto-apply
              format="dd-MM-yyyy"
              :enable-time-picker="false"
              :hide-offset-dates="true"
              :year-range="[(new Date()).getFullYear(), (new Date()).getFullYear() + 5]"
            />
          </div>
        </div>
        <div class="text-right mt-auto flex flex-col 2xs:block">
          <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
            Cancel
          </button>
          <button class="btn-action btn-lg" @click.prevent.stop="saveHoliday">Save Holiday</button>
        </div>
      </div>
    </modal-window>
  </Teleport>
</template>

<script>
import validate from 'validate.js';
import VueDatePicker from '@vuepic/vue-datepicker';
import { getDateFromJsDate } from '@/composables/date';

export default {
  components: {
    VueDatePicker
  },
  props: {
    holidayTitle: {
      type: String,
      default: '',
    },
    holidayStart: {
      type: String,
      default: undefined,
    },
    holidayEnd: {
      type: String,
      default: undefined,
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      newHolidayTitle: undefined,
      newHolidayStart: undefined,
      newHolidayEnd: undefined
    };
  },
  created() {
    if (this.holidayStart && this.holidayEnd) {
      this.newHolidayTitle = this.holidayTitle
      this.newHolidayStart = new Date(this.holidayStart)
      this.newHolidayEnd = new Date(this.holidayEnd)
    }
  },
  methods: {
    async saveHoliday() {
      const validationErrors = validate(
        {
          name: this.newHolidayTitle,
          holidayStart: this.newHolidayStart,
          holidayEnd: this.newHolidayEnd,
        },
        {
          name: {
            presence: { allowEmpty: false },
          },
          holidayStart: {
            presence: { allowEmpty: false },
          },
          holidayEnd: {
            presence: { allowEmpty: false },
          },
        },
      );

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }

      try {
        if (this.id) {
          await window.touch.updateCompanyHoliday(
            this.id,
            this.newHolidayTitle,
            getDateFromJsDate(new Date(this.newHolidayStart)),
            getDateFromJsDate(new Date(this.newHolidayEnd)),
          );
        } else {
          await window.touch.addCompanyHoliday(
            this.newHolidayTitle,
            getDateFromJsDate(new Date(this.newHolidayStart)),
            getDateFromJsDate(new Date(this.newHolidayEnd)),
          );
        }
      } catch (error) {
        if (error.status === 400) {
          this.alertBox().fire(error.popUp);
        } else {
          throw error
        }
      }

      this.$emit('created');
      this.$emit('close');
    },
  },
};
</script>
<style scoped>
.container {
  min-height: 650px;
}
</style>
