<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['My Account', '/manage-touch-account/company-details'],
          ['Quote Stages'],
        ]"
      />
    </template>
    <template #header_page_title> Quote Stages </template>
    <template #header_page_actions>
      <div class="px-3 md:px-10 flex items-center">
        <a class="btn-action" @click.stop.prevent="isUpdateQuoteStage = true"> Add Quote Stage </a>
      </div>
    </template>
    <Menu />
    <div class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch">
      <loading :loading="loading" class="min-h-full">
        <div class="flex flex-row flex-wrap bg-white border m-5 xs:m-10 mt-4 relative">
          <div v-if="stages.length" class="w-full flex flex-col gap-5 p-5">
            <div class="border-b w-full pb-5">
              Name
            </div>
            <div v-for="stage in stages" :key="stage.id" class="flex">
              <div>{{ stage.description }}</div>
              <div class="ml-auto flex items-center">
                <div
                  class="px-2 py-1 text-2xs rounded text-green-600 border border-green-600 cursor-pointer inline-block mr-2"
                  @click.stop.prevent="
                    (description = stage.description), (id = stage.id), (isUpdateQuoteStage = true)"
                >
                  <i class="cursor-pointer fa fa-pencil" />
                </div>
                <div
                  class="px-2 py-1 text-2xs rounded text-green-600 border border-green-600 cursor-pointer inline-block"
                  @click.stop.prevent="deleteQuoteStage(stage.id, stage.description)"
                >
                  <i class="cursor-pointer text-red-600 fa fa-trash-can" />
                </div>
              </div>
            </div>
          </div>
          <div v-else class="p-5">No Quote Stages</div>
        </div>
      </loading>
    </div>
    <modal-window
      v-if="isUpdateQuoteStage"
      ref="modal"
      :modal_open="true"
      title="Add Quote Stage"
      @close="isUpdateQuoteStage = false"
    >
      <div>
        <input v-model="description" type="text" class="form-control">
      </div>
      <div class="text-right mt-5">
        <button class="btn-action btn-lg rounded-sm" @click.prevent="updateQuoteStage(id)">
          Save
        </button>
      </div>
    </modal-window>
  </touch-layout>
</template>

<script>

import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';
import Menu from '@/components/business/manageTouchAccount/Menu.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    Breadcrumbs,
    Menu,
  },
  data() {
    return {
      loading: true,
      isUpdateQuoteStage: false,
      id: undefined,
      description: '',
      stages: [],
    };
  },
  computed: {
    ...mapStores(useCustomerStore),
  },
  async mounted() {
    await this.getCustomQuoteStatuses();
    this.loading = false;
  },
  methods: {
    async getCustomQuoteStatuses() {
      this.stages = await window.touch.GetCustomQuoteStatuses(
        this.customerStore.customer.companyId,
      );
    },
    async updateQuoteStage(id) {
      try {
        await window.touch.UpdateCustomQuoteStage([{ id, description: this.description }]);
      } catch (error) {
        if (error.status === 400) {
          this.alertBox().fire(error.popUp);
        } else {
          throw error
        }
      }
      this.isUpdateQuoteStage = false;
      this.description = '';
      this.id = undefined;
      await this.getCustomQuoteStatuses();
    },
    async deleteQuoteStage(id, name) {
      this.alertBox()
        .fire({
          title: `Are you sure you want to delete ${name} ?`,
          text: 'This action cannot be undone.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await window.touch.UpdateCustomQuoteStage([{ id, delete: true }]);
            } catch (error) {
              if (error.status === 400) {
                this.alertBox().fire(error.popUp);
              } else {
                throw error
              }
            }
            await this.getCustomQuoteStatuses();
          }
        });
    },
  },
};
</script>
