import { errorCallback } from '@/composables/validate';

export async function testListSandboxes() {
  return this.handleApiRequest({ 
    method: 'test/ListSandboxes',
    errorCallback
  })
}

export async function testCreateTestJob(customerId) {
  return this.handleApiRequest({ 
    method: 'test/CreateTestJob',
    payload: { customerId },
    errorCallback
  })
}

export async function testAcceptChanges(sandboxId) {
  return this.handleApiRequest({ 
    method: 'test/AcceptChanges',
    payload: { SandboxId: sandboxId },
    errorCallback
  })
}

export async function testAddNote(contractId, sandboxId, note) {
  return this.handleApiRequest({ 
    method: 'test/AddNote',
    payload: { 
      ContractId: contractId,
      SandboxId: sandboxId,
      Details: note,
     },
    errorCallback
  })
}
