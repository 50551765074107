<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Orders', '/orders'],
        ]"
      />
    </template>
    <template #header_page_title> Search Orders </template>
    <Teleport defer to="#portal_search">
      <SearchBox class="flex" placeholder="Search for reference..." />
    </Teleport>
    <template #header_page_actions>
      <div class="pr-3 md:px-10 flex items-center">
        <router-link to="/new-order" class="btn-action"> New Order </router-link>
        <info-popup
          :id="'quotes:new_quote'"
          class="_pop-out-left"
          :info_message="'Click here to create a new quote at any time.'"
          :next_id="'quotes:view_quote'"
        />
      </div>
    </template>
    <loading
      :loading="loading"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch p-3 md:p-10"
    >
      <div class="flex flex-wrap-reverse 2xs:flex-nowrap">
        <div
          v-for="tab in tabs"
          :key="tab.tabType"
          class="py-2 px-4 border border-b-0 cursor-pointer"
          :class="{
            'bg-white': selectedTab === tab.tabType,
            'border-gray-400 bg-gray-300 text-gray-600': selectedTab !== tab.tabType,
          }"
          @click="selectedTab = tab.tabType"
        >
          {{ tab.name }}
        </div>
      </div>
      <div
        v-if="orders.length > 0"
        key="orders_list"
        class="bg-white p-3 md:p-10 pb-6 border"
        data-index="1"
      >
        <table class="table-lined xs:table-unstacked _with-hover w-full">
          <thead>
            <tr>
              <th class="w-10">#</th>
              <th>Created</th>
              <th>Order Reference</th>
              <th>Net Cost</th>
              <th class="cursor-pointer relative">
                <span class="whitespace-nowrap">Status</span>
              </th>
              <th class="w-16">
                <button class="btn-action" @click.stop="loadFeed">Reload</button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="order in orders"
              :key="order.id"
              class="cursor-pointer pointer-events-none"
              :class="{
                'pointer-events-auto': [
                  enums.orderStatusSaveStates.NOT_APPLICABLE,
                  enums.orderStatusSaveStates.COMPLETED,
                ].includes(order.saveState),
                'fadeInAndOut': order.saveState === enums.orderStatusSaveStates.STARTED
              }"
              @click="$router.push(order.url)"
            >
              <td>
                <div class="td-title w-24">Number:</div>
                <div class="td-content">{{ order.customerCounter }}</div>
              </td>
              <td>
                <div class="td-title w-24">Created:</div>
                <div class="td-content">{{ formatDate(order.date) }}</div>
              </td>
              <td>
                <div class="td-title w-24">Reference:</div>
                <div class="td-content truncate w-full">{{ order.reference }}</div>
              </td>
              <td v-if="order.jobs.length > 0">
                <div class="td-title w-24">Net Cost:</div>
                <div
                  class="td-content"
                  v-html="formatCurrency(
                    order.customerCurrencyCode,
                    order.jobs[0].priceBeforeTax,
                  )"
                />
              </td>
              <td v-else><strong class="xs:hidden"> Net Cost: </strong> -</td>
              <td>
                <div class="td-title w-24">Status:</div>
                <span
                  :class="{
                    'font-bold text-red-500':
                      order.saveState === enums.orderStatusSaveStates.FAILED,
                  }"
                  v-text="statusText(order)"
                />
              </td>
              <td class="td-action-buttons">
                <div class="table-edit-btn">
                  <i class="fa fa-pencil" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else key="none-found" class="bg-white p-3 md:p-10 pb-6 border">
        <div v-if="$route.query.search" class="flex justify-between">
          <div>No Orders match "{{ $route.query.search }}"</div>
          <div>
            <a class="btn-action" @click.stop="$router.push({ query: undefined })">
              Clear Search
            </a>
          </div>
        </div>
        <div v-else>No Orders to show</div>
      </div>
      <pagination :total_items="total_items" :limit="limit" class="mt-3 pb-10" @load-feed="loadFeed" />
    </loading>
  </touch-layout>
</template>

<script>

import { mapStores } from 'pinia'
import { useTouchStore } from '@/pinia/touch';
import { formatCurrency } from '@/composables/currency';
import { formatDate } from '@/composables/date';
import { useContractPoller } from '@/composables/pollContractStates';
import Pagination from '@/components/shared/Pagination.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import SearchBox from '@/components/shared/SearchBox.vue';

const TabTypeEnum = {
  ALL_ORDERS: 1,
  LOCKED_ORDERS: 2,
};

class Tab {
  constructor(name, tabType) {
    this.name = name;
    this.tabType = tabType;
  }
}

export default {
  components: {
    Breadcrumbs,
    pagination: Pagination,
    SearchBox,
  },
  setup() {
    return { formatCurrency, formatDate }
  },
  data() {
    return {
      orders: [],
      loading: true,
      limit: 10,
      total_items: 0,
      selectedTab: 1,
      tabs: [
        new Tab('All Orders', TabTypeEnum.ALL_ORDERS),
        new Tab('Locked Orders', TabTypeEnum.LOCKED_ORDERS),
      ],
      contractsBeingProcessed: [],
      orderAutoUpdateTimeoutEnded: false,
    };
  },
  computed: {
    ...mapStores(useTouchStore),
  },
  watch: {
    selectedTab() {
      this.loadFeed();
    },
    '$route.query': {
      async handler() {
        await this.loadFeed();
        const { pollContractStates } = useContractPoller();
        pollContractStates(window.touch.CONTRACT_TYPE_ORDER, this.orders);
      },
      immediate: true,
    },
  },
  beforeUnmount() {
    this.orderAutoUpdateTimeoutEnded = true;
  },
  methods: {
    showReleaseToAdminButton(order) {
      return order.saveState !== this.enums.orderStatusSaveStates.STARTED;
    },
    statusText(order) {
      if (
        [this.enums.PaymentStatus.AWAITING, this.enums.PaymentStatus.FAILED].includes(
          order.orderPaymentStatus,
        ) &&
        order.finalised
      ) {
        return 'Placed (Pending Payment)';
      }
      if (order.saveState === this.enums.orderStatusSaveStates.COMPLETED) {
        switch (order.orderStatus) {
          case window.enum.orderStatus.NONE:
            return 'None';
          case window.enum.orderStatus.PENDING:
            return 'Pending';
          case window.enum.orderStatus.PLACED:
            return 'Placed';
          case window.enum.orderStatus.CANCELLED:
            return 'Cancelled';
          default:
            return 'None';
        }
      } else {
        switch (order.saveState) {
          case this.enums.orderStatusSaveStates.NOT_STARTED:
            return 'Not Started';
          case this.enums.orderStatusSaveStates.STARTED:
            return 'Started';
          case this.enums.orderStatusSaveStates.FAILED:
            return 'Sorry, problem saving this job. Please re-input';
          default:
            return 'N/A';
        }
      }
    },
    async loadFeed(offset = 0) {
      this.loading = true;
      const response = await this.touchStore.loadOrders({
        limit: this.limit,
        offset,
        onlyShowLocked: this.selectedTab === TabTypeEnum.LOCKED_ORDERS,
        search: this.$route.query.search,
      });
      this.orders = response.orders;
      this.total_items = response.total_items;
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.fadeInAndOut {
  opacity: 1;
  animation: fade 2s linear infinite;
}

@keyframes fade {

  0%,
  100% {
    opacity: 0.2
  }

  50% {
    opacity: 1
  }
}
</style>