import { errorCallback } from '@/composables/validate';

export async function visualisationListImages(contactId) {
  return this.handleApiRequest({ 
    method: 'visualisation/ListImages',
    payload: {
      OnlyForThisContactId: contactId
    },
    errorCallback
  })
}

export async function visualisationUpdateImage(id, reference, view) {
  return this.handleApiRequest({ 
    method: 'visualisation/UpdateImage',
    payload: {
      Id: id,
      Reference: reference,
      InternalView: view,
    },
    errorCallback
  })
}

export async function visualisationDeleteImage(id) {
  return this.handleApiRequest({ 
    method: 'visualisation/DeleteImage',
    payload: {
      Id: id,
    },
    errorCallback
  })
}


export async function UpdateImageForm(newDocument) {
  return this.handleApiRequest({ 
    method: 'visualisation/UpdateImageForm',
    payload: newDocument,
    errorCallback
  })
}
