<template>
  <aside
    ref="aside"
    tabindex="0"
    class="bg-white shadow-xl sm:w-1/3 overflow-y-auto scrolling-touch p-3 xs:p-10 h-full absolute left-0 right-0 bottom-0 sm:relative z-10 sm:z-auto"
  >
    <div class="m-auto">
      <div class="flex flex-col-reverse md:flex-row md:items-center mb-6">
        <h1 class="mt-3 md:mt-0">Job Extras</h1>
        <div class="flex flex-grow justify-end items-center">
          <div class="cursor-pointer hover:text-gray-800" @click="close('close-button-clicked')">
            <div class="whitespace-nowrap">
              <span class="pr-1">Close</span> <i class="fal fa-times" />
            </div>
          </div>
          <div>
            <button class="btn-action ml-2" @click.prevent="showAddItem = true">
              Choose Extra
            </button>
          </div>
        </div>
        <Teleport v-if="showAddItem" defer to="#portal_popup">
          <add-extra-item
            :supply-only="!basketStore.isFit"
            :for-job="true"
            title="Add Extra To Job"
            @close="showAddItem = false"
            @attach-item="attachItem($event)"
            @attach-item-with-option="attachItemWithOption($event)"
          />
        </Teleport>
      </div>

      <div v-if="extrasForThisItem.length > 0" class="mb-6">
        <base-tile
          v-for="extra in extrasForThisItem"
          :key="extra.key"
          :extra="extra"
          class="p-2 border rounded-lg mb-4"
          @delete="$emit('delete-line-item', extra.key)"
        />
      </div>
      <div v-else class="">No extras selected</div>
    </div>
  </aside>
</template>

<script>

import { mapStores } from 'pinia'
import { useBasketStore } from '@/pinia/basket';
import AddExtraItem from '@/components/shared/basket/AddExtraItem.vue';
import BaseTile from '@/components/shared/basket/BaseTile.vue';

export default {
  components: {
    'add-extra-item': AddExtraItem,
    'base-tile': BaseTile,
  },
  props: ['itemId', 'extras'],
  data() {
    return {
      showAddItem: false,
    };
  },
  computed: {
    ...mapStores(useBasketStore),
    extrasForThisItem() {
      return this.extras.filter((extra) => extra.parentItemKey === this.itemId);
    },
  },
  watch: {
    showAddItem(show) {
      if (!show) {
        this.$refs.aside.focus();
      }
    },
  },
  methods: {
    focusOut(event) {
      if (window.$(this.$refs.aside).has(event.relatedTarget).length > 0) {
        return; // don't close if focus passed to a child element
      }

      if (event.relatedTarget === this.$refs.aside) {
        return; // don't close if we're the element gaining focus
      }

      if (this.showAddItem) {
        return; // don't close if a popup is open
      }

      if (window.$(event.relatedTarget).hasClass('swal2-confirm')) {
        return; // don't close on swal popup
      }

      this.close();
    },
    defaultParams(type, withSizes = true) {
      const common = {
        Quantity: 1,
      };

      if (!withSizes) {
        return common;
      }

      switch (type) {
        case window.enum.unitOfMeasure.NONE:
          return {
            ...common,
          };
        case window.enum.unitOfMeasure.UNIT:
          return {
            ...common,
          };
        case window.enum.unitOfMeasure.WIDTH:
          return {
            ...common,
            Width: 0,
          };
        case window.enum.unitOfMeasure.LENGTH:
          return {
            ...common,
            Length: 0,
          };
        case window.enum.unitOfMeasure.AREA:
          return {
            ...common,
            Width: 0,
            Height: 0,
          };
        case window.enum.unitOfMeasure.FITTING_DAYS:
          return {
            ...common,
          };
        case window.enum.unitOfMeasure.HEIGHT:
          return {
            ...common,
            Height: 0,
          };
        case window.enum.unitOfMeasure.PERIMETER:
          return {
            ...common,
            Width: 0,
            Height: 0,
          };
        case window.enum.unitOfMeasure.VOLUME:
          return {
            ...common,
            Length: 0,
            Width: 0,
            Height: 0,
          };
        default:
          throw Error(`Unknown Unit Of Measure ${type}`);
      }
    },
    async attachItemWithOption({ item, option }) {
      try {
        await this.basketStore.addExistingExtraItem({
          itemId: this.itemId,
          extraItemId: item.id,
          params: {
            ...this.defaultParams(item.unitOfMeasure, !item.useSizesFromProduct),
            OptionId: option.id,
          },
        });
      } catch (error) {
        if (error.status === 400) {
          this.alertBox().fire(error.popUp);
        } else {
          throw error
        }
      }
      await this.basketStore.refresh(true);
    },
    async attachItem(extraItem) {
      let extra;
      try {
        if (this.extras.some((x) => x.extraItemId === extraItem.id && x.parentItemKey === this.itemId)) {
          const existingExtra = this.extras.find(
            (x) => x.extraItemId === extraItem.id && x.parentItemKey === this.itemId,
          );
          extra = await this.basketStore.updateExistingExtraItem({
            params: {
              Quantity: existingExtra.quantity + 1,
              Length:
                !existingExtra.useSizesFromProduct &&
                  [window.enum.unitOfMeasure.LENGTH, window.enum.unitOfMeasure.VOLUME].includes(
                    existingExtra.unitOfMeasure,
                  )
                  ? existingExtra.length
                  : undefined,
              Width:
                !existingExtra.useSizesFromProduct &&
                  [
                    window.enum.unitOfMeasure.WIDTH,
                    window.enum.unitOfMeasure.AREA,
                    window.enum.unitOfMeasure.PERIMETER,
                    window.enum.unitOfMeasure.VOLUME,
                  ].includes(existingExtra.unitOfMeasure)
                  ? existingExtra.width
                  : undefined,
              Time: [window.enum.unitOfMeasure.FITTING_DAYS].includes(existingExtra.unitOfMeasure)
                ? existingExtra.time
                : undefined,
              Height:
                !existingExtra.useSizesFromProduct &&
                  [
                    window.enum.unitOfMeasure.HEIGHT,
                    window.enum.unitOfMeasure.AREA,
                    window.enum.unitOfMeasure.PERIMETER,
                    window.enum.unitOfMeasure.VOLUME,
                  ].includes(existingExtra.unitOfMeasure)
                  ? existingExtra.height
                  : undefined,
            },
            itemId: existingExtra.key,
          });
        } else {
          try {
            extra = await this.basketStore.addExistingExtraItem({
              itemId: this.itemId,
              extraItemId: extraItem.id,
              params: this.defaultParams(extraItem.unitOfMeasure, !extraItem.useSizesFromProduct),
            });
          } catch (error) {
            if (error.status === 400) {
              this.alertBox().fire(error.popUp);
            } else {
              throw error
            }
          }
        }
      } catch (error) {
        if (error.status === 400) {
          this.alertBox().fire(error.popUp);
        } else {
          throw error
        }
      }

      await this.basketStore.refresh(true);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
