<template>
  <div class="w-full h-full overflow-y-scroll">
    <Teleport defer to="#portal_search">
      <SearchBox class="flex" placeholder="Search marketing materials..." />
    </Teleport>
    <loading
      :loading="loading"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch p-5 md:p-10"
    >
      <div class="flex flex-col-reverse flex-col 2xs:flex-row items-center gap-5 mb-5">
        <FilterDropdown
          v-if="categories.length"
          label="Category"
          query-label="categoryFilter"
          :filters="categories.filter((x) => !x.isEmpty)"
        />
        <slot name="manage_marketing" />
      </div>
      <div v-if="filteredMarketingItems.length > 0">
        <animation-staggered-fade appear class="grid 2xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
          <div
            v-for="(marketing_item, index) in filteredMarketingItems"
            :key="marketing_item.id"
            :data-index="index"
            class="w-full flex align-stretch"
          >
            <marketing-item
              v-if="marketing_item.type === enums.documentType.RESOURCE_FILE"
              :categories="categories"
              :marketing-item="marketing_item"
              @deleted="loadFeed"
              @updated="loadFeed"
              @download="downloadDocument(marketing_item.id)"
            />
            <video-item
              v-if="marketing_item.type === enums.documentType.RESOURCE_VIDEO"
              :categories="categories"
              :video="marketing_item"
              @deleted="loadFeed"
              @updated="loadFeed"
            />
          </div>
        </animation-staggered-fade>
        <transition appear name="fade-in">
          <div v-if="show_load_more" class="text-center">
            <a class="btn bg-white" @click="current_page += 1"> Load More </a>
          </div>
        </transition>
      </div>
      <div v-else key="none-found" class="bg-white p-10 border">
        <div v-if="$route.query.search" class="flex justify-between items-center">
          <div>No Documents match "{{ $route.query.search }}"</div>
          <div>
            <a class="btn-action" @click.stop="$router.push({ query: undefined })">
              Clear Search
            </a>
          </div>
        </div>
        <div v-else>This category is empty</div>
      </div>
    </loading>
  </div>
</template>

<script>

import { getYoutubeThumbnail, getVimeoThumbnail, getVideoType } from '@/composables/video';
import MarketingItem from '@/components/shared/marketing/MarketingItem.vue';
import VideoItem from '@/components/shared/marketing/VideoItem.vue';
import SearchBox from '@/components/shared/SearchBox.vue';
import FilterDropdown from '@/components/shared/FilterDropdown.vue';

export default {
  components: {
    VideoItem,
    'marketing-item': MarketingItem,
    SearchBox,
    FilterDropdown
  },
  data() {
    return {
      loading: true,
      categories: [],
      allItems: [],
      limit: 24,
      current_page: 1,
      show_load_more: true,
      image_error: false,
    };
  },
  computed: {
    filteredMarketingItems() {
      return this.allItems
        .filter((marketingItem) =>
          `${marketingItem.description} ${marketingItem.name}`.toLowerCase().includes(this.search),
        )
        .slice(0, this.limit * this.current_page);
    },
    selectedCategory() {
      return this.categories.filter((category) => category.id === this.$route.query.categoryFilter)[0];
    },
    search() {
      return this.$route.query.search || '';
    },
  },
  watch: {
    current_page() {
      this.show_load_more = this.showLoadMore();
    },
    '$route.query': {
      handler() {
        this.show_load_more = this.showLoadMore;
        this.current_page = 1;
        this.loadFeed();
      },
    },
  },
  async mounted() {
    await this.loadMarketingItemsByCategory()
  },
  methods: {
    async downloadDocument(id) {
      await window.touch.download(`document/Get/${id}`)
    },
    async getThumbnailForItem(item) {
      if (item.type === this.enums.documentType.RESOURCE_FILE) {
        return window.touch.displayStream(`document/GetThumbnail/${item.id}`);
      }

      if (item.type !== this.enums.documentType.RESOURCE_VIDEO) {
        throw new Error('Unknown Document Type');
      }

      switch (getVideoType(item.url)) {
        case this.enums.documentVideoType.YOUTUBE:
          return getYoutubeThumbnail(item.url);
        case this.enums.documentVideoType.VIMEO:
          return getVimeoThumbnail(item.url);
        default:
          throw new Error('Unknown Video Type');
      }
    },
    async loadFeed() {
      const allItems = await window.touch.marketingItems({ categoryId: this.$route.query.categoryFilter });
      const promiseItems = allItems.map(async (marketingItem) => ({
        ...marketingItem,
        thumbnail: await this.getThumbnailForItem(marketingItem),
      }));
      await Promise.all(promiseItems).then((marketingItem) => {
        this.allItems = marketingItem;
        this.allItems.sort((a, b) =>
          a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: 'base',
          }),
        )
        this.show_load_more = this.allItems.length > this.allItems.slice(0, this.limit).length;
      });
    },
    async deleteMarketingDocument() {
      await window.touch.deleteMarketingDocuments([this.marketing_document_id_to_delete]).then(() => {
        this.delete_document_modal_open = false;
        this.marketing_document_id_to_delete = null;
        this.loadFeed();
      });
    },
    showLoadMore() {
      const filteredItems = this.allItems.filter((marketingItem) =>
        `${marketingItem.description} ${marketingItem.name}`.toLowerCase().includes(this.search),
      );
      return filteredItems.length !== this.filteredMarketingItems.length;
    },
    async loadMarketingItemsByCategory() {
      this.loading = true;
      this.categories = await window.touch.marketingCategories();
      if (this.categories.length === 0) {
        this.$emit('redirectToURL');
      } else {
        this.$router.push({ query: { categoryFilter: this.categories[0].id } })
      }
      await this.loadFeed()
      this.loading = false;
    }
  },
};
</script>
