import { createRouter as _createRouter, createWebHistory } from 'vue-router'
import ErrorPage from '@/views/Public/ErrorPage.vue';

import * as Sentry from '@sentry/browser';

import publicRoutes from './routes/public';
import brandedRoutes from './routes/branded';
import portalRoutes from './routes/portal';
import businessRoutes from './routes/business';

import portalMode from './middleware/portal';
import brandedMode from './middleware/branded';
import businessMode from './middleware/business';
import publicMode from './middleware/public';

import { useStyleStore } from '@/pinia/style';

const routes = [
  ...filter_routes([...publicRoutes, ...brandedRoutes, ...portalRoutes, ...businessRoutes]),
  {
    path: '/:pathMatch(.*)*',
    component: ErrorPage,
    beforeEnter: (to, from, next) => {
      const eventId = Sentry.captureEvent({
        message: `Status 404 - ${to?.fullPath}`,
        extra: {
          status: 404,
        },
      })
      to.query.eventId = eventId;
      to.query.title = `Page ${to?.fullPath} not found.`;
      to.query.status = 404;
      next();
    },
  },
];

function filter_routes (routes) {
  const installation = import.meta.env.VITE_APP_INSTALLATION_TYPE;
  if (installation === 'business') {
    return routes.filter(x => (!x.meta.portalRoute && !x.meta.brandedModeRoute) || x.meta.shared)
  } else if (installation === 'portal') {
    return routes.filter(x => !x.meta.businessRoute || x.meta.shared)
  } else {
    return routes
  }
}

export function createRouter () {
  const router = _createRouter({
    history: createWebHistory(),
    base: import.meta.env.BASE_URL,
    routes,
  })

  router.beforeEach(async (to, from, next) => {
    const installation = window.VITE_APP_INSTALLATION_TYPE;
    if (to.path === '/login' || to.path === '/login-admin') {
      next();
    } else if (to.matched.some((record) => record.meta.brandedModeRoute === true)) {
      brandedMode(to, from, next);
    } else if (to.matched.some((record) => record.meta.publicRoute === true)) {
      publicMode(to, from, next);
    } else if (installation === 'portal') {
      portalMode(to, from, next);
    } else if (installation === 'business') {
      businessMode(to, from, next);
    } else {
      // Shoud never be hit?
      next();
    }
  })
  
  router.afterEach((to) => {
    const styleStore = useStyleStore()
    styleStore.setPageName(to.name)
  });

  return router
}