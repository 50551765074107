import { errorCallback, successCallback } from '@/composables/validate';

export async function stockListStockItems({ subCategoryId, searchText, ListOnlyStockExtras = false }) {
  return this.handleApiRequest({ 
    method: 'stock/ListStockItems',
    payload: { 
      Limit: 99999,
      Offset: 0,
      subCategoryId,
      searchText: searchText ?? '',
      ListOnlyStockExtras
     },
    errorCallback
  })
}

export async function stockUpdateStockItems(stockItems) {
  return this.handleApiRequest({ 
    method: 'stock/UpdateStockItems',
    payload: { stockItems },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save'),
  })
}

export async function stockUpdateStockCustomerPrice(stockCustomerPriceDetails) {
  return this.handleApiRequest({ 
    method: 'stock/UpdateStockCustomerPrice',
    payload: { stockCustomerPriceDetails },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save'),
  })
}

export async function stockListStockCustomerPrice(customerId, itemId) {
  return this.handleApiRequest({ 
    method: 'stock/ListStockCustomerPrice',
    payload: { 
      CustomerId: customerId,
      stockId: itemId
    },
    errorCallback
  })
}

export async function stockListStockParts(query) {
  return this.handleApiRequest({ 
    method: 'stock/ListStockParts',
    payload: { 
      Limit: 99999,
      Offset: 0,
      SearchText: query,
    },
    errorCallback
  })
}

export async function stockListStockPartSubCategories() {
  return this.handleApiRequest({ 
    method: 'stock/ListStockPartSubCategories',
    payload: { 
      Limit: 99999,
      Offset: 0,
    },
    errorCallback
  })
}

export async function stockListStockPartCategories() {
  return this.handleApiRequest({ 
    method: 'stock/ListStockPartCategories',
    payload: { 
      Limit: 99999,
      Offset: 0,
    },
    errorCallback
  })
}
