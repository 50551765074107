<template>
  <Teleport defer to="#portal_popup">
    <modal-window
      v-if="modal_open"
      :modal_open="true"
      title="Edit Staff Member"
      @close="$emit('close')"
    >
      <div class="text-green-600 pl-3 flex flex-col justify-around font-medium text-base">
        * Required Fields
      </div>
      <div class="flex flex-wrap mt-5">
        <div class="w-full xs:w-1/2 xs:pr-5">
          <div class="font-medium">First Name <span class="text-green-600">*</span></div>
          <input v-model="user.firstName" type="text" class="form-control">
        </div>
        <div class="w-full mt-4 xs:w-1/2 xs:pl-5 xs:mt-0">
          <div class="font-medium">Last Name <span class="text-green-600">*</span></div>
          <input v-model="user.lastName" type="text" class="form-control">
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full mt-5 xs:w-1/2 xs:pr-5 xs:mt-8">
          <div class="font-medium">Email <span class="text-green-600">*</span></div>
          <input v-model="user.email" type="text" disabled class="form-control text-gray-400">
          <a
            v-if="!user.hasBeenActivated && user.email === userEmail"
            :class="activationEmailResent
              ? 'text-gray-600 cursor-not-allowed'
              : 'text-green-600 cursor-pointer'"
            @click.prevent.stop="resendActivationEmail"
          >
            {{ activationEmailResent ? "Activation Email Resent" : "Resend Activation Email" }}
          </a>
          <span
            v-if="!user.hasBeenActivated && user.email !== userEmail"
            class="text-gray-600 cursor-not-allowed"
          >
            Must save user before resending activation
          </span>
        </div>
        <div class="w-full mt-5 xs:w-1/2 xs:pl-5 xs:mt-8">
          <div class="font-medium">Mobile</div>
          <input v-model="user.mobile" type="text" class="form-control">
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full mt-5 xs:w-1/2 xs:pr-5 xs:mt-8">
          <div class="font-medium">Departments <span class="text-green-600">*</span></div>
          <select v-model="user.departments" multiple class="form-control multiple">
            <option
              v-for="department in touchStore.contactDepartments"
              :key="department.id"
              :value="department.id"
            >
              {{ department.name }}
            </option>
          </select>
        </div>
        <div class="w-full mt-5 xs:w-1/2 xs:pl-5 xs:mt-8">
          <ContactRoles
            :roles="user.roles"
            @set-contact-roles="$event === 0 ? (user.roles = $event) : (user.roles += $event)"
          />
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full mt-5 xs:w-1/2 xs:pr-5 xs:mt-8">
          <div class="font-medium">Processing Level <span class="text-green-600">*</span></div>
          <select v-model="user.processingLevelId" class="form-control">
            <option
              v-for="(processing_level, index) in processing_levels"
              :key="index"
              :value="processing_level.id"
            >
              {{ processing_level.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="w-full mt-4 xs:w-1/2 xs:pr-5 xs:mt-8">
        <div class="w-full">
          <Dropzone
            :removable="false"
            :current_file="staffImageUrl(user.image)"
            @add-file="new_user_image = $event"
            @remove-file="new_user_image = null"
          />
        </div>
        <div class="mt-1">Photos help your teammates recognise you in TOUCH.</div>
      </div>
      <div class="xs:flex gap-5">
        <div v-show="notEditingSelf" class="flex flex-col justify-around h-10 md:h-20 lg:h-10 mt-3">
          <div
            class="flex items-center cursor-pointer"
            @click.prevent="user.isAdmin = !user.isAdmin"
          >
            <div class="relative">
              <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner" />
              <div class="switch-toggle" :class="{ _active: user.isAdmin }" />
            </div>
            <div class="ml-2 text-gray-700 font-medium whitespace-nowrap">
              <span v-text="user.isAdmin ? 'Admin User' : 'Non Admin User'" />
            </div>
          </div>
        </div>
        <div v-show="notEditingSelf" class="flex flex-col justify-around h-10 md:h-20 lg:h-10 mt-3">
          <div
            class="flex items-center cursor-pointer"
            @click.prevent="toggleUserLicenseStatusType()"
          >
            <div class="relative">
              <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner" />
              <div
                class="switch-toggle"
                :class="{ _active: user.licenseStatusType === enums.licenseStatusType.ENABLED }"
              />
            </div>
            <div class="ml-2 text-gray-700 font-medium whitespace-nowrap">
              Account
              <span
                v-text="user.licenseStatusType === enums.licenseStatusType.ENABLED
                  ? 'Enabled'
                  : 'Disabled'"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-col justify-around h-10 md:h-20 lg:h-10 mt-3">
          <div class="flex items-center cursor-pointer" @click.prevent="user.archived = !user.archived;">
            <div class="relative">
              <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner" />
              <div class="switch-toggle" :class="{ '_active': user.archived }" />
            </div>
            <div class="ml-2 text-gray-700 font-medium whitespace-nowrap">
              Archived
            </div>
          </div>
        </div>
      </div>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button class="btn-action btn-lg" @click.prevent.stop="saveUser()">Save Changes</button>
      </div>
    </modal-window>
  </Teleport>
</template>

<script>
import validate from 'validate.js';
import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { useCustomerStore } from '@/pinia/customer';
import { useTouchStore } from '@/pinia/touch';
import Dropzone from '@/components/shared/Dropzone.vue';
import ContactRoles from '@/components/shared/ContactRoles.vue';

export default {
  components: {
    Dropzone,
    ContactRoles,
  },
  props: ['userId', 'customerId'],
  data() {
    return {
      user: {
        processingLevelId: null,
        departments: [],
      },
      userEmail: '',
      customer: {},
      processing_levels: [],
      user_image: null,
      new_user_image: null,
      activationEmailResent: false,
      modal_open: false,
    };
  },
  computed: {
    ...mapStores(useAuthStore, useCustomerStore, useTouchStore),
    editingSelf() {
      return this.user.id === this.authStore.id;
    },
    notEditingSelf() {
      return !this.editingSelf;
    },
  },
  async mounted() {
    await this.loadUser();
    this.processing_levels = await this.customerStore.customerGetProcessingLevels();
    this.modal_open = true;
  },
  methods: {
    resendActivationEmail() {
      if (this.activationEmailResent) {
        return;
      }
      this.activationEmailResent = true;
      window.touch.resendActivationEmail(this.user.id);
    },
    async loadCustomer() {
      this.customer = await this.customerStore.customerGet(this.customerId);
    },
    async loadUser() {
      await this.loadCustomer();
      const user = this.customer.users.filter((usr) => usr.id == this.userId)[0];
      this.user = user;
      this.user.noLogic = undefined
      this.userEmail = this.user.email;
      this.user_image = user.image;
    },
    async saveUser() {
      this.loading = true;
      const validationAttributes = {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        email: this.user.email,
        mobile: this.user.mobile,
      };

      validationAttributes.processingLevel = this.user.processingLevelId;

      const validationConstraints = {
        firstName: {
          presence: { allowEmpty: false },
          length: {
            maximum: 32,
          },
        },
        lastName: {
          presence: { allowEmpty: false },
          length: {
            maximum: 32,
          },
        },
        email: {
          presence: { allowEmpty: false },
          email: true,
          length: {
            maximum: 255,
          },
        },
      };

      validationConstraints.processingLevel = {
        presence: { allowEmpty: false },
      };

      const validationErrors = validate(validationAttributes, validationConstraints);

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        this.loading = false;
        return;
      }

      const index = this.customer.users.findIndex(x => x.id === this.user.id);
      this.customer.users[index] = this.user;
      if (!this.customer.users.some(x => !x.archived && x.licenseStatusType === this.enums.licenseStatusType.ENABLED)) {
        this.alertBox().fire({
          title: 'At least one staff member must not be archived.',
        });
        this.loading = false;
        return;
      }

      if (this.new_user_image !== null) {
        this.user.image = this.new_user_image;
      }

      let { users } = this.customer;
      const foundIndex = users.findIndex((user) => user.id == this.user.id);
      users[foundIndex] = this.user;
      users = users.filter(
        (user, index, self) => index === self.findIndex((t) => t.id === user.id),
      );
      this.customer.users = users;
      try {
        await this.customerStore.customerUpdateUsers({ customer: this.customer, usersIds: [this.user.id] }).then(
          () => {
            this.$emit('user-saved');
            this.$emit('close');
          },
        );
      } catch (error) {
        if (error.status === 400) {
          this.alertBox().fire(error.popUp);
        } else {
          throw error
        }
      }
    },
    staffImageUrl(image) {
      if (image !== undefined) {
        return window.touch.imageBaseURL + image;
      }
      return '/images/blank-user.png';
    },
    toggleUserLicenseStatusType() {
      if (this.user.licenseStatusType === window.enum.licenseStatusType.ENABLED) {
        this.user.licenseStatusType = window.enum.licenseStatusType.DISABLED;
      } else {
        this.user.licenseStatusType = window.enum.licenseStatusType.ENABLED;
      }
    },
  },
};
</script>
