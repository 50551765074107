<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Customer Management', '/customer'],
          [`Customer (${customer.company.name})`, `/customer/${customer.customerId}`],
          ['Processing Options'],
        ]"
      />
    </template>
    <template #header_page_title> Processing Options </template>
    <Menu />
    <div class="flex flex-col flex-grow  overflow-y-auto ">
      <div class="flex flex-col md:flex-row gap-5 p-5 flex-grow bg-gray-200 w-full" style="flex: 1 1 0">
        <div class="flex flex-col gap-2" style="flex: 1 1 0">
          <div class="bg-white px-5 py-2 border border-gray">
            <div class="flex items-center gap-5">
              <span class="text-lg">Option Groups</span>
              <div
                class="ml-auto bg-gray-200 text-center rounded-lg cursor-pointer"
                :class="{ 'pointer-events-none opacity-50': groups.length === 0 }"
                :style="{ height: '30px', width: '30px' }"
                @click="
                  (isSearchGroups = !isSearchGroups),
                  (searchGroups = ''),
                  $nextTick(() => $refs.searchGroups.focus())"
              >
                <i v-if="isSearchGroups" class="fa fa-times" :style="{ 'line-height': '30px' }" />
                <i v-else class="fa fa-search" :style="{ 'line-height': '30px' }" />
              </div>
            </div>
            <div v-show="isSearchGroups" class="mt-5">
              <div class="flex border">
                <input
                  ref="searchGroups"
                  v-model="searchGroups"
                  class="p-2 h-10 w-full w-full focus:outline-none border-0"
                  type="text"
                  placeholder="Search Groups..."
                  @input="
                    (isSearchHeadings = false),
                    (searchHeadings = ''),
                    (headings = []),
                    (isSearchItems = false),
                    (searchItems = ''),
                    (groupId = undefined),
                    (headingId = undefined),
                    (items = []),
                    (originalItems = [])"
                >
                <div
                  v-if="searchGroups.length > 0"
                  class="w-10 h-10 border-l-0 text-center flex flex-col justify-around border-0 border-l"
                  @click="searchGroups = ''"
                >
                  <i class="fal fa-times cursor-pointer" />
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col flex-grow bg-white p-5 border border-gray">
            <loading v-if="isLoadingOptionGroups" :loading="true" class="h-full" style="flex: 1 1 0" />
            <div
              v-else
              style="flex: 1 1 0; min-height: 200px;"
              class="overflow-y-auto"
            >
              <div class="flex flex-col gap-2">
                <div
                  v-for="group in filteredGroups"
                  :key="group.id"
                  class="bg-gray-200 rounded lg p-2 cursor-pointer hover:bg-green-300 hover:text-white"
                  :class="{ 'bg-green-600 text-white': group.id === groupId }"
                  @click="validateOptionItems('groupId', group.id)"
                >
                  <span>{{ group.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-2" style="flex: 1 1 0">
          <div class="bg-white px-5 py-2  border border-gray">
            <div class="flex items-center gap-5">
              <span class="text-lg">Option Headings</span>
              <div
                class="ml-auto bg-gray-200 text-center rounded-lg cursor-pointer"
                :class="{ 'pointer-events-none opacity-50': headings.length === 0 }"
                :style="{ height: '30px', width: '30px' }"
                @click="
                  (isSearchHeadings = !isSearchHeadings),
                  (searchHeadings = ''),
                  $nextTick(() => $refs.searchHeadings.focus())"
              >
                <i
                  v-if="isSearchHeadings"
                  class="fa fa-times"
                  :style="{ 'line-height': '30px' }"
                />
                <i v-else class="fa fa-search" :style="{ 'line-height': '30px' }" />
              </div>
            </div>
            <div v-show="isSearchHeadings" class="mt-5">
              <div class="flex border">
                <input
                  ref="searchHeadings"
                  v-model="searchHeadings"
                  class="p-2 h-10 w-full w-full focus:outline-none border-0"
                  type="text"
                  placeholder="Search Headings..."
                  @input="
                    (isSearchItems = false),
                    (searchItems = ''),
                    (headingId = undefined),
                    (items = []),
                    (originalItems = [])"
                >
                <div
                  v-if="searchHeadings.length > 0"
                  class="w-10 h-10 border-l-0 text-center flex flex-col justify-around border-0 border-l"
                  @click="searchHeadings = ''"
                >
                  <i class="fal fa-times cursor-pointer" />
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col flex-grow bg-white p-5 border border-gray">
            <loading v-if="isLoadingOptionGroups || isLoadingOptionHeadings" :loading="true" class="h-full" style="flex: 1 1 0" />
            <div
              v-else
              style="flex: 1 1 0; min-height: 200px;"
              class="overflow-y-auto"
            >
              <div class="flex flex-col gap-2">
                <div
                  v-for="heading in filteredHeadings"
                  :key="heading.uniqueValue"
                  class="bg-gray-200 rounded lg p-2 cursor-pointer hover:bg-green-300 hover:text-white"
                  :class="{ 'bg-green-600 text-white': heading.uniqueValue === headingId }"
                  @click="validateOptionItems('headingId', heading.uniqueValue)"
                >
                  <span>{{ heading.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-2" style="flex: 1 1 0">
          <div class="bg-white px-5 py-2  border border-gray">
            <div class="flex items-center gap-5">
              <span class="text-lg">Option Items</span>
              <div
                class="ml-auto bg-gray-200 text-center rounded-lg cursor-pointer"
                :class="{ 'pointer-events-none opacity-50': items.length === 0 }"
                :style="{ height: '30px', width: '30px' }"
                @click="
                  (isSearchItems = !isSearchItems),
                  (searchItems = ''),
                  $nextTick(() => $refs.searchItems.focus())"
              >
                <i v-if="isSearchItems" class="fa fa-times" :style="{ 'line-height': '30px' }" />
                <i v-else class="fa fa-search" :style="{ 'line-height': '30px' }" />
              </div>
            </div>
            <div v-show="isSearchItems" class="mt-5">
              <div class="flex border">
                <input
                  ref="searchItems"
                  v-model="searchItems"
                  class="p-2 h-10 w-full w-full focus:outline-none border-0"
                  type="text"
                  placeholder="Search Items..."
                >
                <div
                  v-if="searchItems.length > 0"
                  class="w-10 h-10 border-l-0 text-center flex flex-col justify-around border-0 border-l"
                  @click="searchItems = ''"
                >
                  <i class="fal fa-times cursor-pointer" />
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col flex-grow bg-white p-5 border border-gray">
            <loading v-if="isLoadingOptionGroups || isLoadingOptionHeadings || isLoadingOptionItems" :loading="true" class="h-full" style="flex: 1 1 0" />
            <div v-else class="overflow-y-auto" style="flex: 1 1 0; min-height: 200px;">
              <div class="flex flex-col gap-2">
                <div
                  v-for="item in filteredItems"
                  :key="item.id"
                  class="flex items-center bg-gray-200 rounded lg p-2"
                >
                  <span>{{ item.name }}</span>
                  <div
                    class="ml-auto flex items-center gap-5 cursor-pointer"
                    @click.prevent="item.disabled = !item.disabled"
                  >
                    <span class="text-xs opacity-50">{{
                      item.invertCustomerAccess
                        ? item.disabled ? 'Enabled' : 'Disabled'
                        : item.disabled ? 'Disabled' : 'Enabled'
                    }}</span>
                    <div class="relative">
                      <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner" />
                      <div class="switch-toggle" :class="{ _active: !item.disabled }" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white text-right p-3 border-t bg-opacity-75">
        <button
          class="btn-action btn-lg"
          :class="{
            'opacity-50 pointer-events-none bg-gray-500 border-gray-500':
              JSON.stringify(items) === JSON.stringify(originalItems),
          }"
          @click="save()"
        >
          Save Changes
        </button>
      </div>
    </div>
  </touch-layout>
</template>

<script>

import Menu from '@/components/business/customer/Menu.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';

export default {
  components: {
    Breadcrumbs,
    Menu,
  },
  props: {
    customer: Object,
  },
  data() {
    return {
      loading: true,
      groups: [],
      groupId: undefined,
      headings: [],
      headingId: undefined,
      optionHeadingTypeId: undefined,
      items: [],
      originalItems: [],
      searchGroups: '',
      isSearchGroups: false,
      searchHeadings: '',
      isSearchHeadings: false,
      searchItems: '',
      isSearchItems: false,
      isLoadingOptionGroups: false,
      isLoadingOptionHeadings: false,
      isLoadingOptionItems: false
    };
  },
  computed: {
    filteredGroups() {
      return this.searchGroups.length > 0
        ? this.groups.filter((x) =>
          x.name.toLowerCase().startsWith(this.searchGroups.toLowerCase()),
        )
        : this.groups;
    },
    filteredHeadings() {
      return this.searchHeadings.length > 0
        ? this.headings.filter((x) =>
          x.name.toLowerCase().startsWith(this.searchHeadings.toLowerCase()),
        )
        : this.headings;
    },
    filteredItems() {
      return this.searchItems.length > 0
        ? this.items.filter((x) => x.name.toLowerCase().startsWith(this.searchItems.toLowerCase()))
        : this.items;
    },
  },
  watch: {
    groupId: {
      async handler() {
        if (this.groupId) {
          this.headings = await this.getOptionHeadingsByGroup(this.groupId);
          this.headingId = undefined;
          this.optionHeadingTypeId = undefined;
          this.isSearchHeadings = false;
          this.searchHeadings = '';
          this.isSearchItems = false;
          this.searchItems = '';
          this.items = [];
          this.originalItems = [];
        }
      },
    },
    headingId: {
      async handler() {
        if (this.headingId) {
          this.items = await this.getCustomerOptionItems(this.headingId);
          this.originalItems = JSON.parse(JSON.stringify(this.items));
          this.isSearchItems = false;
          this.searchItems = '';
        }
      },
    },
  },
  async created() {
    this.groups = await this.getOptionGroups();
    this.groupId = this.groups.length > 0 ? this.groups[0].id : undefined;
  },
  methods: {
    async getOptionGroups() {
      this.isLoadingOptionGroups = true
      const groups = (await window.touch.staffGetOptionGroups()).headings;
      groups.sort((a, b) => a.listIndex - b.listIndex);
      this.isLoadingOptionGroups = false
      return groups;
    },
    async getOptionHeadingsByGroup(groupId) {
      this.isLoadingOptionHeadings = true
      const { headings } = await window.touch.staffGetOptionHeadingsByGroup(groupId);
      headings.sort((a, b) => a.listIndex - b.listIndex);
      this.isLoadingOptionHeadings = false
      return headings;
    },
    async getCustomerOptionItems(headingId) {
      this.isLoadingOptionItems = true
      const { items, optionHeadingTypeId } = await window.touch.staffGetCustomerOptionItems(
        headingId,
        this.customer.customerId,
      );
      this.optionHeadingTypeId = optionHeadingTypeId
      items.sort((a, b) => a.listIndex - b.listIndex);
      this.isLoadingOptionItems = false
      return items;
    },
    validateOptionItems(property, value) {
      if (JSON.stringify(this.items) !== JSON.stringify(this.originalItems)) {
        this.alertBox()
          .fire({
            title: 'There are unsaved changes in this Option Heading.',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Go Back',
            confirmButtonText: 'Ignore & Continue',
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              this[property] = value;
            }
          });
      } else {
        this[property] = value;
      }
    },
    async save() {
      const changesItems = [];
      let html = '';
      for (let i = 0; i < this.items.length; i += 1) {
        if (
          !this.originalItems.some(
            (x) =>
              x.optionItemId === this.items[i].optionItemId &&
              x.disabled === this.items[i].disabled,
          )
        ) {
          const status = this.items[i].invertCustomerAccess ? this.items[i].disabled ? 'Enabled' : 'Disabled' : this.items[i].disabled ? 'Disabled' : 'Enabled'
          html += `<div class="border border-t-0 border-black px-3">${this.items[i].name}</div><div class="border-b border-r border-black px-3">${status}</div>`;
          changesItems.push({
            OptionItemId: this.items[i].optionItemId,
            Disabled: this.items[i].disabled,
            HeadingTypeId: this.optionHeadingTypeId,
          });
        }
      }
      this.alertBox()
        .fire({
          title: 'Save Changes',
          html: `<div class="grid grid-cols-2 border-t border-black">${html}</div>`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.isLoadingOptionItems = true
            try {
              await window.touch.staffUpdateCustomerOptionItems(
                this.customer.datasetIds[0],
                changesItems,
                this.customer.customerId,
              );
              this.originalItems = JSON.parse(JSON.stringify(this.items));
            } catch (error) {
              if (error.status === 400) {
                this.alertBox().fire(error.popUp);
              } else {
                throw error
              }
            } finally {
              this.isLoadingOptionItems = false
            }
          }
        });
    },
  },
};
</script>
