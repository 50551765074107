<template>
  <Teleport v-if="modal_open" defer to="#portal_popup"> 
    <modal-window :modal_open="modal_open" title="Add Voucher" @close="$emit('close')">
      <div class="flex flex-col 2xs:grid grid-cols-2 gap-3">
        <div>
          Code
          <div>
            <input v-model="code" class="form-control">
          </div>
        </div>
        <div>
          Description
          <div>
            <input v-model="description" class="form-control">
          </div>
        </div>
        <div class="col-span-2">
          Amount
          <div class="flex gap-5 items-center">
            <div class="flex-1 flex justify-between gap-2">
              <button
                class="btn flex-1"
                :class="{ 'btn-action': discountType === enums.discountType.FIXED }"
                @click="discountType = enums.discountType.FIXED"
              >
                Fixed
              </button>
              <button
                class="btn flex-1"
                :class="{ 'btn-action': discountType === enums.discountType.PERCENTAGE }"
                @click="discountType = enums.discountType.PERCENTAGE"
              >
                Percentage
              </button>
            </div>
            <div class="flex-1 flex border rounded border-black h-10 overflow-hidden">
              <div v-if="discountType === enums.discountType.FIXED" class="border-r border-black px-3 flex flex-col justify-center">{{ currencySymbol }}</div>
              <input v-model="discountAmount" min="0" type="number" class="px-3 flex-grow">
              <div v-if="discountType === enums.discountType.PERCENTAGE" class="ml-auto border-l border-black px-3 flex flex-col justify-center">%</div>
            </div>
          </div>
        </div>
        <div>
          Date Range
          <div class="relative">
            <VueDatePicker
              v-model="date_range"
              :ui="{ calendar: 'font-thin text-xs', menu: 'font-thin text-xs', calendarCell: '!h-6' }"
              format="dd-MM-yyyy"
              model-type="yyyy-MM-dd"
              style="width: 100% !important"
              range
              multi-calendars
              auto-apply
              :enable-time-picker="false"
              :hide-offz-3-dates="true"
              position="left"
              :disabled-dates="(date) => date < new Date().setHours(0, 0, 0, 0)"
              :year-range="[(new Date()).getFullYear(), (new Date()).getFullYear() + 5]"
            />
          </div>
        </div>
        <div>
          Uses Per Account
          <div>
            <input v-model.number="usesPerAccount" type="number" min="0" class="form-control">
          </div>
        </div>
        <div>
          Total Uses
          <div>
            <input v-model.number="totalUses" type="number" min="0" class="form-control">
          </div>
        </div>
        <div>
          Customers Limited to
          <div>
            <div class="relative">
              <input
                v-model="search"
                placeholder="Search customers..."
                class="h-10 p-3 border border-black w-full rounded-t placeholder-gray-500"
                :class="{ 'rounded': customers.length === 0 }"
                @input="delaySearch($event.target.value)"
              >
              <div
                v-if="customers.length > 0"
                class="absolute border-l border-r border-b border-black p-3 w-full bg-white z-50"
              >
                <div
                  v-for="customer in customers"
                  :key="customer.id"
                  class="cursor-pointer hover:text-gray-500"
                  :class="{ 'opacity-50 pointer-events-none text-green-500': customersLimitedTo.some(x => x.id === customer.id) }"
                  @click="setLimitedTo(customer.id, customer.name)"
                >
                  {{ customer.name }}
                </div>
              </div>
            </div>
            <div
              v-for="customer in customersLimitedTo"
              :key="customer.id"
              class="mt-2 flex items-center gap-2 py-1 px-2 bg-gray-500 rounded cursor-pointer"
              @click="setLimitedTo(customer.id, customer.name)"
            >
              <div class="flex items-center gap-2 w-full">
                <div>{{ customer.name }}</div>
                <div class="ml-auto">
                  <i class="fal fa-times" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-right pt-20">
        <div class="flex mb-3">
          <span class="ml-auto">Please Note: <b>Vouchers apply to job level</b></span>
        </div>
        <div class="text-right mt-auto flex flex-col 2xs:block">
          <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
            Cancel
          </button>
          <button class="btn-action btn-lg" @click.prevent.stop="saveVoucher">
            Save Changes
          </button>
        </div>
      </div>
    </modal-window>
  </Teleport>
</template>

<script>

import { mapStores } from 'pinia'
import { useTouchStore } from '@/pinia/touch';
import validate from 'validate.js';
import VueDatePicker from '@vuepic/vue-datepicker';

export default {
  components: {
    VueDatePicker
  },
  props: {
    modal_open: Boolean,
    voucher: Object,
    currencySymbol: String
  },
  data() {
    return {
      code: '',
      description: '',
      discountType: 1,
      discountAmount: 0,
      date_range: undefined,
      isDatePicker: false,
      usesPerAccount: 0,
      totalUses: 0,
      perOrderOrItem: 0,
      search: '',
      timeout: undefined,
      customers: [],
      customersLimitedTo: []
    };
  },
  computed: {
    ...mapStores(useTouchStore),
  },
  watch: {
    'date_range'() {
      this.isDatePicker = false
    },
  },
  created() {
    if (this.voucher) {
      this.code = this.voucher.code;
      this.description = this.voucher.description
      this.discountType = this.voucher.discountType
      this.discountAmount = this.voucher.discountAmount
      this.usesPerAccount = this.voucher.usesPerAccount
      this.totalUses = this.voucher.totalUses
      this.perOrderOrItem = this.voucher.perOrderOrItem
      this.customersLimitedTo = this.voucher?.customersLimitedTo || []
      this.date_range = []
      this.date_range[0] = this.voucher.startDate.split('T')[0]
      this.date_range[1] = this.voucher.endDate.split('T')[0]
    }
  },
  methods: {
    delaySearch(search) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.searchCustomers(search)
      }, 300);
    },
    async searchCustomers(search) {
      if (search) {
        const response = await this.touchStore.staffListCustomers({
          limit: 5,
          offset: 0,
          query: search,
        });
        this.customers = response.customers;
      } else {
        this.customers = []
      }
    },
    setLimitedTo(customerId, name) {
      if (this.customersLimitedTo.some(x => x.id === customerId)) {
        const index = this.customersLimitedTo.findIndex(x => x.id === customerId)
        this.customersLimitedTo.splice(index, 1)
      } else {
        this.customersLimitedTo.push({
          id: customerId,
          name: name
        })
      }
      this.search = ''
      this.customers = []
    },
    async saveVoucher() {
      const validationErrors = validate(
        {
          code: this.code,
          description: this.description,
          discountAmount: this.discountAmount,
          usesPerAccount: this.usesPerAccount,
          totalUses: this.totalUses,
          dateRange: this.date_range
        },
        {
          code: {
            presence: { allowEmpty: false },
            length: {
              maximum: 20,
            },
          },
          description: {
            presence: { allowEmpty: false },
            length: {
              maximum: 500,
            },
          },
          discountAmount: {
            numericality: {
              greaterThan: 0,
            }
          },
          usesPerAccount: {
            numericality: {
              greaterThan: 0,
            }
          },
          totalUses: {
            numericality: {
              greaterThan: 0,
            }
          },
          dateRange: {
            presence: { allowEmpty: false },
          },
        },
      );

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }

      const voucher = {
        Id: this.voucher?.id,
        Code: this.code,
        Description: this.description,
        LimitTo: this.customersLimitedTo.length > 0 ? this.enums.discountLimitTo.LIMITED_TO_SPECIFIC_CUSTOMERS : this.enums.discountLimitTo.NONE,
        CustomerIdsLimitedTo: this.customersLimitedTo.map(x => x.id),
        UsesPerAccount: this.usesPerAccount,
        TotalUses: this.totalUses,
        DiscountType: this.discountType,
        DiscountAmount: this.discountAmount,
        StartDate: this.date_range ? new Date(this.date_range[0]) : undefined,
        EndDate: this.date_range ? new Date(this.date_range[1] + ' 23:59:59') : undefined,
        PerOrderOrItem: this.perOrderOrItem,
      }

      try {
        let response
        if (voucher.Id) {
          response = await window.touch.UpdateDiscountVoucher(voucher)
        } else {
          response = await window.touch.CreateDiscountVoucher(voucher)
        }
      } catch (error) {
        if (error.status === 400) {
          this.alertBox().fire(error.popUp);
        } else {
          throw error
        }
      }

      if (response) {
        this.$emit('updated')
      }
    },
  },
};
</script>

<style scoped>
.container {
  min-height: 650px;
}
</style>
