<template>
  <div>
    <form @submit="submitForm">
      <div class="grid xs:grid-cols-2 gap-5">
        <div>
          <div class="font-medium">Company</div>
          <input v-model="name" class="form-control">
        </div>
        <div>
          <div class="font-medium">Contact Number</div>
          <input v-model="phone" class="form-control">
        </div>
        <div>
          <div class="font-medium">Email</div>
          <input v-model="email" class="form-control">
        </div>
        <div>
          <div class="font-medium">Website</div>
          <input v-model="web" class="form-control">
        </div>
        <div>
          <div class="font-medium">Company Registration Number</div>
          <input
            v-model="customer.eCommerceIntegration.companyRegNumber"
            class="form-control"
          >
        </div>
      </div>
      <div class="flex flex-wrap gap-5 mt-5">
        <div class="flex-1">
          <div class="w-full flex">
            <Dropzone
              :current_file="style.fabricator_logo"
              :removable="false"
              @add-file="new_logo_file = $event; file_ready = true"
              @remove-file="new_logo_file = null; file_ready = false"
            />
          </div>
        </div>
        <div class="flex-1">
          <div class="w-full mt-4">
            <div class="font-medium flex gap-2">
              <span>Address</span>
              <strong class="cursor-pointer underline text-blue-600" @click="show_address_popup = true">Edit</strong>
            </div>
            <div v-if="customer.eCommerceIntegration.addressId">
              {{ formattedAddress(selected_address) }}
            </div>
            <div v-else class="text-red-600">You have no saved addresses</div>
          </div>
          <modal-window
            v-if="show_address_popup"
            :modal_open="show_address_popup"
            title="Your Address"
            @close="show_address_popup = false"
          >
            <popup-address-selection
              :addresses="customer.addresses"
              :selected-address="customer.eCommerceIntegration.addressId"
              :customer-id="customer.customerId"
              @select-address="customer.eCommerceIntegration.addressId = $event"
              @new-address="newAddress"
              @close="show_address_popup = false"
            />
          </modal-window>
        </div>
      </div>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button class="btn-action btn-lg" type="submit">Save Changes</button>
      </div>
    </form>
  </div>
</template>

<script>
import Dropzone from '@/components/shared/Dropzone.vue';
import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';
import { useStyleStore } from '@/pinia/style';
import { useTouchStore } from '@/pinia/touch';
import PopupAddressSelection from '@/components/shared/address/PopupAddressSelection.vue';

export default {
  components: {
    Dropzone,
    'popup-address-selection': PopupAddressSelection,
  },
  data() {
    return {
      name: undefined,
      web: undefined,
      phone: undefined,
      email: undefined,
      customer: undefined,
      file_ready: false,
      company_logo_missing: false,
      show_address_popup: false,
      new_logo_file: undefined
    };
  },
  computed: {
    ...mapStores(useCustomerStore, useStyleStore, useTouchStore),
    edited() {
      return (
        this.file_ready ||
        ((this.name || this.styleStore.fabricator.name) &&
          this.name !== this.styleStore.fabricator.name) ||
        ((this.web || this.styleStore.fabricator.web) &&
          this.web !== this.styleStore.fabricator.web) ||
        ((this.email || this.styleStore.fabricator.email) &&
          this.email !== this.styleStore.fabricator.email) ||
        ((this.phone || this.styleStore.fabricator.phone) &&
          this.phone !== this.styleStore.fabricator.phone)
      );
    },
    selected_address() {
      if (this.customer.eCommerceIntegration.addressId) {
        return this.customer.addresses.filter(
          (address) => address.id === this.customer.eCommerceIntegration.addressId,
        )[0];
      }
      return {};
    },
  },
  created() {
    this.setData()
  },
  methods: {
    async submitForm(event) {
      event.preventDefault();
      this.touchStore.staffUpdateOrganisationDetails({
        name: this.name,
        web: this.web,
        phone: this.phone,
        email: this.email,
        file: this.file_ready ? this.new_logo_file : null,
      })
        .then(async () => {
          this.styleStore.loadFabricatorStyle;
          try {
            await this.customerStore.customerUpdateRaw({
              CustomerId: this.customer.customerId,
              eCommerceIntegration: {
                companyRegNumber: this.customer.eCommerceIntegration.companyRegNumber,
                addressId: this.customer.eCommerceIntegration.addressId,
              },
            });
            const customer = await this.customerStore.customerGet(this.customer.customerId)
            this.customerStore.setCustomer(customer)
            this.$emit('close')
          } catch (error) {
            if (error.status === 400) {
              this.alertBox().fire(error.popUp);
            } else {
              throw error
            }
          }
        });
    },
    setData() {
      const fabricator = JSON.parse(JSON.stringify(this.styleStore.fabricator))
      const customer = JSON.parse(JSON.stringify(this.customerStore.customer))
      this.name = fabricator.name
      this.web = fabricator.web
      this.phone = fabricator.phone
      this.email = fabricator.email
      this.customer = customer
    },
    newAddress({ addresses, addressId }) {
      this.customer.addresses = addresses;
      this.customer.eCommerceIntegration.addressId = addressId;
    },
    formattedAddress(address) {
      return [
        address.line1,
        address.line2,
        address.line3,
        address.town,
        address.county,
        address.code,
      ]
        .filter((val) => val)
        .join(', ');
    },
  },
};
</script>
