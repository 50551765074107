<template>
  <Teleport defer to="#portal_popup"> 
    <modal-window :modal_open="true" :loading="saving" title="Create Discount" @close="$emit('close')">
      <div class="text-green-600 pl-3 flex flex-col justify-around font-medium text-base">
        * Required Fields
      </div>
      <div class="flex flex-wrap mt-5">
        <div class="w-full">
          <div class="font-medium">Description <span class="text-green-600">*</span></div>
          <input v-model="discount.description" type="text" class="form-control">
        </div>
      </div>
      <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
        <button class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0" @click.prevent="$emit('close')">
          Cancel
        </button>
        <button class="btn-action btn-lg" @click.prevent.stop="saveDiscount">Save Changes</button>
      </div>
    </modal-window>
  </Teleport>
</template>

<script>

import { mapStores } from 'pinia'
import { useTouchStore } from '@/pinia/touch';
import validate from 'validate.js';

export default {
  name: 'EditDiscount',
  data() {
    return {
      discount: {
        description: '',
      },
      saving: false,
    };
  },
  computed: {
    ...mapStores(useTouchStore),
  },
  methods: {
    async saveDiscount() {
      if (!this.saving) {
        this.saving = true;
        const validationErrors = validate(
          {
            description: this.discount.description,
          },
          {
            description: {
              presence: { allowEmpty: false },
              length: {
                maximum: 255,
              },
            },
          },
        );

        if (validationErrors) {
          this.saving = false;
          this.alertBox().fire({
            title: 'Errors',
            icon: 'error',
            html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
          });
          return;
        }

        try {
          await this.touchStore.staffUpdateDiscountCategories({
            ...this.discount,
          });
          this.$emit('saved');
          this.$emit('close');
        } catch (error) {
          if (error.status === 400) {
            this.alertBox().fire(error.popUp);
          } else {
            throw error
          }
        }
        this.saving = false
      }
    },
  },
};
</script>

<style scoped></style>
