<template>
  <div>
    <hr class="-mx-10">
    <UpdateDeliveryOptions
      v-if="editorOpen"
      :title="deliveryOptionEdit ? 'Update Delivery Option' : 'Add Delivery Option'"
      :post-codes="postCodes"
      :delivery-option="deliveryOptionEdit"
      :delivery-days-enums="deliveryDaysEnums"
      :delivery-type-enums="deliveryTypeEnums"
      :bits-in-roles="bitsInRoles"
      @load-items="loadItems"
      @close="(editorOpen = false), (deliveryOptionEdit = undefined)"
    />
    <div class="flex flex-col xs:flex-row justify-between items-center gap-2 p-5 xs:p-10 mb-0 w-full">
      <div class="text-2xl font-medium" v-text="'Delivery Options'" />
      <div class="flex flex-col 2xs:flex-row justify-around xs:ml-auto gap-2 xs:gap-5 items-center w-full xs:w-auto">
        <SearchBox class="w-full flex flex-1" placeholder="Search postcode areas..." />
        <div class="flex-1 w-full btn-action" @click.stop.prevent="editorOpen = true">Add Delivery Option</div>
      </div>
    </div>
    <div class="flex flex-row flex-wrap bg-white border m-5 xs:m-10 mt-4 relative p-3">
      <div v-if="filteredDeliveryOptions.length" class="table-lined xs:table-unstacked w-full p-3">
        <div class="grid-cols-6 hidden sm:grid">
          <strong>Name</strong>
          <strong>Delivery Type</strong>
          <strong>Lead Time</strong>
          <strong class="col-span-2">Delivery Days</strong>
        </div>
        <hr class="my-5">
        <div
          v-for="deliveryOption in filteredDeliveryOptions"
          :key="deliveryOption.id"
          class="mt-5"
        >
          <div class="grid-cols-6 flex flex-col gap-2 sm:gap-0 sm:grid">
            <div class="mt-5 xs:mt-0 flex">
              <strong class="block sm:hidden w-40 flex-1"> Name: </strong>
              <span class="flex-1">{{ deliveryOption.name }}</span>
            </div>
            <div class="flex">
              <strong class="flex-1 block sm:hidden w-40"> Delivery Type: </strong>
              <span class="flex-1">{{ deliveryTypeEnums[deliveryOption.deliveryType][0] }}</span>
            </div>
            <div class="flex">
              <strong class="block sm:hidden w-40 flex-1"> Lead Time: </strong>
              <div class="flex gap-2 flex-1">
                <span>{{ deliveryOption.leadTimeAddition }}</span>
                <span v-if="deliveryOption.leadTimeAddition === 1"> Day</span>
                <span v-else> Days</span>
              </div>
            </div>
            <div class="col-span-2 flex flex-col gap-3">
              <DeliveryDays
                :delivery-days="deliveryOption.deliveryDays"
                :delivery-type="deliveryOption.deliveryType"
                label="Branch"
              />
              <DeliveryDays
                v-if="enums.DeliveryType.ViaBranch === deliveryOption.deliveryType"
                :delivery-days="deliveryOption.onwardDeliveryDays"
                :delivery-type="deliveryOption.deliveryType"
                label="Onward"
              />
            </div>
            <div class="ml-auto hidden sm:block">
              <div
                class="table-edit-btn mr-2"
                @click.stop.prevent="(deliveryOptionEdit = deliveryOption), (editorOpen = true)"
              >
                <i class="cursor-pointer fa fa-pencil" />
              </div>
              <div
                class="table-edit-btn"
                @click.stop.prevent="deleteDeliveryOption(deliveryOption.id, deliveryOption.name)"
              >
                <i class="cursor-pointer text-red-600 fa fa-trash-can" />
              </div>
            </div>
          </div>
          <div class="flex sm:block mt-2">
            <span v-if="deliveryOption.postCodes.length > 0" class="flex-1 flex-shrink-0 w-40 opacity-50">
              Post Code Areas<span class="inline-block sm:hidden">:</span>
            </span>
            <div class="flex gap-2 flex-wrap sm:mt-2 flex-1">
              <div
                v-for="postCode in deliveryOption.postCodes"
                :key="postCode.id"
                class="bg-gray-200 p-2 rounded-lg"
              >
                <span>{{ postCode.postcodePrefix }}</span>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="ml-auto block sm:hidden mt-10">
              <div
                class="table-edit-btn mr-2"
                @click.stop.prevent="(deliveryOptionEdit = deliveryOption), (editorOpen = true)"
              >
                <i class="cursor-pointer fa fa-pencil" />
              </div>
              <div
                class="table-edit-btn"
                @click.stop.prevent="deleteDeliveryOption(deliveryOption.id, deliveryOption.name)"
              >
                <i class="cursor-pointer text-red-600 fa fa-trash-can" />
              </div>
            </div>
          </div>
          <hr class="my-5">
        </div>
      </div>
      <div v-else>No Delivery Options Available</div>
    </div>
  </div>
</template>
<script>
import UpdateDeliveryOptions from '@/components/business/manageTouchAccount/deliveryOptions/UpdateDeliveryOptions.vue';
import SearchBox from '@/components/shared/SearchBox.vue';
import DeliveryDays from '@/components/business/manageTouchAccount/deliveryOptions/DeliveryDays.vue';

export default {
  components: {
    UpdateDeliveryOptions,
    SearchBox,
    DeliveryDays
  },
  data() {
    return {
      postCodes: [],
      deliveryOptions: [],
      editorOpen: false,
      deliveryOptionEdit: undefined,
    };
  },
  computed: {
    deliveryDaysEnums() {
      return Object.keys(this.enums.DeliveryDays).map((key) => [key, this.enums.DeliveryDays[key]]);
    },
    deliveryTypeEnums() {
      return Object.keys(this.enums.DeliveryType).map((key) => [key, this.enums.DeliveryType[key]]);
    },
    filteredDeliveryOptions() {
      const search = this.$route.query.search || '';
      return search.length > 0
        ? this.deliveryOptions.filter((x) =>
          x.postCodes.some((y) =>
            y.postcodePrefix.toLowerCase().startsWith(search.toLowerCase()),
          ),
        )
        : this.deliveryOptions;
    },
  },
  async created() {
    await this.loadItems();
  },
  methods: {
    async loadItems() {
      await this.getPostCodes();
      await this.getDeliveryOptions(undefined, 0, 1000, undefined);
    },
    async getDeliveryOptions(Name, Offset, Limit, DeliveryDays) {
      this.deliveryOptions = await window.touch.staffGetDeliveryOptions(
        Name,
        Offset,
        Limit,
        DeliveryDays,
      );
      for (let i = 0; i < this.deliveryOptions.length; i += 1) {
        this.deliveryOptions[i].postCodes = this.parsePostCodes(
          this.postCodes.filter((x) => x.deliveryOptionId === this.deliveryOptions[i].id),
        );
      }
    },
    async getPostCodes() {
      this.postCodes = await window.touch.staffGetPostCodes(undefined, 0, 10000);
    },
    async deleteDeliveryOption(id, name) {
      this.alertBox()
        .fire({
          title: `Are you sure you want to delete ${name} ?`,
          text: 'This action cannot be undone.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const deliveryOption = [
              {
                id,
                delete: true,
              },
            ];

            try {
              await window.touch.staffUpdateDeliveryOptions(deliveryOption);
            } catch (error) {
              if (error.status === 400) {
                this.alertBox().fire(error.popUp);
              } else {
                throw error
              }
            }
            await this.loadItems();
          }
        });
    },
    bitsInRoles(deliveryDays) {
      let b = 1;
      const res = [];
      while (b <= deliveryDays) {
        if (b & deliveryDays) res.push(b);
        b <<= 1;
      }
      return res;
    },
    parsePostCodes(postCodes) {
      // Smart sorting e.g. B1 B2 B10 (B10 goes after 2 not before)
      postCodes.sort((a, b) =>
        a.postcodePrefix.localeCompare(b.postcodePrefix, undefined, {
          numeric: true,
          sensitivity: 'base',
        }),
      );

      let condensedPostCodes = [];
      for (let i = 0; i < postCodes.length; i += 1) {
        let startChars = postCodes[i].postcodePrefix.substring(0, 2);
        let endChars = postCodes[i].postcodePrefix.substring(2, postCodes[i].postcodePrefix.length);
        if (!postCodes[i].isChecked) {
          // First two string, remaining numbers
          if (this.isStringThenNumbers(startChars, endChars)) {
            const postCodesWithSameStart = postCodes.filter(
              (x) => x.postcodePrefix.startsWith(startChars) && !x.isChecked,
            );
            const condensedPostCode = this.groupPostCodes(
              postCodes,
              postCodesWithSameStart,
              startChars,
              2,
            );
            condensedPostCodes = condensedPostCodes.concat(condensedPostCode);
          } else {
            startChars = postCodes[i].postcodePrefix.substring(0, 1);
            endChars = postCodes[i].postcodePrefix.substring(1, postCodes[i].postcodePrefix.length);
            // First letter string, remaining numbers
            if (this.isStringThenNumbers(startChars, endChars)) {
              const postCodesWithSameStart = postCodes.filter(
                (x) => x.postcodePrefix.startsWith(startChars) && !x.isChecked,
              );
              const condensedPostCode = this.groupPostCodes(
                postCodes,
                postCodesWithSameStart,
                startChars,
                1,
              );
              condensedPostCodes = condensedPostCodes.concat(condensedPostCode);
            } else {
              // Add remaining postcodes as they are
              postCodes[i].isChecked = true;
              condensedPostCodes = condensedPostCodes.concat({
                postcodePrefix: postCodes[i].postcodePrefix,
              });
            }
          }
        }
      }
      return condensedPostCodes;
    },
    isStringThenNumbers(firstPart, secondPart) {
      const hasNumber = /\d/;
      return !hasNumber.test(firstPart) && hasNumber.test(secondPart);
    },
    groupPostCodes(postCodes, postCodesWithSameStart, startChars, numberToSliceTo) {
      if (postCodesWithSameStart.length > 0) {
        const firstPostCode = postCodesWithSameStart[0].postcodePrefix;
        const startNumber = Number(firstPostCode.substring(numberToSliceTo, firstPostCode.length));
        let endNumber = startNumber;
        // Don't check the same postcode later
        let index = postCodes.findIndex((x) => x.postcodePrefix === firstPostCode);
        postCodes[index].isChecked = true;
        if (Number.isNaN(startNumber)) {
          return [
            {
              postcodePrefix: firstPostCode,
            },
          ];
        }
        for (let j = 1; j < postCodesWithSameStart.length; j += 1) {
          const endChars = postCodesWithSameStart[j].postcodePrefix.substring(
            numberToSliceTo,
            postCodesWithSameStart[j].postcodePrefix.length,
          );
          const previousPostCode = postCodesWithSameStart[j - 1].postcodePrefix;
          // If previous postcodes ending numbers are increment of the current, add to the condenser e.g. b1 now becomes b1-2.
          if (
            endChars - 1 ==
            previousPostCode.substring(numberToSliceTo, previousPostCode.length)
          ) {

            endNumber += 1;
            // Don't check the same postcode later
            index = postCodes.findIndex(
              (x) => x.postcodePrefix === postCodesWithSameStart[j].postcodePrefix,
            );
            postCodes[index].isChecked = true;
          } else {
            break;
          }
        }
        return [
          {
            postcodePrefix:
              startNumber === endNumber
                ? startChars + startNumber
                : `${startChars + startNumber} - ${endNumber}`,
          },
        ];
      }
      return [];
    },
  },
};
</script>
