<template>
  <div>
    <div class="bg-gray-200 p-3 mb-1" @click="$emit('place')">
      <ShowSvg
        v-if="image"
        :branded-mode="true"
        :show-issues="false"
        :thumbnail-mode="true"
        :svg="image"
        class="mx-auto visualiser-place-product-tile-img"
      />
    </div>
    <div class="mb-1" v-text="product.retailDescription" />
    <div v-if="product.location !== 'None'" v-text="product.location" />
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useBasketStore } from '@/pinia/basket';
import ShowSvg from '@/components/shared/ShowSvg.vue';

export default {
  components: {
    ShowSvg
  },
  props: ['product', 'view'],
  data() {
    return {
      image: false,
    };
  },
  computed: {
    ...mapStores(useBasketStore),
  },
  watch: {
    async view() {
      this.image = await this.loadImage();
    },
  },
  async mounted() {
    this.image = await this.loadImage();
    this.image = await this.loadImage();
  },
  methods: {
    async loadImage() {
      return this.basketStore.getProcessingImage({
        itemKey: this.product.key,
        imageType: this.view === 'inside' ? 2 : 1,
      });
    },
  },
};
</script>
