import { errorCallback, successCallback } from '@/composables/validate';

export async function loadKPIDashboardActiveUsers(range) {
  return this.handleApiRequest({ 
    method: 'kpi/ActiveUsers',
    payload: {
      Range: Number(range),
      GetTotal: true,
    },
    errorCallback
  })
}

export async function loadKPIDashboardQuotes(range) {
  return this.handleApiRequest({ 
    method: 'kpi/NumberOfContracts',
    payload: {
      Range: Number(range),
      Type: Number(window.touch.CONTRACT_TYPE_QUOTE),
    },
    errorCallback
  })
}

export async function loadKPIDashboardOrders(range) {
  return this.handleApiRequest({ 
    method: 'kpi/NumberOfContracts',
    payload: {
      Range: Number(range),
      Type: Number(window.touch.CONTRACT_TYPE_ORDER),
    },
    errorCallback
  })
}

export async function loadKPITopCustomersForQuotes(range) {
  return this.handleApiRequest({ 
    method: 'kpi/TopCustomersForContracts',
    payload: {
      Range: Number(range),
      Type: Number(window.touch.CONTRACT_TYPE_QUOTE),
      TopN: 8,
    },
    errorCallback
  })
}

export async function loadKPITopCustomersForOrders(range) {
  return this.handleApiRequest({ 
    method: 'kpi/TopCustomersForContracts',
    payload: {
      Range: Number(range),
      Type: Number(window.touch.CONTRACT_TYPE_ORDER),
      TopN: 8,
    },
    errorCallback
  })
}

export async function loadKPIConversionsFromEnquiriesToQuotes(range) {
  return this.handleApiRequest({ 
    method: 'kpi/NumberOfContractConversions',
    payload: {
      Range: Number(range),
      Type: Number(1),
    },
    errorCallback
  })
}

export async function loadKPIConversionsFromQuotesToOrders(range) {
  return this.handleApiRequest({ 
    method: 'kpi/NumberOfContractConversions',
    payload: {
      Range: Number(range),
      Type: Number(2),
    },
    errorCallback
  })
}
