<template>
  <div class="flex flex-col">
    <Teleport v-if="popupOpen" defer to="#portal_popup">
      <note-update
        v-show="popupOpen"
        :notes="notes"
        :note-type="noteType"
        :title
        :loading
        @save="save($event)"
        @close="popupOpen = false, loading = false"
      >
        <slot />
      </note-update>
    </Teleport>
    <div class="font-bold flex justify-between">
      <div>
        <slot />
      </div>
      <div>
        <div
          v-if="!demo_mode && !readOnly"
          class="px-2 py-1 text-2xs rounded text-green-600 border border-green-600 cursor-pointer inline-block"
          @click="createNote()"
        >
          <i class="fa fa-pencil" />
        </div>
      </div>
    </div>
    <div v-if="notes" class="border-t mt-3 pt-3 whitespace-pre-line overflow-y-auto" style="max-height: 300px;">
      <div v-for="note in notes" :key="note.id">
        {{ note.text }}
      </div>
    </div>
    <div v-else class="border-t mt-3 pt-3 text-gray-300">No Notes.</div>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useTouchStore } from '@/pinia/touch';
import NoteUpdate from '@/components/shared/NoteUpdate.vue';

export default {
  components: {
    NoteUpdate,
  },
  props: ['title', 'readOnly', 'contractId', 'jobId', 'noteType', 'notes'],
  data() {
    return {
      popupOpen: false,
      loading: false
    };
  },
  computed: {
    ...mapStores(useTouchStore),
  },
  methods: {
    async save(text) {
      this.loading = true
      if (this.notes.length) {
        try {
          if (this.notes.length > 1) {
            await this.touchStore.createNote({
              contractId: this.contractId,
              jobId: this.jobId,
              noteType: this.noteType,
              text,
            });
            for (let i = 0; i < this.notes.length; i++) {
              await this.touchStore.updateNote({
                contractId: this.contractId,
                jobId: this.jobId,
                noteId: this.notes[i].id,
                noteType: this.noteType,
                text: this.notes[i].text,
                deleteNote: true,
              });
            }
          } else {
            const note = this.notes[0]
            await this.touchStore.updateNote({
              contractId: this.contractId,
              jobId: this.jobId,
              noteType: this.noteType,
              noteId: note.id,
              text,
              deleteNote: text.length === 0,
            });
          }
        } catch (error) {
          if (error.status === 400) {
            this.alertBox().fire(error.popUp);
          } else {
            throw error
          }
        }
        this.$emit('loadNotes');
        this.popupOpen = false
        this.loading = false
      } else {
        this.$emit('loadNotes', async () => {
          this.$nextTick(async () => {
            if (this.notes.length) {
              this.alertBox().fire({
                title: 'Save Failed',
                text: `${this.title} has already been updated. Please refresh to see changes.`,
                icon: 'error',
              });
            } else {
              await this.touchStore.createNote({
                contractId: this.contractId,
                jobId: this.jobId,
                noteType: this.noteType,
                text,
              });
              this.$emit('loadNotes');
            }
            this.popupOpen = false
            this.loading = false
          })
        });
      }
    },
    createNote() {
      this.popupOpen = true;
    },
  },
};
</script>
