<template>
  <div ref="company" class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch">
    <div class="p-5 xs:p-10 min-h-screen">
      <div class="mx-auto" style="max-width: 1550px">
        <Details
          v-if="$route.query.active_section === 'company-details'"
          ref="details" 
          :settings="settings"
          :setup="setup"
          @set-logo-file="logo_src = $event"
        >
          <template #email_address>
            <slot name="email_address" />
          </template>
        </Details>
        <Settings
          v-else-if="$route.query.active_section === 'settings'" 
          :company-settings="companySettings"
          :original-company-settings="originalCompanySettings"
          :refresh-required="refreshRequired"
          :setup="setup"
        >
          <template #enquiries-overdue-limit>
            <slot name="enquiries-overdue-limit" />
          </template>
        </Settings>
      </div>
    </div>
    <div
      v-if="is_admin_user"
      class="sticky bottom-0 bg-white p-3 xs:p-4 text-right border-gray-300 border-t"
    >
      <div class="flex flex-col xs:flex-row justify-between" :class="{ 'pointer-events-none opacity-50': loading }">
        <div v-if="!demo_mode" class="ml-auto">
          <button class="btn-action btn-lg w-full xs:w-auto" @click.prevent.stop="saveSettings()">
            Save all Changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Details from '@/components/shared/setup/company/Details.vue';
import Settings from '@/components/shared/setup/company/Settings.vue';
import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';
import { useTouchStore } from '@/pinia/touch';
import validate from 'validate.js';

export default {
  components: {
    Details,
    Settings
  },
  props: {
    setup: String,
    customerId: Number
  },
  data() {
    return {
      loading: true,
      settings: {},
      companySettings: {},
      originalCompanySettings: undefined,
      logo_src: undefined,
      refreshRequired: false
    };
  },
  computed: {
    ...mapStores(useCustomerStore, useTouchStore),
  },
  watch: {
    '$route.query': {
      async handler(to) {
        if (!to.active_section) {
          this.$router.replace({ query: { active_section: 'company-details' } });
          return
        }
        if (this.$refs.company) {
          this.$refs.company.scrollTop = 0;
        }
        await this.loadSettings();
        this.loading = false;
      },
      immediate: true,
    },
  },
  methods: {
    async loadSettings() {
      const customer = this.customerStore.customer
      this.settings.logo = window.touch.imageBaseURL + customer.styling.logo + 1;
      this.settings.installerName = customer.eCommerceIntegration.installerName;
      this.settings.email = customer.eCommerceIntegration.email;
      this.settings.workPhone = customer.eCommerceIntegration.workPhone;
      this.settings.mobilePhone = customer.eCommerceIntegration.mobilePhone;
      this.settings.companyRegNumber = customer.eCommerceIntegration.companyRegNumber;
      this.settings.webAddress = customer.eCommerceIntegration.webAddress;
      this.settings.addressId = customer.eCommerceIntegration.addressId;
      this.settings.addresses = customer.addresses;
      this.settings.customerId = customer.customerId;
      this.settings.fensaRegistrationNumber = customer.eCommerceIntegration.fensaRegistrationNumber;
      this.settings.vatRegistrationNumber = customer.eCommerceIntegration.vatRegistrationNumber;
      let response = await this.touchStore.customerGetCompanySettings();
      this.companySettings = response.companySettings || {};
      if (!this.companySettings.showConsumerSizes) {
        this.companySettings.showConsumerSizes = false
      }
      if (!this.companySettings.showDesignNames) {
        this.companySettings.showDesignNames = false
      }

      response = await window.touch.authGetCompanyWebSettings()
      if (response.companyWebSettings.some(x => x.key === 'tagId')) {
        const index = response.companyWebSettings.findIndex(x => x.key === 'tagId')
        this.companySettings.tagId = response.companyWebSettings[index].value
      }
      if (response.companyWebSettings.some(x => x.key === 'iframeRedirect')) {
        const index = response.companyWebSettings.findIndex(x => x.key === 'iframeRedirect')
        this.companySettings.iframeRedirect = response.companyWebSettings[index].value
      }
      this.originalCompanySettings = JSON.parse(JSON.stringify(this.companySettings))
    },
    async saveSettings() {
      this.loading = true;

      const validationErrors = this.validateInput();
      if (validationErrors) {
        this.loading = false;
        this.alertBox().fire({
          title: 'Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }

      if (this.companySettings?.tagId?.includes('-')) {
        this.alertBox().fire({
          title: 'Invalid Google Tag',
          icon: 'error',
          html: 'Please do not enter <strong>G-</strong> as part of the code.',
        });
        this.loading = false;
        return
      }

      const options = {
        email: this.settings.email,
        mobilePhone: this.settings.mobilePhone,
        webAddress: this.settings.webAddress,
        alias: this.settings.alias,
        addressId: this.settings.addressId,
        companyRegNumber: this.settings.companyRegNumber,
        fensaRegistrationNumber: this.settings.fensaRegistrationNumber,
        vatRegistrationNumber: this.settings.vatRegistrationNumber,
      };

      let logoUpdates = {};

      if (this.logo_src !== null) {
        logoUpdates = await this.customerStore.formatCustomerUpdateLogo({
          logo: this.logo_src,
        });
      }

      this.$emit('staffUpdateEnquiryExpirationTime');

      const ecommerceUpdates = await this.customerStore.formatCustomerUpdateCommerceIntegrationSettings({
        eCommerceIntegrationSettings: options,
      });

      try {
        await this.touchStore.customerUpdateCompanySettings({
          showSizes: this.companySettings?.showConsumerSizes,
          showDesignNames: this.companySettings?.showDesignNames
        });

        if (typeof this.companySettings?.tagId !== 'undefined') {
          await window.touch.authUpdateCompanyWebSettings('tagId', this.companySettings?.tagId || '');
        }
        if (typeof this.companySettings?.iframeRedirect !== 'undefined') {
          await window.touch.authUpdateCompanyWebSettings('iframeRedirect', this.companySettings?.iframeRedirect || '');
        }

        await this.customerStore.customerUpdateRaw({
          ...logoUpdates,
          ...ecommerceUpdates,
          CustomerId: this.customerId,
        });

        if (this.originalCompanySettings?.tagId !== this.companySettings?.tagId) {
          this.refreshRequired = true
        }
        this.$emit('afterSaved', async () => { await this.loadSettings(), this.loading = false })
      } catch (error) {
        if (error.status === 400) {
          this.alertBox().fire(error.popUp);
        } else {
          throw error
        }
      }
    },
    validateInput() {
      return validate(
        {
          emailAddress: this.settings.email,
          webAddress: this.settings.webAddress,
          phoneNumber: this.settings.mobilePhone,
          address: this.settings.addressId,
          iframeRedirect: `https://${this.companySettings?.iframeRedirect}`
        },
        {
          emailAddress: {
            presence: { allowEmpty: false },
            email: true,
          },
          phoneNumber: {
            presence: { allowEmpty: false },
          },
          webAddress: {
            presence: { allowEmpty: false },
          },
          address: {
            presence: {
              allowEmpty: false,
              message: 'must be chosen.',
            },
            numericality: {
              onlyInteger: true,
              greaterThan: 0,
              message: 'must be chosen.',
            },
          },
          iframeRedirect: {
            url: this.companySettings?.iframeRedirect,
            presence: { allowEmpty: true },
          }
        },
      );
    },
  },
};
</script>
