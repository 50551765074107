<template>
  <div class="relative h-full">
    <loading
      id="customer_index_scrolling_outer_wrapper"
      :loading="loading"
      class="bg-gray-200 overflow-y-auto w-full h-full scrolling-touch"
    >
      <div class="m-3 md:m-10">
        <div class="flex flex-col sm:flex-row">
          <div
            class="sm:ml-3 relative pricing-category-options-list-wrapper bg-white h-full"
            :style="{ height: '50vh' }"
          >
            <div class="absolute top-0 left-0 right-0 bottom-0 overflow-y-auto border border-gray-400">
              <div
                v-if="anomalies.length === 0 || filteredAnomalies.length === 0"
                class="flex justify-between bg-white"
              >
                <div class="flex flex-col justify-around">
                  <div class="p-3">No Matching Products Found</div>
                </div>
                <div class="p-3">
                  <button v-if="$route.query.search" class="btn bg-white" @click="$router.replace({ query: undefined })">
                    clear search
                  </button>
                </div>
              </div>
              <table class="table-pricing mb-6">
                <tr
                  v-for="price in $route.query.search || focFilterActive
                    ? filteredAnomalies
                    : anomalies"
                  :key="price.id"
                >
                  <td>
                    <price-line
                      :customer-id="customerStore.customer.customerId"
                      :dataset-id="customerStore.customer.datasetIds[0]"
                      :default-currency="defaultCurrency"
                      :price="price"
                      :list-value="price.listValue"
                      :is-price-selectable="false"
                      @price-updated="priceUpdated(price, $event)"
                    />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!loading"
        class="absolute bottom-0 right-0 text-right w-full p-3 bg-white border-t"
      >
        <button class="btn-action btn-lg" @click="savePriceData">Save Changes</button>
      </div>
    </loading>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useCustomerStore } from '@/pinia/customer';
import { useTouchStore } from '@/pinia/touch';
import PriceLine from '@/components/shared/PriceLine.vue';
import validate from 'validate.js';

export default {
  components: {
    PriceLine,
  },
  props: {
    focFilterActive: Boolean,
  },
  data() {
    return {
      anomalies: [],
      original_anomalies: [],
      loading: true,
      defaultCurrency: undefined,
    };
  },
  computed: {
    ...mapStores(useCustomerStore, useTouchStore),
    filteredAnomalies() {
      const { search } = this.$route.query;
      let searchResults = this.anomalies;

      if (search) {
        searchResults = searchResults.filter((x) =>
          x.description.toLowerCase().includes(search.toLowerCase()),
        );
      }

      if (this.focFilterActive) {
        searchResults = searchResults.filter(
          (x) => x.overrideValue === 0 || x.overrideValue === '0',
        );
      }

      return searchResults;
    },
  },
  async mounted() {
    this.defaultCurrency = (await this.touchStore.commonGetCurrencies()).filter(
      (currency) => currency.isDefault,
    )[0].code;
    await this.loadPriceData();
    this.loading = false;
  },
  methods: {
    async loadPriceData() {
      const anomalies = await window.touch.GetCustomerOnlyPrices(
        this.customerStore.customer.datasetIds[0],
        9999,
        0,
      );
      this.anomalies = anomalies.anomalies
        .map((anomaly) => ({
          ...anomaly,
        }))
        .sort((a, b) => a.listIndex - b.listIndex)
        .map((anomoly) => ({
          ...anomoly,

          overrideValue: anomoly.foc ? 0 : anomoly.overrideValue ? anomoly.overrideValue : null,
        }));
      this.original_anomalies = JSON.parse(JSON.stringify(this.anomalies));
    },
    priceUpdated(price, object) {
      const modifiedPrice = this.anomalies.find((anomaly) => anomaly.id === price.id);
      modifiedPrice.valueIsNett = object.valueIsNett;
      modifiedPrice.overrideValue = object.overrideValue;
    },
    async savePriceData() {
      this.loading = true;
      const anomalies = this.modifiedAnomalies().map((anomaly) => ({
        Id: anomaly.id,
        Value:
          anomaly.overrideValue === '' || anomaly.overrideValue === null
            ? 0
            : Number(anomaly.overrideValue),
        FOC:
          anomaly.overrideValue !== '' &&
          anomaly.overrideValue !== null &&
          Number(anomaly.overrideValue) === 0,
      }));
      if (this.validateInput()) {
        try {
          await window.touch.updateAnomalies(this.customerStore.customer.datasetIds[0], anomalies);
        } catch (error) {
          if (error.status === 400) {
            this.alertBox().fire(error.popUp);
          } else {
            throw error
          }
        }
        await this.loadPriceData();
      }
      this.loading = false;
    },
    validateInput() {
      let isValid = true;
      this.anomalies.forEach((anomaly) => {
        if (!validate.isNumber(Number(anomaly.overrideValue))) {
          isValid = false;
        }
      });
      return isValid;
    },
    modifiedAnomalies() {
      return this.anomalies.filter(
        (anomaly) =>
          this.original_anomalies.findIndex(
            (originalAnomaly) =>
              originalAnomaly.id === anomaly.id &&
              originalAnomaly.overrideValue !== anomaly.overrideValue,
          ) !== -1,
      );
    },
  },
};
</script>
