<template>
  <div>
    <Teleport v-if="select_currency_modal_open" defer to="#portal_popup"> 
      <modal-window
        :modal_open="select_currency_modal_open"
        title="Select Currency"
        @close="select_currency_modal_open = false"
      >
        <div>
          <select v-model="selected_currency_to_add" class="form-control">
            <option disabled :value="null">Choose Currency</option>
            <option v-for="(index, currency) in currencyList()" :key="index" :value="currency">
              {{ currency }}
            </option>
          </select>
        </div>
        <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
          <button
            class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0"
            @click="select_currency_modal_open = false"
          >
            Cancel
          </button>
          <button
            class="btn-action btn-lg"
            @click.prevent.stop="addCurrency(selected_currency_to_add)"
          >
            Save
          </button>
        </div>
      </modal-window>
    </Teleport>
    <hr class="-mx-10">
    <div class="flex flex-wrap-reverse justify-between items-center m-3 xs:m-10 mb-0">
      <div class="text-2xl font-medium" v-text="'Currencies'" />
      <div class="flex ml-auto">
        <button class="btn mr-1" @click="updateExchangeRates">Update Exchange Rates</button>
        <button class="btn-action" @click="select_currency_modal_open = true">Add Currency</button>
      </div>
    </div>
    <div
      v-if="currencies.length > 0"
      class="flex flex-wrap bg-white border m-3 xs:m-10 mt-4 relative pr-16"
    >
      <div
        v-for="(currency, index) in currencies"
        :key="index"
        class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4"
      >
        <div class="font-bold">{{ currency.name }} ({{ currency.code }})</div>
        <div v-if="currency.isDefault" class="h-10 flex flex-col justify-around">Base</div>
        <div v-else class="h-10 flex flex-col justify-around">{{ currency.exchangeRate }}</div>
      </div>
      <div class="absolute bottom-0 right-0 p-5">
        <div
          class="px-2 py-1 text-2xs rounded text-green-600 border border-green-600 cursor-pointer hover:text-white hover:bg-green-600"
          @click="editing = true"
        >
          <i class="fa fa-pencil" />
        </div>
      </div>
    </div>
    <div v-else class="bg-white border m-3 xs:m-10 p-3 xs:p-10 mt-4">No Currencies to Show</div>
    <Teleport v-if="editing" defer to="#portal_popup"> 
      <modal-window :modal_open="editing" title="Edit Currencies" @close="editing = false">
        <div class="flex flex-wrap">
          <div
            v-for="(currency, index) in currencies"
            :key="index"
            class="w-full mt-4 xs:w-1/2 relative"
            :class="{
              'xs:pr-5': index % 2 === 0,
              'xs:pl-5': index % 2 !== 0,
              'xs:mt-8': index > 1,
            }"
          >
            <div class="flex">
              <div class="font-medium">{{ currency.name }} ({{ currency.code }})</div>
              <div
                v-if="!currency.isDefault"
                class="ml-1 text-green-600 cursor-pointer text-xs"
                @click="makeDefault(currency)"
              >
                - Make Default
              </div>
            </div>
            <div class="flex items-center">
              <input v-model="currency.exchangeRate" class="form-control">
              <div
                v-if="!currency.isDefault"
                class="ml-3 cursor-pointer"
                @click="deleteCurrency(currency.id)"
              >
                <i class="fal fa-trash text-red-600" />
              </div>
            </div>
          </div>
        </div>
        <div class="text-right mt-4 xs:mt-8 flex flex-col 2xs:block">
          <button
            class="btn btn-lg 2xs:mr-1 mb-1 2xs:mb-0"
            @click.prevent="(editing = false), loadCurrencies"
          >
            Cancel
          </button>
          <button class="btn-action btn-lg" @click.prevent.stop="saveExchangeRates">
            Save Changes
          </button>
        </div>
      </modal-window>
    </Teleport>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useTouchStore } from '@/pinia/touch';
import { currencyLookup, currencyList } from '@/composables/currency';

export default {
  setup() {
    return { currencyLookup, currencyList }
  },
  data() {
    return {
      currencies: [],
      editing: false,
      select_currency_modal_open: false,
      selected_currency_to_add: null,
    };
  },
  computed: {
    ...mapStores(useTouchStore),
  },
  mounted() {
    this.loadCurrencies();
  },
  methods: {
    async updateExchangeRates() {
      const result = await this.touchStore.staffGetLatestExchangeRates();
      this.currencies = this.currencies.map((currency) => {
        if (currency.code === result.baseCurrency) {
          return {
            ...currency,
            exchangeRate: 1,
          };
        }

        return {
          ...currency,
          exchangeRate: result.rates.find((rate) => rate.code === currency.code).rate,
        };
      });

      this.saveExchangeRates();
    },
    async saveExchangeRates() {
      try {
        await this.touchStore.staffUpdateCurrency(this.currencies);
      } catch (error) {
        if (error.status === 400) {
          this.alertBox().fire(error.popUp);
        } else {
          throw error
        }
      }
      await this.loadCurrencies();
      this.editing = false;
    },
    async makeDefault(currency) {
      try {
        await this.touchStore.staffUpdateCurrency([
          {
            Id: currency.id,
            IsDefault: true,
          },
        ]);
        this.loadCurrencies();
      } catch (error) {
        if (error.status === 400) {
          this.alertBox().fire(error.popUp);
        } else {
          throw error
        }
      }
    },
    async addCurrency(code) {
      if (this.selected_currency_to_add === null) {
        this.alertBox().fire('Please select a currency');
        return;
      }
      const isDefault = this.currencies.length === 0;

      try {
        await this.touchStore.staffUpdateCurrency([
          {
            Name: currencyLookup()[code],
            Code: code,
            ExchangeRate: 1,
            IsDefault: isDefault,
          },
        ]);

        this.loadCurrencies().then(async () => {
          if (isDefault) {
            return;
          }

          const result = await this.touchStore.staffGetLatestExchangeRates();
          this.currencies = this.currencies.map((currency) => ({
            ...currency,
            exchangeRate:
              currency.code === code
                ? result.rates.find((rate) => rate.code === currency.code).rate
                : currency.exchangeRate,
          }));

          this.saveExchangeRates();
        });
      } catch (error) {
        if (error.status === 400) {
          this.alertBox().fire(error.popUp);
        } else {
          throw error
        }
      }
      this.select_currency_modal_open = false;
    },
    async loadCurrencies() {
      this.currencies = await this.touchStore.commonGetCurrencies();
    },
    async deleteCurrency(id) {
      this.alertBox()
        .fire({
          title: 'Are you sure?',
          text: 'Are you sure you want to delete this Currency?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then(async (result) => {
          if (result.value) {
            try {
              await this.touchStore.staffUpdateCurrency([
                {
                  Id: id,
                  Delete: true,
                },
              ]);

              this.loadCurrencies();
            } catch (error) {
              if (error.status === 400) {
                this.alertBox().fire(error.popUp);
              } else {
                throw error
              }
            }
          }
        });
    },
  },
};
</script>
