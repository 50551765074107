import { useAuthStore } from '@/pinia/auth';
import { useCustomerStore } from '@/pinia/customer';

export default async (to, from, next) => {
  const authStore = useAuthStore()
  const customerStore = useCustomerStore()
  customerStore.freshdeskWidgetEnabled = false;
  const isLoginValid = await authStore.validateLogin('public')
  if (isLoginValid) {
    next();
  } else if (to.matched.some((record) => record.meta.brandedModeRoute === true)) {
    next({
      path: `/launch/${to.params.slug}`,
    });
  } else {
    next()
  }
};
