<template>
  <div
    class="relative w-full h-screen flex items-center justify-center bg-cover bg-center px-10"
    style="background-image: url('/images/login-background.png');"
  >
    <div class="p-10 bg-white bg-opacity-50 backdrop-blur-sm flex flex-col max-w-3xl text-center rounded">
      <img src="/images/touch-logo.svg" class="max-w-xs mx-auto mb-10">
      <div v-if="$route.query.status === 404">
        <h1 class="text-3xl">404. Page not found.</h1>
        <h1 class="text-xl">Please let us know what happened so we can better understand the problem.</h1>
      </div>
      <div v-else>
        <h1 class="text-3xl">Oops! Something went wrong...</h1>
        <h1 class="text-xl">
          Don’t worry though, it’s not your fault. Please let us know what happened so we can better understand the problem.
        </h1>
      </div>
      <form class="flex flex-col gap-3 mt-10" @submit.prevent="submit">
        <input
          v-model="name"
          type="text"
          placeholder="Name"
          autocomplete="off"
          class="border border-solid border-gray-400 rounded w-full p-2"
        >
        <input
          v-model="email"
          type="text"
          placeholder="Email"
          autocomplete="off"
          class="border border-solid border-gray-400 rounded w-full p-2"
        >
        <textarea
          v-model="message"
          type="text"
          placeholder="Message"
          autocomplete="off"
          class="border border-solid border-gray-400 rounded w-full p-2"
        />
        <button class="btn-action btn-lg mx-auto mt-5">Submit Feedback</button>
      </form>
    </div>
    <div class="absolute top-0 right-0">
      <div class="p-5 sm:p-10">
        <i class="cursor-pointer text-white text-4xl fa fa-thin fa-times-circle" @click="exit" />
      </div>
    </div>
  </div>
</template>


<script>

import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { useBrandedStore } from '@/pinia/branded';
import validate from 'validate.js';
import * as Sentry from '@sentry/browser';

export default {
  props: {
    errorType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      name: '',
      email: '',
      message: ''
    }
  },
  computed: {
    ...mapStores(useAuthStore, useBrandedStore)
  },
  created() {
    this.eventId = this.$route.query.eventId
    this.name = this.authStore.firstName ? `${this.authStore.firstName} ${this.authStore.lastName}` : ''
    this.email = this.authStore.email
    this.message = this.$route.query.title && this.$route.query.html
      ? `${this.$route.query.title} ${this.$route.query.html}`
      : this.$route.query.title ? this.$route.query.title : ''

    if (!this.eventId) {
      this.$router.replace('/')
    }
  },
  methods: {
    submit() {
      const validationErrors = validate(
        {
          'message': this.message,
        },
        {
          'message': {
            presence: { allowEmpty: false },
          }
        }
      );

      if (validationErrors) {
        this.alertBox().fire({
          title: 'Validation Errors',
          icon: 'error',
          html: `<ul>${Object.values(validationErrors).join('</li><li>')}</ul>`,
        });
        return;
      }

      Sentry.captureFeedback({
        name: this.name,
        email: this.email,
        message: this.message,
        associatedEventId: this.eventId,
      });

      this.redirect()

      this.alertBox().fire({
        title: 'Feedback submitted. Thank you!',
      })
    },
    exit() {
      this.alertBox()
        .fire({
          text: 'Click continue to be redirected before the error.',
          showCancelButton: true,
          confirmButtonText: 'Continue',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$router.go(-1)
          }
        });
    },
    redirect() {
      if (this.authStore.installation === 'public') {
        this.$router.replace(this.brandedStore.launchURL).catch(() => { });
      } else {
        this.$router.replace('/').catch(() => { });
      }
    }
  }
};
</script>