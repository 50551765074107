<template>
  <touch-layout page_override_class="retail-designer-basket">
    <OptionControlTestMode />
    <transition name="fade-in" mode="out-in">
      <div v-if="loading || saving_quote" class="flex items-center justify-center" style="flex: 1 1 0">
        <div>
          <loading
            :key="1"
            style="display: block !important; flex: none !important"
            :loading="loading || saving_quote"
            :label="saving_quote ? 'Sending your enquiry' : 'Loading'"
          />
          <div v-if="saving_quote">
            <span>Please do not click back or refresh button.</span>
          </div>
        </div>
      </div>
      <div v-else :key="2" class="flex h-screen overflow-hidden relative">
        <div
          v-if="basketStore.basket.length === 0 && !loading"
          class="bg-gray-200 w-full flex-grow overflow-y-auto scrolling-touch text-center"
        >
          <div class="p-10">No basket items to display</div>
          <router-link :to="brandedStore.chooseProductURL" class="btn-primary btn-lg">
            Design a Product
          </router-link>
        </div>
        <main
          v-if="basketStore.basket.length > 0"
          class="vendor-basket-main bg-gray-200 w-full flex-grow sm:w-1/2 md:w-2/3 flex flex-col overflow-hidden"
        >
          <div class="px-5 md:px-10 mb-4 mt-4 md:mr-3">
            <div class="flex flex-col xs:flex-row gap-3">
              <router-link
                key="add_another"
                :to="brandedStore.chooseProductURL"
                class="btn-action text-xs py-3 px-3 md:px-10 w-full"
                data-index="0"
              >
                <span class="pr-3"><i class="fal fa-plus" /></span> Add Another Product
              </router-link>
              <router-link
                v-if="!hideVirtualHomeBanner"
                key="open-visualiser"
                :to="{ name: 'Design Online - Visualiser Setup' }"
                class="btn-action text-xs py-3 px-3 md:px-10 w-full"
                data-index="0"
              >
                <span class="pr-3"><i class="fal fa-house" /></span> Open Virtual Home
              </router-link>
            </div>
          </div>
          <div
            class="overflow-y-auto overflow-x-hidden scrolling-touch flex-grow"
            :class="{ 'md:overflow-y-hidden': showImage || showSummary }"
          >
            <animation-staggered-slide
              class="flex md:flex-col flex-wrap p-2 md:p-10 pt-0 md:pt-0 relative"
            >
              <basket-tile
                v-for="(item, index) in basketStore.basket"
                :key="item.key"
                :item="item"
                :data-index="index"
                :show-sizes="basketStore.contractHasSizing"
                :style="getTileStyle(item.key)"
                @show-summary="showSummaryPanel(item.key)"
                @show-image="showImagePanel(item.key)"
                @delete-line-item="deleteLineItem(item.key)"
              />
              <router-link
                v-if="!hideVirtualHomeBanner"
                key="open-visualiser"
                :to="{ name: 'Design Online - Visualiser Setup' }"
                class="rounded-lg cursor-pointer mt-6 sm:mt-10 hidden xs:flex"
                style="background-color: #242424"
              >
                <div>
                  <img :src="$t('/images/visualiser-banner-1.png')" class="rounded-lg">
                </div>
                <div class="hidden md:block">
                  <img :src="virtualHomeBannerUrl" class="rounded-lg">
                </div>
                <HelpPoint
                  key="help"
                  class="absolute right-0 bottom-0 mr-2 mb-2 z-10"
                  freshdesk-article-id="101000456233"
                />
              </router-link>
            </animation-staggered-slide>
          </div>
        </main>

        <transition name="fade-in" mode="out-in" @after-enter="afterFadeInBasketSidebar">
          <basket-image
            v-if="showImage && loadedItem"
            ref="basket-image"
            key="basket_image"
            :item="loadedItem"
            :show-sizes="basketStore.contractHasSizing"
            @close="hideImagePanel()"
          />
          <basket-summary
            v-else-if="showSummary && loadedItem"
            ref="basket-summary"
            key="basket_summary"
            :item="loadedItem"
            :show-sizes="basketStore.contractHasSizing"
            @close="hideSummaryPanel()"
          />
          <basket-pricing
            v-else-if="basketStore.basket.length > 0"
            key="basket_pricing"
            :show-save-product-button="false"
            :show-place-order-button="false"
            class="sm:block"
            :class="{ hidden: !showEnquiryForm }"
            @save-quote="saveQuote($event)"
            @close-form="showEnquiryForm = false"
          />
        </transition>
        <div
          class="sm:hidden absolute bottom-0 left-0 right-0 p-4 bg-white"
          :class="{ hidden: showEnquiryForm }"
        >
          <div class="btn-action btn-lg w-full" @click.stop.prevent="showEnquiryForm = true">
            Get a Quote
          </div>
        </div>
      </div>
    </transition>
  </touch-layout>
</template>

<script>

import { mapStores } from 'pinia'
import { useBrandedStore } from '@/pinia/branded';
import { useStyleStore } from '@/pinia/style';
import { useBasketStore } from '@/pinia/basket';
import { isIframe } from '@/composables/web';
import Tile from '@/components/branded/basket/Tile.vue';
import BasketPricing from '@/components/branded/basket/BasketPricing.vue';
import BasketSummary from '@/components/branded/basket/BasketSummary.vue';
import BasketImage from '@/components/branded/basket/BasketImage.vue';
import HelpPoint from '@/components/shared/HelpPoint.vue';
import OptionControlTestMode from '@/components/branded/OptionControlTestMode.vue';

export default {
  components: {
    'basket-tile': Tile,
    'basket-pricing': BasketPricing,
    'basket-summary': BasketSummary,
    'basket-image': BasketImage,
    HelpPoint,
    OptionControlTestMode
  },
  setup() {
    return { isIframe }
  },
  data() {
    return {
      showSummary: false,
      showImage: false,
      showEnquiryForm: true,
      itemIndex: undefined,
      loadedItem: undefined,
      saving_quote: false,
      loading: true,
      virtualHomeBannerUrl: '/images/visualiser-banner-2.png',
    };
  },
  computed: {
    ...mapStores(useBrandedStore, useStyleStore, useBasketStore),
    hideVirtualHomeBanner() {
      return (
        this.basketStore.basket.filter((x) => x.omitFromVisualiser).length === this.basketStore.basket.length
      );
    },
  },
  async mounted() {
    if (!this.basketStore.hasContract) {
      this.routerPush(this.brandedStore.launchURL);
      return
    }

    // hidden behind a click on mobile mode
    if (window.innerWidth <= 1024) {
      this.showEnquiryForm = false;
    }
    await this.basketStore.refresh();
    this.virtualHomeBannerUrl = this.styleStore.companyStyles?.virtualHomePageStyling?.bannerImageUri ?? this.virtualHomeBannerUrl;
    this.loading = false;
  },
  methods: {
    async saveQuote({
      firstName,
      lastName,
      email,
      phone,
      addressLine1,
      addressLine2,
      addressLine3,
      addressTown,
      addressCode,
      addressIso,
      notes,
      latitude,
      longitude,
    }) {
      this.saving_quote = true;
      await this.basketStore.saveBrandedEnquiry({
        firstName,
        lastName,
        email,
        phone,
        addressLine1,
        addressLine2,
        addressLine3,
        addressTown,
        addressCode,
        addressIso,
        notes,
        latitude,
        longitude,
      });
      const redirect = () => {
        window.gtag('event', 'enquiry-conversion');
        this.routerPush({ path: this.brandedStore.thanksURL, query: { optionControlTestMode: this.$route.query.optionControlTestMode } })
      }
      if (isIframe()) {
        const response = await window.touch.authGetCompanyWebSettings()
        if (response.companyWebSettings.some(x => x.key === 'iframeRedirect')) {
          const index = response.companyWebSettings.findIndex(x => x.key === 'iframeRedirect')
          const value = response.companyWebSettings[index].value
          if (value) {
            window.location.href = `https://${value}`
          } else {
            redirect()
          }
        } else {
          redirect()
        }
      } else {
        redirect()
      }
    },
    async showSummaryPanel(key) {
      if (this.itemIndex !== key) {
        this.showImage = false;
        this.showSummary = false;
        this.itemIndex = key;
        this.loadedItem = await this.basketStore.loadItem({
          itemKey: key,
        });
      }
      this.showSummary = true;
      this.showImage = false;
    },
    hideSummaryPanel() {
      this.showSummary = false;
    },
    async showImagePanel(key) {
      if (this.itemIndex !== key) {
        this.showImage = false;
        this.showSummary = false;
        this.itemIndex = key;
        this.loadedItem = await this.basketStore.loadItem({
          itemKey: key,
        });
      }
      this.showImage = true;
      this.showSummary = false;
    },
    hideImagePanel() {
      this.showImage = false;
    },
    getTileStyle(itemKey) {
      if (
        this.loadedItem &&
        (this.showImage || this.showSummary) &&
        itemKey !== this.loadedItem.itemKey
      ) {
        return {
          opacity: 0.15,
          pointerEvents: 'none',
        };
      }
      if (
        this.loadedItem &&
        (this.showImage || this.showSummary) &&
        itemKey === this.loadedItem.itemKey
      ) {
        return {
          pointerEvents: 'none',
        };
      }
      return {};
    },
    afterFadeInBasketSidebar() {
      if (this.showImage && this.loadedItem) {
        this.$refs['basket-image'].$el.focus();
      }
      if (this.showSummary && this.loadedItem) {
        this.$refs['basket-summary'].$el.focus();
      }
    },
    deleteLineItem(lineItemId) {
      this.alertBox()
        .fire({
          title: 'Are you sure you want to remove this item?',
          text: 'This action cannot be undone.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.basketStore.deleteLineItem({
              lineItemId,
            })
              .then(() => {
                this.alertBox().fire({
                  title: 'Item deleted',
                  icon: 'success',
                });
              });
          }
        });
    },
  },
};
</script>
