import { errorCallback, successCallback } from '@/composables/validate';

export async function connectGetConnectSubscriptions() {
  return this.handleApiRequest({ 
    method: 'connect/GetConnectSubscriptions',
    errorCallback,
  })
}

export async function connectGetConnectSources() {
  return this.handleApiRequest({ 
    method: 'connect/GetConnectSources',
    errorCallback,
  })
}

export async function connectUpdateConnectSource(id, type, datasetId ) {
  return this.handleApiRequest({ 
    method: 'connect/UpdateConnectSource',
    payload: {
      Id: id,
      Type: type,
      DatasetId: datasetId,
    },
    errorCallback,
    successCallback: (r) => successCallback(r, 'Save'),
  })
}

export async function connectUpdateConnectCustomerProductLinks(id, link) {
  return this.handleApiRequest({ 
    method: 'connect/UpdateConnectCustomerProductLinks',
    payload: {
      DatasetId: id,
      ProductLink: link,
    },
    errorCallback,
  })
}

export async function getConnectCodes() {
  return this.handleApiRequest({ 
    method: 'connect/GetConnectCodes',
    errorCallback,
  })
}

export async function createStockSubscription(Username, Password, Code) {
  return this.handleApiRequest({ 
    method: 'connect/CreateStockSubscription',
    payload: {
      Username,
      Password,
      Code,
    },
    errorCallback,
    successCallback: (r) => successCallback(r, 'Save'),
  })
}

export async function CreateConnectSubscription(Username, Password,Code, ConnectSourceType) {
  return this.handleApiRequest({ 
    method: 'connect/CreateConnectSubscription',
    payload: {
      Username,
      Password,
      Code,
      ConnectSourceType
    },
    errorCallback,
    successCallback: (r) => successCallback(r, 'Save'),
  })
}
