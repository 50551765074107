<template>
  <div class="flex flex-col flex-grow">
    <loading v-if="loading || saving" :loading="loading || saving" :label="saving ? 'Saving' : 'Loading'" style="flex: 1 1 0" />
    <div v-else class="flex h-screen overflow-hidden relative">
      <div
        v-if="basketItems.length === 0 && !loading"
        class="bg-gray-200 w-full flex-grow overflow-y-auto scrolling-touch text-center"
      >
        <div class="p-10">No basket items to display</div>
        <router-link
          v-if="basketStore.salesSectorType === enums.salesSectorType.RETAIL"
          :to="url + '/choose-product'"
          class="btn-primary btn-lg"
        >
          Design a Product
        </router-link>
        <router-link v-else :to="url + '/new-quote'" class="btn-primary btn-lg">
          Design a Product
        </router-link>
      </div>
      <main
        v-if="basketItems.length > 0"
        class="basket-main bg-gray-200 w-full flex-grow sm:w-1/2 md:w-2/3 flex flex-col overflow-hidden"
        :class="{ '_can-order': !basketStore.isEnquiry }"
      >
        <div class="px-5 md:px-10 mt-4">
          <div v-if="branded_mode_enabled" class="flex">
            <router-link
              key="add_another"
              to="/choose-product"
              class="btn-action text-xs py-3 px-3 md:px-10 w-full xs:mr-3"
              data-index="0"
            >
              <span class="pr-3"><i class="fal fa-plus" /></span> Add Another Product
            </router-link>
            <router-link
              v-if="basketMainItems.length > 0"
              key="open-visualiser"
              :to="{ name: 'Visualiser Setup', params: { id: customerId } }"
              class="btn-action text-xs py-3 px-3 md:px-10 w-full ml-3 hidden xs:block"
              data-index="0"
            >
              <span class="pr-3"><i class="fal fa-house" /></span> Open Virtual Home
            </router-link>
          </div>
          <router-link
            v-else
            key="add_another"
            :to="url + '/choose-product'"
            class="btn-action text-xs py-3 px-3 md:px-10 w-full"
            data-index="0"
          >
            <span class="pr-3"><i class="fal fa-plus" /></span> Add Another Product
          </router-link>
        </div>
        <div
          class="overflow-y-auto overflow-x-hidden scrolling-touch flex-grow"
          :class="{ 'md:overflow-y-hidden': showImage || showSummary || showExtras }"
        >
          <div class="px-5 md:px-10">
            <sizing-warning v-if="!basketStore.contractHasSizing" />
          </div>
          <animation-staggered-slide
            class="flex md:flex-col flex-wrap p-2 md:p-10 md:pt-0 relative"
          >
            <!--            <div class="text-lg m-2 md:m-0 md:mt-6" key="basket_products_title">-->
            <!--              Products-->
            <!--            </div>-->
            <basket-tile
              v-for="(item, index) in basketMainItems"
              :key="item.key"
              :item="item"
              :data-index="index"
              :style="getTileStyle(item.key)"
              :show-sizes="basketStore.contractHasSizing"
              :is-costs-hidden="isCostsHidden"
              :customer-id="customerId"
              :extras="extrasForItem(item.key)"
              @show-summary="showSummaryPanel(item.key)"
              @add-extras="showExtrasPanel(item.key)"
              @show-image="showImagePanel(item.key)"
              @delete-line-item="deleteLineItem(item.key)"
              @duplicate-line-item="duplicateLineItem(item.key)"
            >
              <template #pricing="{ basketCurrency, price, quantity }">
                <slot
                  name="pricing"
                  title="Item Price"
                  :item-key="item.itemKey"
                  :override="item.override"
                  :override-price="item.overridePrice"
                  :basket-currency="basketCurrency"
                  :price="price"
                  :quantity="quantity"
                />
              </template>
            </basket-tile>
            <stock-parts-basket-list
              v-if="stockPartsStore.cartItems.length > 0"
              key="basket_stock_parts_title"
            />

            <router-link
              v-if="!hideVirtualHomeBanner"
              key="open-visualiser"
              :to="{ name: 'Visualiser Setup', params: { id: customerId } }"
              class="rounded-lg cursor-pointer mt-6 sm:mt-10 hidden xs:flex"
              style="background-color: #242424"
            >
              <div>
                <img src="/images/visualiser-banner-1.png" class="rounded-lg">
              </div>
              <div class="hidden md:block">
                <img :src="virtualHomeBannerUrl" class="rounded-lg">
              </div>
              <HelpPoint
                key="help"
                class="absolute right-0 bottom-0 mr-2 mb-2 z-10"
                freshdesk-article-id="101000456233"
              />
            </router-link>
          </animation-staggered-slide>
        </div>
      </main>
      <transition name="fade-in" mode="out-in" @after-enter="afterFadeInBasketSidebar">
        <basket-image
          v-if="showImage && loadedItem"
          ref="basket-image"
          key="basket_image"
          :item="loadedItem"
          @close="hideImagePanel()"
        />
        <basket-summary
          v-else-if="showSummary && loadedItem"
          ref="basket-summary"
          key="basket_summary"
          :show-sizes="basketStore.contractHasSizing"
          :item="loadedItem"
          :extras="extrasForItem(loadedItem.key)"
          @close="hideSummaryPanel()"
        />
        <basket-extras
          v-else-if="showExtras"
          ref="basket-extras"
          key="basket_extras"
          :item-id="loadedItemId"
          :extras="basketExtraItems"
          @delete-line-item="deleteLineItem($event)"
          @close="hideExtrasPanel()"
        />
        <BasketInvisionAppLinks
          v-else-if="isBasketInvisionAppLinks"
          @close="isBasketInvisionAppLinks = false"
        />
        <basket-pricing
          v-else-if="basketItems.length > 0"
          key="basket_pricing"
          :extras="basketExtraItems"
          :customer="customer"
          :show-save-product-button="showSaveProductButton"
          :show-place-order-button="showPlaceOrderButton"
          :breakdown="breakdown"
          :basket-main-items="basketMainItems"
          :is-costs-hidden="isCostsHidden"
          :customer-id="customerId"
          @save-order="saveOrder($event)"
          @add-extras="addExtrasToQuote"
          @save-quote="saveQuote()"
          @show-basket-invision-app-links="isBasketInvisionAppLinks = true"
        >
          <template #extras>
            <slot name="extras" />
          </template>
          <template #pricing="{ basketCurrency, price }">
            <slot
              name="pricing"
              title="Job Price"
              :override="basketStore?.job?.overrideCalculatedPrice"
              :override-price="basketStore?.job?.overridePriceTaxable"
              :basket-currency="basketCurrency"
              :price="price"
            />
          </template>
        </basket-pricing>
      </transition>
    </div>
  </div>
</template>

<script>

import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { useCustomerStore } from '@/pinia/customer';
import { useStockPartsStore } from '@/pinia/stock-parts';
import { useStyleStore } from '@/pinia/style';
import { useBasketStore } from '@/pinia/basket';
import { useDesignerStore } from '@/pinia/designer';
import StockPartsBasketList from '@/components/shared/basket/StockPartsBasketList.vue';
import HelpPoint from '@/components/shared/HelpPoint.vue';
import Tile from '@/components/shared/basket/Tile.vue';
import BasketPricing from '@/components/shared/basket/BasketPricing.vue';
import BasketSummary from '@/components/shared/basket/BasketSummary.vue';
import BasketInvisionAppLinks from '@/components/shared/basket/BasketInvisionAppLinks.vue';
import BasketImage from '@/components/shared/basket/BasketImage.vue';
import BasketExtras from '@/components/shared/basket/BasketExtras.vue';
import SizingWarning from '@/components/shared/basket/SizingWarning.vue';

export default {
  components: {
    'basket-tile': Tile,
    'basket-pricing': BasketPricing,
    'basket-summary': BasketSummary,
    'basket-image': BasketImage,
    'basket-extras': BasketExtras,
    'sizing-warning': SizingWarning,
    'stock-parts-basket-list': StockPartsBasketList,
    BasketInvisionAppLinks,
    HelpPoint,
  },
  props: {
    customer: Object,
    customerId: Number,
    canSkipSop: Boolean,
    showSaveProductButton: Boolean,
    showPlaceOrderButton: Boolean,
    url: String,
    breakdown: String,
    isCostsHidden: Boolean,
    hideVirtualHomeBanner: Boolean,
    basketItems: Array,
    basketMainItems: Array
  },
  data() {
    return {
      showSummary: false,
      showImage: false,
      showExtras: false,
      itemIndex: undefined,
      loadedItem: undefined,
      saving: false,
      isBasketInvisionAppLinks: false,
      loading: true,
      virtualHomeBannerUrl: '/images/visualiser-banner-2.png',
      availableBranches: []
    };
  },
  computed: {
    ...mapStores(useAuthStore, useCustomerStore, useStockPartsStore, useStyleStore, useBasketStore, useDesignerStore),
    prohibitedIssues() {
      return this.basketItems.filter((item) =>
        item.issues
          ? item.issues.filter(
            (issue) =>
              issue.severityType === window.enum.IssueSeverityEnumLabel.PROHIBIT ||
              issue.severityType === window.enum.IssueSeverityEnumLabel.RESTRICT,
          ).length
          : 0,
      );
    },
    loadedItemId() {
      return this.loadedItem ? this.loadedItem.itemKey : 0;
    },
    basketExtraItems() {
      return this.basketItems.filter((item) => item.inputType === window.enum.inputType.CUSTOMER);
    },
  },
  async mounted() {
    this.loading = true;
    if (this.authStore.isBranchManagerAvailable) {
      try {
        let availableBranches = await window.touch.AvailableBranchesForContract({ ContractId: this.basketStore.contractIdentifier.contractId, CustomerId: this.customer?.customerId })
        this.$emit('setAvailableBranches', availableBranches.map(x => ({
          id: x.id,
          name: x.name
        })))
      } catch (error) {
        // Empty
      }
    }
    if (!this.basketStore.hasContract) {
      this.basketStore.setButtonName('Quote');
      this.routerPush(`${this.url}/new-quote`);
    } else {
      try {
        if (this.basketStore.selected_address_id) {
          await window.touch.processingUpdateJobDelivery(
            this.basketStore.customerId,
            this.basketStore.contractIdentifier.contractId,
            this.basketStore.contractIdentifier.jobKey,
            { AddressId: parseInt(this.basketStore.selected_address_id, 10) }
          );
        }
        await this.basketStore.refresh(true);
      } catch (error) {
        if (error.status === 400) {
          this.alertBox().fire(error.popUp);
        } else {
          throw error
        }
      }
      this.loading = false;
    }
    this.virtualHomeBannerUrl =
      (await this.styleStore.getCompanyStyles())?.virtualHomePageStyling?.bannerImageUri ??
      this.virtualHomeBannerUrl;
    this.loading = false;
  },
  beforeUnmount() {
    this.basketStore.contractData = undefined
  },
  methods: {
    tableOfDefaults(item) {
      const prefix = '<ul class="pt-3">';
      const suffix = '</ul>';

      const rows = item.specification
        .filter((spec) => spec.mustBeSelected && !spec.hasBeenSelected)
        .map((spec) => `<li>${spec.name}</li>`)
        .join('');

      return `${prefix}${rows}${suffix}`;
    },
    extrasForItem(itemKey) {
      return this.basketExtraItems.filter((extra) => extra.parentKey === itemKey);
    },
    addExtrasToQuote() {
      this.itemIndex = undefined;
      this.loadedItem = undefined;
      this.showExtras = true;
    },
    readyToBeProcessed() {
      let foundItem = this.basketMainItems.find((item) => item.invalidSpec);
      if (foundItem) {
        this.routerPush(`${this.url}/design/${foundItem.key}?isInvalidSpec=true`);
        return false;
      }

      foundItem = this.basketMainItems.find((item) => item.invalidDimensions);
      if (foundItem) {
        this.routerPush(`${this.url}/design/${foundItem.key}?tab=measurements`, () => {
          this.alertBox().fire({
            title: 'You are required to add dimensions',
            didDestroy: () => {
              setTimeout(() => {
                this.designerStore.SET_OPEN_MENU_TYPE('specification');
                setTimeout(() => {
                  this.designerStore.SET_OPEN_MENU_TYPE('sizes');
                }, 5);
              }, 5);
            },
          });
        });
        return false;
      }

      return true;
    },
    async saveQuote() {
      if (!this.readyToBeProcessed()) {
        return;
      }
      if (this.prohibitedIssues.length) {
        this.showIssuesModal();
        return;
      }
      this.saving = true;
      this.$emit('saveQuote', () => {
        this.saving = false;
      });
    },
    async placeOrderProcess(bypassSOP) {
      this.saving = true;
      this.$emit('placeOrderProcess', {
        canSkipSop: this.canSkipSop,
        bypassSOP,
        callback: () => {
          this.saving = false;
        }
      });
    },
    saveOrder(bypassSOP) {
      if (!this.readyToBeProcessed()) {
        return;
      }
      if (this.prohibitedIssues.length) {
        this.showIssuesModal();
        return;
      }
      if (bypassSOP && this.canSkipSop) {
        this.placeOrderProcess(bypassSOP);
      } else {
        this.alertBox()
          .fire({
            title: 'Order on hold',
            text:
              this.customerStore.customer.financial.paymentWithOrder &&
                this.basketStore.contractData.paymentStatus === this.enums.PaymentStatus.AWAITING
                ? 'To formally place the order use the Confirm Customer Order button in the order page and pay for the order at checkout.'
                : 'To formally place the order use the Confirm Customer Order button in the order page.',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Ok',
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.placeOrderProcess();
            }
          });
      }
    },
    showIssuesModal() {
      this.alertBox().fire({
        title: 'There are issues with some of your items',
        text: 'Please review your items with issues before continuing.',
        icon: 'warning',
      });
    },
    async showSummaryPanel(index) {
      if (this.itemIndex !== index) {
        this.showImage = false;
        this.showSummary = false;
        this.showExtras = false;
        this.itemIndex = index;
        this.loadedItem = await this.basketStore.loadItem({
          itemKey: index,
          customerID: this.customerId,
        });
      }
      this.showSummary = true;
      this.showExtras = false;
      this.showImage = false;
    },
    async showExtrasPanel(index) {
      if (this.itemIndex !== index) {
        this.showExtras = false;
        this.showSummary = false;
        this.showImage = false;
        this.itemIndex = index;
        this.loadedItem = await this.basketStore.loadItem({
          itemKey: index,
          customerID: this.customerId,
        });
      }
      this.showExtras = true;
      this.showSummary = false;
      this.showImage = false;
    },
    hideSummaryPanel() {
      this.showSummary = false;
    },
    async showImagePanel(index) {
      if (this.itemIndex !== index) {
        this.showImage = false;
        this.showSummary = false;
        this.showExtras = false;
        this.itemIndex = index;
        this.loadedItem = await this.basketStore.loadItem({
          itemKey: index,
          customerID: this.customerId,
        });
      }
      this.showImage = true;
      this.showExtras = false;
      this.showSummary = false;
    },
    hideImagePanel() {
      this.showImage = false;
    },
    hideExtrasPanel() {
      this.showExtras = false;
    },
    getTileStyle(itemKey) {
      if (
        this.loadedItem &&
        (this.showImage || this.showSummary) &&
        itemKey !== this.loadedItem.itemKey
      ) {
        return {
          opacity: 0.15,
          pointerEvents: 'none',
        };
      }
      if (
        this.loadedItem &&
        (this.showImage || this.showSummary) &&
        itemKey === this.loadedItem.itemKey
      ) {
        return {
          pointerEvents: 'none',
        };
      }
      return {};
    },
    afterFadeInBasketSidebar() {
      if (this.showImage) {
        this.$refs['basket-image'].$el.focus();
      }
      if (this.showSummary) {
        this.$refs['basket-summary'].$el.focus();
      }
      if (this.showExtras) {
        this.$refs['basket-extras'].$el.focus();
      }
    },
    deleteLineItem(lineItemId) {
      this.alertBox()
        .fire({
          title: 'Are you sure you want to remove this item?',
          text: 'This action cannot be undone.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.basketStore.deleteLineItem({
              lineItemId,
            })
              .then(() => {
                this.alertBox().fire({
                  title: 'Item deleted',
                  icon: 'success',
                });
              });
          }
        });
    },
    duplicateLineItem(lineItemId) {
      this.basketStore.duplicateLineItem({
        lineItemId,
      });
    },
  },
};
</script>
