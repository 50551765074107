<template>
  <touch-layout class="bg-gray-200">
    <main class="home-custom w-full overflow-y-auto">
      <Summary :is-bn-q="isBnQ" />
      <div class="md:grid grid-cols-3 gap-5 p-5">
        <div class="col-span-2">
          <Welcome
            :first-name="authStore.user.firstName"
            :company-name="authStore.user.companyName"
            :is-bn-q="isBnQ"
            @add-customer-pop-up="add_customer_box_open = true"
          />
          <Enquiries />
        </div>
        <div v-if="!isBnQ" class="flex flex-col xs:flex-row mt-5 md:mt-0 md:flex-col gap-5">
          <Support />
          <Hero />
        </div>
        <div v-else class="xs:flex mt-5 md:mt-0 md:flex-col gap-5 md:w-1/3">
          <News :company-name="authStore.user.companyName" :news-items="newsItems" class="w-full" />
        </div>
      </div>
      <Teleport v-if="add_customer_box_open" defer to="#portal_popup">
        <modal-window
          :modal_open="add_customer_box_open"
          title="Add Customer"
          @close="add_customer_box_open = false"
        >
          <customer-create @close="add_customer_box_open = false" />
        </modal-window>
      </Teleport>
    </main>
  </touch-layout>
</template>

<script>

import { mapStores } from 'pinia'
import { useAuthStore } from '@/pinia/auth';
import { useStyleStore } from '@/pinia/style';
import Summary from '@/components/business/home/Summary.vue';
import Welcome from '@/components/business/home/Welcome.vue';
import Enquiries from '@/components/business/home/Enquiries.vue';
import Support from '@/components/business/home/Support.vue';
import Hero from '@/components/business/home/Hero.vue';
import CustomerCreate from '@/components/shared/CustomerCreate.vue';
import News from '@/components/portal/home/News.vue';

export default {
  name: 'Home',
  components: {
    Summary,
    Welcome,
    CustomerCreate,
    Enquiries,
    Support,
    Hero,
    News
  },
  data() {
    return {
      active_news_slide: 'news',
      quotes_and_enquiries_tab: 'enquiries',
      news_widget_hovered: false,
      news_interval: null,
      add_customer_box_open: false,
      add_staff_box_open: false,
      instagram_connected: false,
      feeds_available: ['news'],
      newsItems: []
    };
  },
  computed: {
    ...mapStores(useAuthStore, useStyleStore),
    show_news_link() {
      return this.feeds_available.includes('news');
    },
    isBnQ() {
      return this.styleStore.fabricator.name === 'B & Q'
    },
  },
  watch: {
    news_widget_hovered(hovered) {
      if (hovered) {
        clearInterval(this.news_interval);
      } else if (this.feeds_available.length > 1) {
        this.slideNews();
      }
    },
  },
  async mounted() {
    if (this.isBnQ) {
      const { news_items } = await window.touch.latestNews({ limit: 3, offset: 0 });
      this.newsItems = news_items
    }

    this.user_img_url = window.touch.imageBaseURL + this.authStore.user.image;

    if (window.location.hostname.split('.')[0] === 'demo') {
      await window.touch.checkInstagramAuth({
        fabricatorName: this.styleStore.fabricator_name,
      })
        .then(() => {
          this.instagram_connected = true;
          this.feeds_available.push('instagram');
        })
        .catch(() => {
          this.instagram_connected = false;
        });
    }


    this.active_news_slide = this.feeds_available[0];

    if (this.feeds_available.length > 1) {
      this.slideNews();
    }
  },
  methods: {
    newStaffMemberCreated() {
      this.add_staff_box_open = false;
      this.alertBox().fire('New Staff User Created');
    },
    slideNews() {
      const vueThis = this;
      this.news_interval = setInterval(() => {
        const currentNewsIndex = vueThis.feeds_available.indexOf(vueThis.active_news_slide);
        if (currentNewsIndex + 1 === vueThis.feeds_available.length) {

          vueThis.active_news_slide = vueThis.feeds_available[0];
        } else {
          vueThis.active_news_slide = vueThis.feeds_available[currentNewsIndex + 1];
        }
      }, 5000);
    },
  },
};
</script>

<style scoped>
@media (min-width: 1280px) {
  .home-custom {
    width: calc(100vw - 250px);
  }
}
</style>

