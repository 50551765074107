import { errorCallback } from '@/composables/validate';

export async function listRackTypes() {
  return this.handleApiRequest({ 
    method: 'rack/ListTypes',
    errorCallback
  })
}

export async function createRackType(name) {
  await this.handleApiRequest({ 
    method: 'rack/CreateType',
    payload: {
      Name: name
    },
    errorCallback
  })
}

export async function updateRackType(id, name) {
  await this.handleApiRequest({ 
    method: 'rack/UpdateType',
    payload: {
      Id: id,
      Name: name
    },
    errorCallback
  })
}

export async function getRackList() {
  return this.handleApiRequest({ 
    method: 'rack/List',
    errorCallback
  })
}
