<template>
  <touch-layout class="h-full">
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Customer Management', '/customer'],
          [`Customer (${customer.company.name})`, `/customer/${customer.customerId}`],
          ['Stock Parts'],
        ]"
      />
    </template>
    <template #header_page_title>
      Stock Parts
    </template>
    <Teleport defer to="#portal_search">
      <SearchBox class="flex" placeholder="Search for an code/description..." />
    </Teleport>
    <template #header_page_actions>
      <strong
        v-if="search.length < stockPartsStore.minimumQueryLength && search.length > 0"
        class="absolute text-red-400 mr-2"
        :style="{ right: '3.25rem' }"
      >
        Minimum {{ stockPartsStore.minimumQueryLength }} characters.
      </strong>
    </template>
    <Menu />
    <StockParts :query="search" :minimum-query-length="stockPartsStore.minimumQueryLength" />
  </touch-layout>
</template>

<script>

import { mapStores } from 'pinia'
import { useStockPartsStore } from '@/pinia/stock-parts';
import StockParts from '@/components/shared/StockParts.vue';
import Menu from '@/components/business/customer/Menu.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import SearchBox from '@/components/shared/SearchBox.vue';

export default {
  components: {
    StockParts,
    Menu,
    Breadcrumbs,
    SearchBox,
  },
  props: {
    customer: Object,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapStores(useStockPartsStore),
    search() {
      return this.$route.query.search || '';
    },
  },
  created() {
    this.$router.replace({ query: undefined }).catch(() => { });
    setTimeout(() => {
      this.$watch('$route.query', this.setQuery);
    }, 0);
  },
  async mounted() {
    this.loading = false;
  },
  methods: {
    async setQuery() {
      await this.stockPartsStore.runItemQuery(this.search)
      if (this.search.length < this.stockPartsStore.minimumQueryLength) {
        await this.stockPartsStore.setSearchQuery('')
        await this.stockPartsStore.setQueriedStockParts({
          query: this.search,
          results: [],
        });
      }
    },
  },
};
</script>
