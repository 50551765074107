<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Quotes', '/quotes'],
        ]"
      />
    </template>
    <template #header_page_title> Search Quotes </template>
    <Teleport defer to="#portal_search">
      <SearchBox class="flex" placeholder="Search for reference..." />
    </Teleport>
    <template #header_page_actions>
      <div class="pr-3 md:px-10 flex items-center">
        <router-link to="/new-quote" class="btn-action"> New Quote </router-link>
        <info-popup
          :id="'quotes:new_quote'"
          class="_pop-out-left"
          :info_message="'Click here to create a new quote at any time.'"
          :next_id="'quotes:view_quote'"
        />
      </div>
    </template>
    <loading
      :loading="loading"
      class="bg-gray-200 w-full h-full overflow-y-auto scrolling-touch p-3 md:p-10"
    >
      <div class="flex flex-wrap-reverse 2xs:flex-nowrap">
        <div
          v-for="tab in tabs"
          :key="tab.tabType"
          class="py-2 px-4 border border-b-0 cursor-pointer"
          :class="{
            'bg-white': selectedTab === tab.tabType,
            'border-gray-400 bg-gray-300 text-gray-600': selectedTab !== tab.tabType,
          }"
          @click="selectedTab = tab.tabType"
        >
          {{ tab.name }}
        </div>
      </div>
      <div
        v-if="quotes.length > 0"
        key="quotes_list"
        class="bg-white p-3 md:p-10 pb-6 border"
        data-index="1"
      >
        <table class="table-lined xs:table-unstacked _with-hover w-full">
          <thead>
            <tr>
              <th class="w-10">#</th>
              <th>Date Created</th>
              <th>Reference</th>
              <th>
                Net Cost
                <info-popup
                  :id="'quotes:pricebook'"
                  :info_message="'This will be created using your initial pricing setup.'"
                  :next_id="'quotes:requoted_price'"
                />
              </th>
              <th>
                Requoted Price
                <info-popup
                  :id="'quotes:requoted_price'"
                  :info_message="'This price will show any uplift added using the quote my customer feature, showing the last price quoted to the customer.'"
                  :next_id="'quotes:new_quote'"
                />
              </th>
              <th class="w-16">
                <button class="btn-action" @click.stop="loadFeed">Reload</button>
                <info-popup
                  :id="'quotes:view_quote'"
                  class="_pop-out-left"
                  :info_message="'As you create quotes using the sales and survey designer they will land here, click a quote for more details of the customers quote.'"
                  :next_id="'quotes:status'"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(quote, index) in quotes"
              :key="index"
              class="cursor-pointer pointer-events-none"
              :class="{
                'pointer-events-auto': [
                  enums.orderStatusSaveStates.NOT_APPLICABLE,
                  enums.orderStatusSaveStates.COMPLETED,
                ].includes(quote.saveState),
                'fadeInAndOut': quote.saveState === enums.orderStatusSaveStates.STARTED
              }"
              @click="$router.push(quote.url)"
            >
              <td>
                <div class="td-title w-32">Number:</div>
                <div class="td-content">{{ quote.customerCounter }}</div>
              </td>
              <td>
                <div class="td-title w-32">Created:</div>
                <div class="td-content">{{ formatDate(quote.date) }}</div>
              </td>
              <td>
                <div class="td-title w-32">Reference:</div>
                <div class="td-content truncate w-full">{{ quote.reference }}</div>
              </td>
              <td v-if="quote.saveState === enums.orderStatusSaveStates.FAILED">
                <div class="td-content font-bold text-red-500">
                  Sorry, problem saving this job. Please re-input
                </div>
              </td>
              <td v-else-if="quote.jobs.length > 0">
                <div class="td-title w-32">Net Cost:</div>
                <div
                  class="td-content"
                  v-html="formatCurrency(
                    quote.customerCurrencyCode,
                    quote.jobs[0].priceBeforeTax,
                  )"
                />
              </td>
              <td v-else>
                <div class="td-title w-32">Net Cost:</div>
                <div class="td-content">-</div>
              </td>
              <td v-if="quote.jobs.length > 0">
                <div class="td-title w-32">Requoted Price:</div>
                <div
                  class="td-content"
                  v-html="formatCurrency(
                    quote.customerCurrencyCode,
                    quote.jobs[0].requotePriceIncludingTax,
                  )"
                />
              </td>
              <td v-else>
                <div class="td-title w-32">Requoted Price:</div>
                <div class="td-content">-</div>
              </td>
              <td class="td-action-buttons">
                <div class="table-edit-btn">
                  <i class="fa fa-pencil" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else key="none-found" class="bg-white p-3 md:p-10 pb-6 border">
        <div v-if="$route.query.search" class="flex justify-between">
          <div>No Quotes match "{{ $route.query.search }}"</div>
          <div>
            <a class="btn-action" @click="$router.push({ query: undefined })"> Clear Search </a>
          </div>
        </div>
        <div v-else>No Quotes to show</div>
      </div>
      <pagination :total_items="total_items" :limit="limit" class="mt-3 pb-10" @load-feed="loadFeed" />
    </loading>
  </touch-layout>
</template>

<script>

import { mapStores } from 'pinia'
import { useTouchStore } from '@/pinia/touch';
import { formatCurrency } from '@/composables/currency';
import { formatDate } from '@/composables/date';
import Pagination from '@/components/shared/Pagination.vue';
import { useContractPoller } from '@/composables/pollContractStates';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import SearchBox from '@/components/shared/SearchBox.vue';

class Tab {
  constructor(name, tabType) {
    this.name = name;
    this.tabType = tabType;
  }
}

const TabTypeEnum = {
  ALL_QUOTES: 1,
  LOCKED_QUOTES: 2,
};

export default {
  components: {
    Breadcrumbs,
    pagination: Pagination,
    SearchBox,
  },
  setup() {
    return { formatCurrency, formatDate }
  },
  data() {
    return {
      quotes: [],
      loading: true,
      limit: 10,
      total_items: 0,
      selectedTab: 1,
      tabs: [
        new Tab('All Quotes', TabTypeEnum.ALL_QUOTES),
        new Tab('Locked Quotes', TabTypeEnum.LOCKED_QUOTES),
      ],
      contractsBeingProcessed: [],
    };
  },
  computed: {
    ...mapStores(useTouchStore),
  },
  watch: {
    async selectedTab() {
      this.loading = true;
      await this.loadFeed();
      this.loading = false;
    },
    '$route.query': {
      async handler() {
        await this.loadFeed();
        const { pollContractStates } = useContractPoller();
        pollContractStates(window.touch.CONTRACT_TYPE_QUOTE, this.quotes);
      },
      immediate: true,
    },
  },
  methods: {
    showReleaseToAdminButton(quote) {
      return quote.saveState !== this.enums.orderStatusSaveStates.STARTED;
    },
    jobOverridePriceWithTax(job) {
      const fittingPrice = job.overrideCalculatedFittingPrice
        ? job.overrideFittingPriceTaxable * (1 + job.taxRate) + job.overrideFittingPriceTaxExempt
        : job.calculatedFittingPriceTaxable * (1 + job.taxRate) +
        job.calculatedFittingPriceTaxExempt;
      const price = job.overrideCalculatedFittingPrice
        ? job.overridePriceTaxable * (1 + job.taxRate) + job.overridePriceTaxExempt
        : job.calculatedPriceTaxable * (1 + job.taxRate) + job.calculatedPriceTaxExempt;

      return fittingPrice + price;
    },
    async loadFeed(offset = 0) {
      this.loading = true;
      const response = await this.touchStore.loadQuotes({
        limit: this.limit,
        offset,
        onlyShowLocked: this.selectedTab === TabTypeEnum.LOCKED_QUOTES,
        search: this.$route.query.search,
      });
      this.quotes = response.quotes;
      this.total_items = response.total_items;
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.fadeInAndOut {
  opacity: 1;
  animation: fade 2s linear infinite;
}

@keyframes fade {

  0%,
  100% {
    opacity: 0.2
  }

  50% {
    opacity: 1
  }
}
</style>