import { errorCallback, successCallback } from '@/composables/validate';

export async function contractSendTradeCustomerQuoteReadyEmail(contractId, jobKey) {
  return this.handleApiRequest({ 
    method: 'contract/SendTradeCustomerQuoteReadyEmail',
    payload: {
      contractId,
      jobKey
    },
    errorCallback,
  })
}

export async function contractList(type, limit, offset, searchForReference, statusId, customerId, enquirySource, onlyShowLocked) {
  const options = {
    type,
    limit: parseInt(limit, 10),
    offset: parseInt(offset, 10),
    searchForReference,
    GetTotal: true,
    CustomerId: customerId,
    EnquirySource: enquirySource,
    OnlyShowLocked: onlyShowLocked,
  };

  if (type === window.touch.CONTRACT_TYPE_QUOTE && statusId !== '') {
    options.QuoteStatus = Number(statusId);
  }

  if (type === window.touch.CONTRACT_TYPE_ORDER && statusId !== '') {
    options.OrderStatus = Number(statusId);
  }

  if (type === window.touch.CONTRACT_TYPE_ENQUIRY && statusId !== '') {
    options.EnquiryStatus = Number(statusId);
  }

  return this.handleApiRequest({ 
    method: 'contract/List',
    payload: options,
    errorCallback,
  })
}

export async function contractSummary({ 
  type, limit, offset, searchForReference, statusId, customerId, enquirySource, onlyShowLocked, 
  enquiryStatus, IncludeItems, customQuoteStageId, fromDate, toDate, branchCompanyId
}) {
  const options = {
    type,
    limit: parseInt(limit, 10),
    offset: parseInt(offset, 10),
    searchForReference,
    GetTotal: true,
    CustomerId: customerId,
    CustomQuoteStageId: customQuoteStageId,
    EnquirySource: enquirySource,
    OnlyShowLocked: onlyShowLocked,
    enquiryStatus,
    IncludeItems,
    FromDate: fromDate,
    ToDate: toDate,
    BranchCompanyId: branchCompanyId
  };

  if (type === window.touch.CONTRACT_TYPE_QUOTE && statusId) {
    options.QuoteStatus = Number(statusId);
  }

  if (type === window.touch.CONTRACT_TYPE_ORDER && statusId) {
    options.OrderStatus = Number(statusId);
  }

  if (type === window.touch.CONTRACT_TYPE_ENQUIRY && statusId) {
    if (Array.isArray(statusId)) {
      options.EnquiryStatus = statusId;
    } else {
      options.EnquiryStatus = [Number(statusId)];
    }
  }

  return this.handleApiRequest({ 
    method: 'contract/Summary',
    payload: options,
    errorCallback,
  })
}

export async function ListSaveStates({type, customerId, OnlyTheseContracts }) {
  return this.handleApiRequest({ 
    method: 'contract/ListSaveStates',
    payload: {
      type,
      customerId,
      OnlyTheseContracts,
    },
    errorCallback,
  })
}

export async function contractConvertToOrder(contractId, jobIds, customerId = undefined) {
  return this.handleApiRequest({ 
    method: 'contract/ConvertToOrder',
    payload: {
      ContractId: contractId,
      JobKeys: jobIds,
      CustomerId: customerId,
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data.contractId, 'Save'),
  })
}

export async function contractPlaceTradeOrder(contractId) {
  return this.handleApiRequest({ 
    method: 'contract/PlaceTradeOrder',
    payload: {
      ContractId: contractId,
    },
    errorCallback,
    successCallback: r => r.data.contractId,
  })
}

export async function contractConvertToQuote(contractId, jobIds) {
  const response = await this.handleApiRequest({ 
    method: 'contract/ConvertToQuote',
    payload: {
      ContractId: Number(contractId),
      JobKeys: jobIds.map((jobId) => Number(jobId)),
    },
    errorCallback
  })
  return response.contractId;
}

export async function contractListJobs(contractId, jobKey, processingLevel, includeItems = true) {
  const { jobs } = await this.handleApiRequest({ 
    method: 'contract/ListJobs',
    payload: {
      ContractId: parseInt(contractId, 10),
    },
    errorCallback
  })

  return Promise.all(
    jobs.map(async (job) => {
      if (jobKey !== undefined && job.jobKey !== jobKey) {
        return {
          ...job,
          items: [],
        };
      }

      const fullJob = await this.contractJobDetails(contractId, job.jobKey);

      if (!includeItems) {
        return fullJob;
      }

      const formattedItems = await Promise.all(
        fullJob.items.map(async (item) => {
          const fullItem = await window.touch.contractJobLineItemDetails(
            contractId,
            job.jobKey,
            item.key,
            processingLevel,
            fullJob.exchangeRate,
          );

          return {
            ...item,
            ...fullItem,
          };
        }),
      );

      return {
        ...job,
        ...fullJob,
        items: formattedItems,
      };
    }),
  );
}

export async function contractUpdateNote(params) {
  return this.handleApiRequest({ 
    method: 'contract/UpdateNote',
    payload: {
      ...params,
    },
    errorCallback,
    successCallback: (r) => successCallback(r, 'Save')
  })
}

export async function contractGetNotes(params) {
  return this.handleApiRequest({ 
    method: 'contract/GetNotes',
    payload: {
      ...params,
      ContractId: Number(params.ContractId),
    },
    errorCallback
  })
}

export async function contractJobDetails(contractId, jobKey) {
  const response = await this.handleApiRequest({ 
    method: 'contract/JobDetails',
    payload: {
      ContractId: parseInt(contractId, 10),
      JobKey: parseInt(jobKey, 10),
    },
    errorCallback
  })

  if (response.exchangeRate === null || response.exchangeRate === 0) {
    response.exchangeRate = 1;
  }

  return response;
}

export async function contractDetails(customerId, contractId, jobId = 1,) {
  const params = {
    ContractIds: [Number(contractId)],
  };

  if (window.VITE_APP_INSTALLATION_TYPE === 'business') {
    params.CustomerId = customerId;
  }
  const contract = await this.handleApiRequest({ 
    method: 'contract/Details',
    payload: params,
    errorCallback
  })
  const fullJob = await this.contractJobDetails(contractId, jobId);
  fullJob.currency = contract[0].currencyCode
  fullJob.customerCurrency = contract[0].customerCurrencyCode
  return {
    ...contract[0],
    jobs: [fullJob]
  };
}

export async function contractJobLineItemDetails(contractId, jobKey, itemKey, processingLevel, exchangeRate) {
  const response = await this.handleApiRequest({ 
    method: 'contract/JobLineItemDetails',
    payload: {
      ContractId: parseInt(contractId, 10),
      JobKey: jobKey,
      ItemKey: parseInt(itemKey, 10),
      ProcessingLevel: processingLevel,
    },
    errorCallback
  })

  response.calculatedPrice *= exchangeRate;
  response.price *= exchangeRate;

  return response;
}

export async function contractUpdateJobRequote({ contractId, jobKey, emailSubject, emailBodyText, requotePriceBeforeTax }) {
  return this.handleApiRequest({ 
    method: 'contract/UpdateJobRequote',
    payload: {
      ContractId: contractId,
      JobKey: jobKey,
      EmailSubject: emailSubject,
      EmailBodyText: emailBodyText,
      RequotePriceBeforeTax: requotePriceBeforeTax,
    },
    errorCallback,
    successCallback: (r) => successCallback(r, 'Save')
  })
}


export async function contractUpdateVisibility(contractId, { visibleToAdmin, visibleToPortal }) {
  return this.handleApiRequest({ 
    method: 'contract/UpdateVisibility',
    payload: {
      ContractId: contractId,
      VisibleToPortal: visibleToPortal,
      VisibleToAdmin: visibleToAdmin,
    },
    errorCallback,
    successCallback: r => r
  })
}

export async function cancelOrder(contractId) {
  return this.handleApiRequest({ 
    method: 'contract/CancelOrder',
    payload: {
      ContractId: contractId,
    },
    errorCallback,
    successCallback: r => r
  })
}

export async function contractUpdateConsumer({ 
  contractId, customerId, salutation, firstName, lastName, email, mobile, addressLine1,
  addressLine2, addressLine3, addressTown, addressCounty, addressCode, addressIso,
}) {
  return this.handleApiRequest({ 
    method: 'contract/UpdateConsumer',
    payload: {
      ContractId: contractId,
      CustomerId: customerId,
      Salutation: salutation,
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      Mobile: mobile,
      AddressLine1: addressLine1,
      AddressLine2: addressLine2,
      AddressLine3: addressLine3,
      AddressTown: addressTown,
      AddressCounty: addressCounty,
      AddressCode: addressCode,
      AddressIso: addressIso,
    },
    errorCallback,
    successCallback: (r) => successCallback(r, 'Save'),
  })
}

export async function AssignCustomQuoteStage(ContractId, CustomerId, CustomQuoteStageId) {
  return this.handleApiRequest({ 
    method: 'contract/AssignCustomQuoteStage',
    payload: {
      ContractId,
      CustomerId,
      CustomQuoteStageId,
    },
    errorCallback,
    successCallback: (r) => successCallback(r, 'Save'),
  })
}

export async function contractAddStaffToContract(ContractId, JobKey, Role, StaffId) {
  return this.handleApiRequest({ 
    method: 'contract/AddStaffToContract',
    payload: {
      ContractId,
      JobKey,
      Role,
      StaffId,
    },
    errorCallback,
    successCallback: (r) => successCallback(r, 'Save'),
  })
}

export async function GetTotalOrdersPlaced() {
  return this.handleApiRequest({ 
    method: 'contract/GetTotalOrdersPlaced',
    errorCallback,
  })
}

export async function GetTotalDealerEnquiries() {
  return this.handleApiRequest({ 
    method: 'contract/GetTotalDealerEnquiries',
    errorCallback,
  })
}

export async function GetTotalQuotesCreated() {
  return this.handleApiRequest({ 
    method: 'contract/GetTotalQuotesCreated',
    errorCallback,
  })
}

export async function GetDownloadHistory(contractId) {
  return this.handleApiRequest({ 
    method: 'contract/GetDownloadHistory',
    payload: { contractId },
    errorCallback,
  })
}
