import Cookies from 'js-cookie';
import { momentDate } from '@/composables/date';
import axios from 'axios';

import * as auth from './touch-auth';
import * as news from './touch-news';
import * as processing from './touch-processing';
import * as contract from './touch-contract';
import * as report from './touch-report';
import * as common from './touch-common';
import * as staff from './touch-staff';
import * as customer from './touch-customer';
import * as marketing from './touch-marketing';
import * as kpi from './touch-kpi';
import * as branded from './touch-branded';
import * as visualisation from './touch-visualisation';
import * as vendor from './touch-vendor';
import * as setup from './touch-setup';
import * as stock from './touch-stock';
import * as connect from './touch-connect';
import * as socialFeeds from './touch-social-feeds';
import * as rack from './touch-rack';
import * as company from './touch-company';
import * as vehicle from './touch-vehicle';
import * as carrier from './touch-carrier';
import * as test from './touch-test';
import * as payment from './touch-payment';
import * as fake from './touch-fake';

import { useAuthStore } from '@/pinia/auth';
import { useBrandedStore } from '@/pinia/branded'
import { useBasketStore } from '@/pinia/basket';
import { useSandboxStore } from '@/pinia/sandbox'

export default class Touch {

  constructor(endpoint, imageBaseURL, router) {
    this.CONTRACT_TYPE_ENQUIRY = 1;
    this.CONTRACT_TYPE_QUOTE = 2;
    this.CONTRACT_TYPE_ORDER = 3;
    this.endpoint = endpoint;
    this.imageBaseURL = imageBaseURL;
    this.router = router;
    this.authStore = useAuthStore()
    this.brandedStore = useBrandedStore()
    this.basketStore = useBasketStore()
    this.sandboxStore = useSandboxStore()
    this.axios = axios
    this.prefix = 'pearl'

    Object.assign(this, auth);
    Object.assign(this, news);
    Object.assign(this, processing);
    Object.assign(this, contract);
    Object.assign(this, report);
    Object.assign(this, common);
    Object.assign(this, staff);
    Object.assign(this, customer);
    Object.assign(this, marketing);
    Object.assign(this, kpi);
    Object.assign(this, branded);
    Object.assign(this, visualisation);
    Object.assign(this, vendor);
    Object.assign(this, setup);
    Object.assign(this, stock);
    Object.assign(this, connect);
    Object.assign(this, socialFeeds);
    Object.assign(this, rack);
    Object.assign(this, company);
    Object.assign(this, vehicle);
    Object.assign(this, carrier);
    Object.assign(this, test);
    Object.assign(this, payment);
    Object.assign(this, fake);

    this.setLogin(this.authStore?.authToken, this.authStore?.authTokenExpiry, this.authStore?.installation);

    const urlSuffix = 'bm-touch.co.uk'
    const regEx = new RegExp(`^(([^.]+)\\.)?(business|admin|portal)(\\.([^.]+))?\\.${urlSuffix.replace(/\./gi, '\\.')}$`);
    const matches = window.location.hostname.match(regEx);

    if (matches) {
      const type = matches[3];
      this.prefix = matches[2] ? matches[2] : type;
      const environment = matches[5];
      const domain = [this.prefix, 'server', environment].filter(Boolean).join('.');
      this.endpoint = `https://${domain}.${urlSuffix}/api/`;
      this.imageBaseURL = `https://${domain}.${urlSuffix}`;
    }

    if (this.endpoint === undefined) {
      throw new Error('Endpoint not defined');
    }
    if (this.imageBaseURL === undefined) {
      throw new Error('imageBaseURL not defined');
    }
    window.setTimeout(this.sessionTimeoutCheck.bind(this), 600000);
  }

  urlIs(prefix) {
    return this.prefix === prefix;
  }

  sessionTimeoutCheck() {
    if (this.tokenExpiry) {
      Object.keys(this.tokenExpiry).forEach((key) => {
        if (this.tokenExpiry[key]) {
          if (momentDate(this.tokenExpiry[key]).isBefore()) {
            this.token[key] = undefined;
            this.tokenExpiry[key] = undefined;
            this.router
              .push({ path:  this.authStore.login_url, query: { redirect: this.router.currentRoute.path } })
              .catch(() => {});
          } else if (momentDate(this.tokenExpiry[key]).subtract(6, 'hours').isBefore()) {
            this.authStore.GetJWT(key)
          }
        }
      });
    }
    window.setTimeout(this.sessionTimeoutCheck.bind(this), 600000);
  }

  hasToken() {
    return this.token !== undefined;
  }

  setEndpoint(endpoint) {
    this.endpoint = endpoint;
  }

  setImageBaseURL(url) {
    this.imageBaseURL = url;
  }

   
  async download(url) {
    Cookies.remove('Bearer');
    await this.authGetCookie();
    const link = document.createElement('a');
    link.href = `/api/${url}`;

    document.body.appendChild(link);
    link.click();
  }

  async displayStream(url, options = {}) {
    const response = await this.handleApiRequest({
      method: url,
      config: { responseType: 'blob'}
    })
    return window.URL.createObjectURL(new Blob([response], options));
  }


  async handleApiRequest({ 
    method, request = window.enum.request.POST,  payload = {}, errorCallback, successCallback = (r) => r.data, config = {}
  }) {
    const url = method.endpoint ? method.endpoint + method.method : this.endpoint + method
    const headers = { Authorization: this.token && this.installation ? `Bearer ${this.token[this.installation]}` : undefined }

    let response
    try {
      response = request === window.enum.request.POST 
        ? await this.axios.post(url, payload, { ...config, headers }) 
        : await this.axios.get(url, { ...config, headers })
    } catch (error) {
      return errorCallback(error, this.authStore, this.brandedStore, this.router, url)
    }
    return successCallback(response);
  }
}
