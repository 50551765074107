<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <Breadcrumbs
        v-if="current_category_name !== ''"
        :routes="[['Dashboard', '/'], ['News', '/news'], [current_category_name]]"
      />
      <Breadcrumbs
        v-else
        :routes="[
          ['Dashboard', '/'],
          ['News', '/news'],
        ]"
      />
    </template>
    <template #header_page_title>
      <div class="flex items-center justify-center gap-2">
        <div v-if="current_category_name === ''"> Search News </div>
        <div v-else> News - {{ current_category_name }} </div>
      </div>
    </template>
    <Teleport defer to="#portal_search">
      <SearchBox class="flex" placeholder="Search news..." />
    </Teleport>
    <loading
      v-if="!loading"
      :loading="loading"
      appear
      class="bg-gray-200 w-full h-full overflow-y-auto overflow-x-hidden scrolling-touch p-5 md:p-10 relative"
    >
      <div class="flex flex-col-reverse flex-col 2xs:flex-row items-center gap-5 mb-5">
        <FilterDropdown
          v-if="news_categories.length"
          label="News"
          query-label="newsFilter"
          :filters="news_categories.filter((x) => !x.isEmpty)"
        />
      </div>
      <div key="1" class="flex" data-index="1">
        <div v-if="total_items > 0 && news.length > 0" class="w-full">
          <transition :key="otherPosts.length" appear name="fade-in" mode="out-in">
            <animation-staggered-fade :start-delay="500" appear class="grid xs:grid-cols-2 lg:grid-cols-3 gap-5">
              <router-link
                v-for="(news_post, index) in otherPosts"
                :key="index"
                :to="news_post.url"
                class="w-full flex flex-col cursor-pointer hover-zoom"
                :data-index="index"
              >
                <div class="flex flex-col flex-1 bg-white border p-3">
                  <div v-if="news_post.imageUrl" class="flex flex-col justify-around flex-grow">
                    <api-img :src="news_post.imageUrl" class="w-full" />
                  </div>
                  <div class="flex flex-col mt-auto">
                    <div class="font-medium border-b pb-3 my-3">
                      {{ news_post.title }}
                    </div>
                    <div class="mt-2">
                      <ProtectedIframe :html="news_post.summary" />
                    </div>
                    <div class="text-sm">
                      {{ formatDate(news_post.publishAt) }}
                    </div>
                  </div>
                </div>
              </router-link>
            </animation-staggered-fade>
          </transition>
        </div>
        <div v-else key="none-found" class="w-full">
          <div
            v-if="$route.query.search"
            class="flex items-center justify-between bg-white p-3 border"
          >
            <div>No News Articles match "{{ $route.query.search }}"</div>
            <div>
              <a class="btn-action" @click.stop="$router.push({ query: undefined })">
                Clear Search
              </a>
            </div>
          </div>
          <div v-else class="bg-white p-3 border">No News Articles to show</div>
        </div>
      </div>
    </loading>
  </touch-layout>
</template>

<script>

import { formatDate } from '@/composables/date';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import SearchBox from '@/components/shared/SearchBox.vue';
import ProtectedIframe from '@/components/shared/ProtectedIframe.vue';
import FilterDropdown from '@/components/shared/FilterDropdown.vue';

export default {
  components: {
    Breadcrumbs,
    SearchBox,
    ProtectedIframe,
    FilterDropdown
  },
  setup() {
    return { formatDate }
  },
  data() {
    return {
      news: [],
      news_categories: [],
      loading: true,
      limit: 20,
      current_page: 1,
      show_load_more: true,
      total_items: 0,
    };
  },
  computed: {
    current_category_name() {
      const currentCategory = this.news_categories.find(
        (category) => category.id == this.currentCategory,
      );
      if (currentCategory !== undefined) {
        return currentCategory.name;
      }
      return '';
    },
    otherPosts() {
      // return this.news.slice(1);
      return this.news;
    },
    currentCategory() {
      return this.$route.query.category;
    },
  },
  watch: {
    current_page() {
      this.loadFeed(false);
    },
    '$route.query': {
      handler() {
        this.loadFeed();
      },
      immediate: true,
    },
  },
  async mounted() {
    this.news_categories = await window.touch.getNewsCategories();
  },
  methods: {
    async loadFeed() {
      this.loading = true;
      const offset = this.limit * (this.current_page - 1);

      const loadedNews = await window.touch.newsItems({
        limit: this.limit,
        offset,
        search: this.$route.query.search,
        categoryId: this.currentCategory,
      });

      if (offset > 0) {
        for (let i = 0; i < loadedNews.news_items.length; i += 1) {
          this.news.push(loadedNews.news_items[i]);
        }
      } else {
        this.news = loadedNews.news_items;
        // Example of potential XSS
        // this.news[0].summary = "<img src='pic_trulsli.jpg' onerror=alert(localStorage.getItem('business')) width='500' height='333'>"
        // What is generated from user input
        // this.news[0].summary = "&lt;img src='pisc_truslo.pbf' onerror=alert('img')&gt;"
      }

      this.total_items = loadedNews.total_items;
      this.show_load_more = this.total_items > this.news.length;
      this.loading = false;
    },
  },
};
</script>
