import { errorCallback, successCallback } from '@/composables/validate';

export async function CreatePaymentRequest(contractId, JobKey) {
  return this.handleApiRequest({ 
    method: 'payment/CreatePaymentRequest',
    payload: {
      contractId,
      JobKey,
    },
    errorCallback
  })
}

export async function UpdatePaymentStatus(ContractId, PaymentStatus) { 
  return this.handleApiRequest({ 
    method: 'contract/UpdatePaymentStatus',
    payload: {
      ContractId,
      PaymentStatus
    },
    errorCallback,
    successCallback: (r) => successCallback(r, 'Save')
  })
}

export async function getAccount() {
  return this.handleApiRequest({ 
    method: 'stripe/getaccount',
    errorCallback
  })
}

export async function createAccount() {
  return this.handleApiRequest({ 
    method: 'stripe/createaccount',
    errorCallback
  })
}

export async function createAccountLink() {
  return this.handleApiRequest({ 
    method: 'stripe/createaccountlink',
    errorCallback
  })
}
