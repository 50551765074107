import { errorCallback } from '@/composables/validate';

export async function vendorUpdateThisGridAnomaly(datasetId, id, gridId, overrideCells, customerId) {
  return this.handleApiRequest({ 
    method: 'vendor/UpdateThisGridAnomaly',
    payload: {
      Id: id,
      CustomerId: customerId,
      OverrideCells: overrideCells,
      DatasetId: datasetId,
      FinancialGridConfigurationId: gridId,
    },
    errorCallback
  })
}

export async function vendorGetThisGridAnomaly(id, customerId, datasetId) {
  return this.handleApiRequest({ 
    method: 'vendor/GetThisGridAnomaly',
    payload: {
      Id: id,
      CustomerId: customerId,
      DatasetId: datasetId,
    },
    errorCallback
  })
}
