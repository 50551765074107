import { errorCallback, successCallback } from '@/composables/validate';

export async function staffGetEnquiryExpirationTime() {
  return this.handleApiRequest({ 
    method: 'staff/GetEnquiryExpirationTime',
    errorCallback
  })
}

export async function staffUpdateEnquiryExpirationTime(newTime) {
  return this.handleApiRequest({ 
    method: 'staff/UpdateEnquiryExpirationTime',
    payload: {
      EnquiryOverdueHours: newTime,
    },
    errorCallback
  })
}

export async function staffUpdateAlternativePriceData(customerId, datasetId, anomalies) {
  return this.handleApiRequest({ 
    method: 'staff/UpdateAlternativePriceData',
    payload: {
      CustomerId: customerId,
      DatasetId: datasetId,
      Anomalies: anomalies,
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  })
}

export async function staffGetFinancialGroups(customerId, datasetId) {
  return this.handleApiRequest({ 
    method: 'staff/GetFinancialGroups',
    payload: {
      CustomerId: customerId,
      DatasetId: datasetId,
    },
    errorCallback
  })
}

export async function staffGetAlternativePriceData(customerId, datasetId, groupId, description, offset, limit) {
  return this.handleApiRequest({ 
    method: 'staff/GetAlternativePriceData',
    payload: {
      CustomerId: customerId,
      DatasetId: datasetId,
      GroupId: groupId,
      Description: description,
      Offset: offset,
      Limit: limit,
    },
    errorCallback
  })
}

export async function staffUpdateProductSelectionGroups(groupList) {
  return this.handleApiRequest({ 
    method: 'staff/UpdateProductSelectionGroups',
    payload: {
      ProductSelection: groupList,
    },
    errorCallback
  })
}

export async function staffGetLatestExchangeRates() {
  return this.handleApiRequest({ 
    method: 'staff/GetLatestExchangeRates',
    errorCallback
  })
}

export async function staffUpdateCurrency(currencyList) {
  return this.handleApiRequest({ 
    method: 'staff/UpdateCurrency',
    payload: {
      Currencies: currencyList,
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  })
}

export async function staffUpdateProductSelectionTypes(groupList) {
  return this.handleApiRequest({ 
    method: 'staff/UpdateProductSelectionTypes',
    payload: {
      ProductSelection: groupList,
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  })
}

export async function staffAssignEnquiry(contractId, userIds) {
  return this.handleApiRequest({ 
    method: 'staff/AssignEnquiry',
    payload: {
      ContractId: Number(contractId),
      CustomerIds: userIds,
    },
    errorCallback
  })
}

export async function staffUpdateOrganisationDetails(name, email, phone, web, file, PortalOnStop) {
  return this.handleApiRequest({ 
    method: 'staff/updateOrganisationDetails',
    payload: {
      Name: name,
      Email: email,
      Phone: phone,
      Web: web,
      Styling: {
        Logo: file,
      },
      PortalOnStop,
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  })
}

export async function staffListCustomers(
  limit, offset, searchName, contractId, dealerNetworkPartnerOnly = false,
  CustomerStatus, generalLicenseStatus, BranchCompanyId
) {
  const response = await this.handleApiRequest({ 
    method: 'staff/ListCustomers',
    payload: {
      limit,
      offset,
      searchName,
      GetTotal: true,
      ContractIDForDistance: contractId,
      DealerNetworkPartnerOnly: dealerNetworkPartnerOnly,
      CustomerStatus,
      generalLicenseStatus,
      BranchCompanyId,
      ContractId: contractId
    },
    errorCallback
  })
  const customers = response.customers.map((customer) => ({
    ...customer,
    url: `/customer/${customer.id}/details`,
  }));
  return {
    customers,
    total_items: response.total,
    enabledCustomers: response.enabledCustomers,
    maxCustomers: response.maxCustomers,
  };
}

export async function staffGetOrganisationCustomerId() {
  const { customerId } = await this.handleApiRequest({ 
    method: 'staff/GetOrganisationCustomerId',
    errorCallback
  })
  return customerId
}

export async function staffList() {
  const response = await this.handleApiRequest({ 
    method: 'staff/List',
    errorCallback
  })
  return response.map((staffMember) => ({
    ...staffMember,
    url: `/staff/${staffMember.id}`,
  }));
}

export async function staffGetTaxRates() {
  return this.handleApiRequest({ 
    method: 'staff/GetTaxRates',
    errorCallback
  })
}

export async function staffUpdateTaxRate(TaxRate) {
  return this.handleApiRequest({ 
    method: 'staff/UpdateTaxRate',
    payload: {
      Id: Number(TaxRate.id),
      Name: TaxRate.name,
      Value: Number(TaxRate.value),
      IsDefault: TaxRate.isDefault,
    },
    errorCallback
  })
}

export async function staffAddTaxRate(TaxRate) {
  return this.handleApiRequest({ 
    method: 'staff/AddTaxRate',
    payload: {
      Name: TaxRate.name,
      Value: Number(TaxRate.value),
      IsDefault: TaxRate.isDefault,
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  })
}

export async function staffDeleteTaxRate(id) {
  return this.handleApiRequest({ 
    method: 'staff/DeleteTaxRate',
    payload: {
      Id: Number(id),
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Delete')
  })
}

export async function staffGetStaffContact(ContactId) {
  return this.handleApiRequest({ 
    method: 'staff/GetStaffContact',
    payload: {
      Id: Number(ContactId),
    },
    errorCallback
  })
}

export async function staffEditStaffContact(Contact) {
  return this.handleApiRequest({ 
    method: 'staff/EditStaffContact',
    payload: Contact,
    errorCallback
  })
}

export async function staffCreateStaffContact(Contact) {
  return this.handleApiRequest({ 
    method: 'staff/CreateStaffContact',
    payload: Contact,
    errorCallback
  })
}

export async function staffGetDiscountList(datasetId) {
  return this.handleApiRequest({ 
    method: 'staff/GetDiscountList',
    payload: {
      Id: datasetId,
    },
    errorCallback
  })
}

export async function staffUpdateDiscountCategories(discounts) {
  return this.handleApiRequest({ 
    method: 'staff/UpdateDiscountCategories',
    payload: {
      DiscountCategories: discounts,
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, discounts[0].Delete ? 'Delete' : 'Save')
  })
}

export async function staffListDiscountCategories() {
  return this.handleApiRequest({ 
    method: 'staff/ListDiscountCategories',
    errorCallback
  })
}

export async function staffGetCustomerDiscounts(customerId, datasetId) {
  const { discounts } = await this.handleApiRequest({ 
    method: 'staff/GetCustomerDiscounts',
    payload: {
      CustomerId: Number(customerId),
      DatasetId: datasetId,
    },
    errorCallback
  })
  return discounts;
}

export async function staffUpdateDiscounts(customerId, datasetId, discounts) {
  return this.handleApiRequest({ 
    method: 'staff/UpdateDiscounts',
    payload: {
      CustomerId: Number(customerId),
      DatasetId: datasetId,
      Discounts: discounts.map((discount) => ({
        Id: discount.id,
        Value: Number(discount.value),
      }))
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  })
}

export async function staffGetBasePrices(datasetId, limit, offset, groupId, description = null) {
  return this.handleApiRequest({ 
    method: 'staff/GetBasePrices',
    payload: {
      limit,
      offset,
      datasetId,
      groupId,
      description,
    },
    errorCallback
  })
}

export async function staffGetFinancialOverrides(datasetId, financialId, limit, offset) {
  return this.handleApiRequest({ 
    method: 'staff/GetFinancialOverrides',
    payload: {
      limit,
      offset,
      datasetId,
      financialId,
    },
    errorCallback
  })
}

export async function staffUpdateBasePrices(datasetId, financials) {
  return this.handleApiRequest({ 
    method: 'staff/UpdateBasePrices',
    payload: {
      DatasetId: datasetId,
      Financials: financials,
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  })
}

export async function staffUpdateBasePriceGrid(datasetId, financialId, financialGridConfigurationId, cells) {
  return this.handleApiRequest({ 
    method: 'staff/UpdateBasePriceGrid',
    payload: {
      DatasetId: datasetId,
      Id: financialId,
      FinancialGridConfigurationId: financialGridConfigurationId,
      Cells: cells
    },
    errorCallback
  })
}

export async function staffUpdateBasePriceList(datasetId, financialId, financialGridConfigurationId, cells) {
  return this.handleApiRequest({ 
    method: 'staff/UpdateBasePriceList',
    payload: {
      DatasetId: datasetId,
      Id: financialId,
      FinancialGridConfigurationId: financialGridConfigurationId,
      Cells: cells,
    },
    errorCallback
  })
}

export async function staffGetBasePriceList(datasetId,financialId) {
  return this.handleApiRequest({ 
    method: 'staff/GetBasePriceList',
    payload: {
      DatasetId: datasetId,
      Id: financialId
    },
    errorCallback
  })
}

export async function UpdateBaseValueWidthByHeight(datasetId, financialId, cells) {
  return this.handleApiRequest({ 
    method: 'staff/UpdateBaseValueWidthByHeight',
    payload: {
      DatasetId: datasetId,
      Id: financialId,
      Data: cells,
    },
    errorCallback
  })
}

export async function staffGetBasePriceGrid(datasetId, financialId) {
  return this.handleApiRequest({ 
    method: 'staff/GetBasePriceGrid',
    payload: {
      DatasetId: datasetId,
      Id: financialId,
    },
    errorCallback
  })
}

export async function staffUpdateFinancialGroups(datasetId, financialGroups) {
  return this.handleApiRequest({ 
    method: 'staff/UpdateFinancialGroups',
    payload: {
      DatasetId: datasetId,
      FinancialGroups: financialGroups,
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  })
}

export async function staffMoveFinancialItems(datasetId, groupId, financialItemIds) {
  return this.handleApiRequest({ 
    method: 'staff/MoveFinancialItems',
    payload: {
      DatasetId: datasetId,
      GroupId: groupId,
      ItemIds: financialItemIds,
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  })
}

export async function staffListStaff(limit, offset, branchCompanyId) {
  return this.handleApiRequest({ 
    method: 'staff/ListStaff',
    payload: {
      limit,
      offset,
      GetTotal: true,
      branchCompanyId,
    },
    errorCallback
  })
}

export async function staffGetThisListAnomaly(id, customerId, datasetId) {
  return this.handleApiRequest({ 
    method: 'staff/GetThisListAnomaly',
    payload: {
      Id: id,
      CustomerId: customerId,
      DatasetId: datasetId,
    },
    errorCallback
  })
}

export async function staffUpdateThisListAnomaly(datasetId, id, gridId, overrideCells, customerId) {
  return this.handleApiRequest({ 
    method: 'staff/UpdateThisListAnomaly',
    payload: {
      Id: id,
      CustomerId: customerId,
      OverrideCells: overrideCells,
      DatasetId: datasetId,
      FinancialGridConfigurationId: gridId,
    },
    errorCallback
  })
}

export async function staffGetDeliveryOptions(Name, Offset, Limit, DeliveryDays) {
  return this.handleApiRequest({ 
    method: 'staff/GetDeliveryOptions',
    payload: {
      Name,
      Offset,
      Limit,
      DeliveryDays,
    },
    errorCallback
  })
}

export async function staffUpdateDeliveryOptions(deliveryOption,) {
  return this.handleApiRequest({ 
    method: 'staff/UpdateDeliveryOptions',
    payload: {
      DeliveryOptionDetails: deliveryOption,
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  })
}

export async function staffGetPostCodes(Prefix, Offset, Limit) {
  return this.handleApiRequest({ 
    method: 'staff/GetPostcodes',
    payload: {
      Prefix,
      Offset,
      Limit,
    },
    errorCallback
  })
}

export async function staffUpdatePostcodes(PostCode) {
  return this.handleApiRequest({ 
    method: 'staff/UpdatePostcodes',
    payload: {
      PostcodeDetails: PostCode,
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  })
}

export async function staffGetOptionHeadings() {
  const { headings } = await this.handleApiRequest({ 
    method: 'staff/GetOptionHeadings',
    request: window.enum.request.GET,
    errorCallback
  })
  return headings;
}

export async function staffGetOptionItems(headingType) {
  const { items } = await this.handleApiRequest({ 
    method: `staff/GetOptionItems?headingType=${headingType}`,
    request: window.enum.request.GET,
    errorCallback
  })
  return items;
}

export async function staffUpdateOptionItems(DatasetId, OptionItems) {
  return this.handleApiRequest({ 
    method: 'staff/UpdateOptionItems',
    payload: {
      DatasetId,
      items: OptionItems,
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  })
}

export async function staffGetOptionGroups() {
  return this.handleApiRequest({ 
    method: 'staff/GetOptionGroups',
    request: window.enum.request.GET,
    errorCallback
  })
}

export async function staffGetFenestrationOptionGroups(datasetId) {
  return this.handleApiRequest({ 
    method: `staff/GetFenestrationOptionGroups?DatasetId=${datasetId}`,
    request: window.enum.request.GET,
    errorCallback
  })
}

export async function staffGetOptionHeadingsByGroup(groupId) {
  return this.handleApiRequest({ 
    method: `staff/GetOptionHeadingsByGroup?groupId=${groupId}`,
    request: window.enum.request.GET,
    errorCallback
  })
}

export async function staffGetFenestrationOptionHeadingsForGroup(datasetId,groupId) {
  return this.handleApiRequest({ 
    method: `staff/GetFenestrationOptionHeadingsForGroup?DatasetId=${datasetId}&OptionGroupId=${groupId}`,
    request: window.enum.request.GET,
    errorCallback
  })
}

export async function GetFenestrationOptionHeadings(datasetId) {
  return this.handleApiRequest({ 
    method: `staff/GetFenestrationOptionHeadings?DatasetId=${datasetId}`,
    request: window.enum.request.GET,
    errorCallback
  })
}

export async function staffGetCustomerOptionItems(headingType,customerId) {
  return this.handleApiRequest({ 
    method: `staff/GetCustomerOptionItems?headingType=${headingType}&customerId=${customerId}`,
    request: window.enum.request.GET,
    errorCallback
  })
}

export async function staffGetFenestrationOptionItemsForHeading(datasetId,headingType) {
  return this.handleApiRequest({ 
    method: `staff/GetFenestrationOptionItemsForHeading?DatasetId=${datasetId}&OptionHeadingId=${headingType}`,
    request: window.enum.request.GET,
    errorCallback
  })
}

export async function staffUpdateCustomerOptionItems(DatasetId,Items,CustomerId) {
  return this.handleApiRequest({ 
    method: 'staff/UpdateCustomerOptionItems',
    payload: {
      CustomerId,
      DatasetId,
      Items,
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  })
}

export async function UpdateOptionConfigurations(DatasetId, OptionGroups, OptionHeadings, OptionItems) {
  return this.handleApiRequest({ 
    method: 'staff/UpdateOptionConfiguration',
    payload: {
      DatasetId,
      OptionGroups,
      OptionHeadings,
      OptionItems
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  })
}

export async function GetCustomerCount() {
  return this.handleApiRequest({ 
    method: 'staff/GetCustomerCount',
    errorCallback
  })
}

export async function updateAccountSettings(accountSettings) {
  return this.handleApiRequest({ 
    method: 'staff/UpdateAccountSettings',
    payload: {
      EmailOff: accountSettings.emailOff,
      showFitting: accountSettings.showFitting
    },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Save')
  })
}

export async function ListDiscountVouchers(query) {
  return this.handleApiRequest({ 
    method: 'staff/ListDiscountVouchers',
    payload: query,
    errorCallback
  })
}

export async function CreateDiscountVoucher(voucher) {
  return this.handleApiRequest({ 
    method: 'staff/CreateDiscountVoucher',
    payload: voucher,
    errorCallback,
    successCallback: (r) => successCallback(r, 'Save')
  })
}

export async function UpdateDiscountVoucher(voucher) {
  return this.handleApiRequest({ 
    method: 'staff/UpdateDiscountVoucher',
    payload: voucher,
    errorCallback,
    successCallback: (r) => successCallback(r, 'Save')
  })
}

export async function DeleteDiscountVoucher(id) {
  return this.handleApiRequest({ 
    method: 'staff/UpdateDiscountVoucher',
    payload: { id },
    errorCallback,
    successCallback: (r) => successCallback(r.data, 'Delete')
  })
}

export async function GetBrandedFabricatorLimit() {
  return this.handleApiRequest({ 
    method: 'staff/GetBrandedFabricatorLimit',
    errorCallback: e => e,
    successCallback: r => r
  })
}

export async function ListCustomerBrandedDetails(query) {
  return this.handleApiRequest({ 
    method: 'staff/ListCustomerBrandedDetails',
    payload: query,
    errorCallback
  })
}

export async function GetFinancialCategories() {
  return this.handleApiRequest({ 
    method: 'staff/GetFinancialCategories',
    errorCallback
  })
}

export async function UpdateFinancialCategories(payload)  {
  return this.handleApiRequest({ 
    method: 'staff/UpdateFinancialCategories',
    payload: {
      FinancialCategoryDetails: payload
    },
    errorCallback,
    successCallback: (r) => successCallback(r, 'Save')
  })
}